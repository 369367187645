import styled from 'styled-components';
import { Button } from '~/ui/components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-block: 8px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
`;

export const CancelButton = styled(Button)`
  @media (max-width: 1024px) {
    padding: 12px 20px;
    span {
      font-size: 14px;
      text-align: center;
    }
  }
`;
