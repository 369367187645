import React, { useEffect, useMemo } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { TabItem, Tabs as UiTabs } from '~/ui/components';
import { getDetails, getContractHistory } from '~/store/contracts/actions';
import { Pendencies } from './Pendencies/Pendencies';
import OtherInfo from './OtherInfo/OtherInfo';
import History from './History/History';
import Summary from './Summary/Summary';
import { Files } from './Files/Files';
import { Returns } from './Returns/Returns';

function Tabs() {
  const contractDetailsControl = useAwaitControl(getDetails);
  const contractHistoryControl = useAwaitControl(getContractHistory);

  const contract = contractDetailsControl.result();
  const contractHistory = contractHistoryControl.result();

  const tabs = useMemo(() => {
    const tabsList: TabItem[] = [
      {
        key: 'summary',
        title: 'Resumo',
        noPadding: true,
        component: <Summary contract={contract} />,
      },
      {
        key: 'info',
        title: 'Outras informações',
        noPadding: true,
        component: <OtherInfo personData={contract?.personData} />,
      },
      {
        key: 'files',
        title: 'Arquivos',
        noPadding: true,
        component: <Files />,
      },
      {
        key: 'history',
        title: 'Histórico',
        noPadding: true,
        component: <History events={contractHistory} />,
      },
      {
        key: 'pendencies',
        title: 'Pendências',
        component: <Pendencies />,
        noPadding: true,
      },
      {
        key: 'returns',
        title: 'Retornos',
        component: <Returns contract={contract} />,
        noPadding: true,
      },
    ];

    return tabsList;
  }, [contract, contractHistory]);

  useEffect(() => {
    if (contract?.contract) {
      contractHistoryControl.start({
        externalId: contract?.contract?.externalId,
        product: contract?.contract?.product,
        document: contract?.contract?.document,
      });
    }
  }, [contract]);

  return <UiTabs tabs={tabs} persistOnQuery={{ key: 'tab' }} />;
}

export default Tabs;
