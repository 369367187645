import React, { useState } from 'react';

import { Shimmer } from '~/ui/components';
import Action from '~/components/Action';

import { ActionItem } from '~/components';
import {
  ShimmerContainer,
  ActionsTitle,
  CollapseButton,
  MainButton,
  Collapse,
  Panel,
  ActionsContainer,
} from './SideActions.styled';

type ActionWithComponent = {
  component?: React.ReactNode;
} & Action;

type Actions = ActionWithComponent[];

type SideActionsProps = {
  title?: string;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  actions?: Actions;
};

export function SideActions({ title, loading, disabled, className, actions }: SideActionsProps) {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  if (loading) {
    return (
      <ShimmerContainer>
        <Shimmer width={220} height={30} mt={25} mb={18} ml={32} mr={24} />
        <Shimmer width={200} height={25} ml={32} mb={8} />
        <Shimmer width={200} height={25} ml={32} mb={8} />
        <Shimmer width={200} height={25} ml={32} mb={8} />
        <Shimmer width={200} height={25} ml={32} mb={8} />
        <Shimmer width={200} height={25} ml={32} mb={8} />
        <Shimmer width={200} height={25} ml={32} mb={8} />
        <Shimmer width={200} height={25} ml={32} mb={8} />
      </ShimmerContainer>
    );
  }

  return (
    <ActionsContainer className={className} disabled={disabled}>
      <MainButton onClick={toggleCollapse}>
        <CollapseButton collapsed={collapsed} />
        <ActionsTitle>{title || 'Ações'}</ActionsTitle>
      </MainButton>

      <Collapse
        defaultActiveKey="collapse"
        activeKey={collapsed ? null : 'collapse'}
        bordered={false}
      >
        <Panel showArrow={false} header={null} key="collapse">
          {actions?.map((action, i) =>
            action?.component ? action?.component : <ActionItem key={i} action={action} />,
          )}
        </Panel>
      </Collapse>
    </ActionsContainer>
  );
}
