import React from 'react';
import styled from 'styled-components';
import { Link as DomLink, LinkProps } from 'react-router-dom';

import { themeColor } from '~/common/utils/theme';
import { Button } from '~/ui/components';

export const Link = styled((props: LinkProps) => <DomLink {...props} />)`
  color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
`;

export const StyledButton = styled(Button).attrs({
  variant: 'text',
})`
  padding: 0;

  span {
    font-weight: 400 !important;
    font-size: 14px !important;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

export const CellWrapper = styled.div`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  color: ${({ theme }) => theme.colors.element.primary};
`;
