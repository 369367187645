import { css } from 'styled-components';
import { themeColor, themeToggleValue } from '~/common/utils/theme';

export default css`
  .mt-modal {
    max-width: 90%;
    max-height: 90%;

    .ant-modal-content {
      background: ${themeColor('background.primary', { dark: 'background.secondary' })};
      border-radius: 16px;
    }

    .ant-modal-header {
      background: ${themeColor('background.primary', { dark: 'background.secondary' })};
      border-radius: 16px 16px 0 0;
      padding: 24px 24px 0;
      border-bottom: 1px solid ${({ theme }) => theme.colors.divider.primary};
    }

    .ant-modal-title {
      color: ${themeColor('element.primary', { dark: 'element.secondary' })};
      font-family: ${({ theme }) => theme.typography.fonts.libreFranklin};
      font-size: ${({ theme }) => theme.typography.types.bodyLarge.size};
      line-height: ${({ theme }) => theme.typography.types.bodyMedium.lineHeight};
      font-weight: 700;
      flex-direction: row;
      display: flex;
      gap: 8px;
      padding-bottom: 16px;
      justify-content: flex-start;
      align-items: center;

      svg {
        color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
        cursor: pointer;
      }
    }

    .ant-modal-body {
      padding: 16px 24px 24px;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .ant-modal-close {
      top: 2px;

      span,
      .ant-modal-close-x {
        background-color: ${themeColor('background.secondary')};
        border-radius: 12px;
        width: 40px;
        height: 40px;
        margin: 12px;
      }

      svg {
        color: ${themeColor('element.primary')};
      }
    }

    &.loading {
      .ant-modal-body,
      .ant-modal-header,
      .ant-modal-close {
        opacity: 0.6;
        pointer-events: none;
      }
    }

    &.bg-transparent {
      .ant-modal-content {
        background: transparent;
        box-shadow: none;
      }
    }

    &.max-height-modal {
      .ant-modal-body {
        max-height: 551px;
        overflow: overlay;
      }
    }

    &.no-padding {
      .ant-modal-body {
        padding: 0;
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: center;
      padding-block: 24px;
      border-top: 1px solid ${({ theme }) => theme.colors.divider.primary};
    }
    //&.with-back-button {
    //  .ant-modal-header {
    //    padding: 16px 40px 0 16px;
    //  }
    //}
  }

  .ant-modal-mask {
    background-color: ${({ theme }) =>
      themeToggleValue(theme, 'rgba(0, 0, 0, 0.35)', { dark: 'rgba(0, 0, 0, 0.80)' })};
  }

  .overflow-hidden {
    .ant-modal-content {
      overflow: hidden;
    }
  }
`;
