import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { useModal } from '~/hooks/useModal';
import { Alert, Button, Form, Select } from '~/ui/components';
import { cancelContract, getCancellationReasons } from '~/store/contracts/actions';
import { getLoggedUser } from '~/store/user/actions';
import { Actions, Wrapper } from './CancelContractButton.styles';

type CancelContractModalProps = {
  product: string;
  externalId: string;
};

function CancelContractModal({ product, externalId }: CancelContractModalProps) {
  const [selectedOption, setSelectedOption] = useState(null);
  const { closeAll, setLoading } = useModal();

  const cancellationReasonsControl = useAwaitControl(getCancellationReasons);
  const cancellationControl = useAwaitControl(cancelContract);
  const loggedUserControl = useAwaitControl(getLoggedUser);

  const cancellationReasons = cancellationReasonsControl.result();
  const isCancelled = cancellationControl.isSuccessful();
  const isCancelling = cancellationControl.isRunning();
  const loggedUser = loggedUserControl.result();

  const normalizeOptions = useMemo(() => {
    if (!Array.isArray(cancellationReasons)) return [];

    return cancellationReasons?.map((option) => ({
      value: option?.id,
      label: option?.reason,
    }));
  }, [cancellationReasons]);

  const handleCancellation = useCallback(
    (value) => {
      const reasonObject = cancellationReasons?.find((reason) => reason.id === value.cancellation);

      const payload = {
        product,
        comment: reasonObject?.reason,
        externalId,
        externalUserId: loggedUser?.id,
        reasonId: reasonObject?.id,
      };

      cancellationControl.start(payload);
    },
    [selectedOption, cancellationReasons, externalId, loggedUser, product],
  );

  useEffect(() => {
    if (!cancellationReasons?.length) cancellationReasonsControl.start({ productName: product });
  }, [product]);

  useEffect(() => {
    if (isCancelled) {
      closeAll();
      cancellationControl.clear();
    }
  }, [isCancelled]);

  useEffect(() => {
    setLoading('cancel-contract-modal', isCancelling);
  }, [isCancelling]);

  return (
    <Wrapper>
      <Alert status="warning" label="Ao confirmar seleção, você cancela o contrato." />
      <Form
        name="reset-form"
        onSubmit={handleCancellation}
        inputs={[
          {
            id: 'cancellation',
            input: (
              <Select
                options={normalizeOptions}
                placeholder="Motivo do cancelamento"
                onChange={(option) => setSelectedOption(option[0])}
              />
            ),
          },
        ]}
      >
        <Actions>
          <Button
            title="Cancelar"
            variant="outline"
            type="button"
            rounded
            fullWidth
            size="md"
            color="black"
            onClick={closeAll}
          />
          <Button
            title="Confirmar"
            variant="contained"
            rounded
            fullWidth
            type="submit"
            disabled={!selectedOption}
            size="md"
            color="black"
          />
        </Actions>
      </Form>
    </Wrapper>
  );
}

export default memo(CancelContractModal);
