import { createAsyncAction } from 'react-redux-await-control';
import { message } from 'antd';

import { telli, backoffice } from '~/services';
import { handleErrors } from '~/common/utils/errorHandler';

export const getPendencies = createAsyncAction('GET_PENDENCIES', {
  saveResult: true,
  context: {
    errorParser: () => [],
    responseParser: ({ data }) => data.resource,
    onRequestError: () => message.success('Erro ao carregar pendências'),
    request: ({ contractId, personId }) =>
      telli.post('/admin/pendencycustom/pendencies', {
        contractId,
        personId,
      }),
  },
});

export const getAllPendencies = createAsyncAction('GET_ALL_PENDENCIES', {
  saveResult: true,
  context: {
    errorParser: () => [],
    responseParser: ({ data }) => data.resource,
    onRequestError: () => message.success('Erro ao carregar pendências'),
    request: ({ contractId, personId }) =>
      telli.get('/admin/pendencycustom/all-pendencies', {
        params: {
          idContract: contractId || '',
          idPerson: personId || '',
        },
      }),
  },
});

export const solveBiometry = createAsyncAction('SOLVE_BIOMETRY', {
  context: {
    request: ({ id, file }) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('imageType', 'FACE_FRONT');
      return telli.post(`/admin/pendencycustom/pendencies/${id}/uploadimage`, formData);
    },
    onRequestSuccess: () => message.success('Pendência resolvida'),
    onRequestError: (err) => handleErrors(err, { defaultMessage: 'Falha ao resolver pendência' }),
  },
});

export const solveDocument = createAsyncAction('SOLVE_DOCUMENT', {
  context: {
    request: ({ id, files }) => {
      const formData = new FormData();
      files?.forEach((file) => formData.append(file.id, file.file));
      return telli.post(`/admin/pendencycustom/pendencies/${id}/uploaddocument`, formData);
    },
    onRequestSuccess: () => message.success('Pendência de documento resolvida'),
    onRequestError: (err) => handleErrors(err, { defaultMessage: 'Falha ao resolver pendência' }),
  },
});

export const solvePaycheck = createAsyncAction('SOLVE_PAYCHECK', {
  context: {
    request: ({ id, file }) => {
      const formData = new FormData();
      formData.append('file', file);
      return telli.post(`/admin/pendencycustom/pendencies/${id}/paycheck`, formData);
    },
    onRequestSuccess: () => message.success('Pendência resolvida'),
    onRequestError: (err) => handleErrors(err, { defaultMessage: 'Falha ao resolver pendência' }),
  },
});

export const solveProofOfAddress = createAsyncAction('SOLVE_PROOF_OF_ADDRESS', {
  context: {
    request: ({ id, file, documentType }) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('documentType', documentType);
      return telli.post(`/admin/pendencycustom/pendencies/${id}/proofofaddress`, formData);
    },
    onRequestSuccess: () => message.success('Pendência resolvida'),
    onRequestError: (err) => handleErrors(err, { defaultMessage: 'Falha ao resolver pendência' }),
  },
});

export const solveBankAccount = createAsyncAction('SOLVE_BANK_ACCOUNT', {
  context: {
    request: ({ id, data }) =>
      telli.post(`/admin/pendencycustom/pendencies/${id}/bankaccount`, data),
    onRequestSuccess: () => message.success('Pendência resolvida'),
    onRequestError: (err) => handleErrors(err, { defaultMessage: 'Falha ao resolver pendência' }),
  },
});

export const solveAddress = createAsyncAction('SOLVE_ADDRESS', {
  context: {
    request: ({ id, data }) => telli.post(`/admin/pendencycustom/pendencies/${id}/address`, data),
    onRequestSuccess: () => message.success('Pendência resolvida'),
    onRequestError: (err) => handleErrors(err, { defaultMessage: 'Falha ao resolver pendência' }),
  },
});

export const getPendingReasons = createAsyncAction('GET_PENDING_REASONS', {
  initialValue: [],
  saveResult: true,
  context: {
    request: () => telli.get('/admin/pendencycustom/pendingreasons'),
  },
});

export const requestPendingData = createAsyncAction('REQUEST_PENDING_DATA', {
  context: {
    request: (payload) =>
      telli.post(`/admin/contractcustom/contracts/${payload.contractId}/requestdata`, payload),
    onRequestSuccess: () => message.success('Solicitação de pendência realizado com sucesso'),
    onRequestError: () => message.error('Falha ao solicitar pendência'),
  },
});

export const getPendencyTypes = createAsyncAction('GET_PENDENCY_TYPES', {
  initialValue: [],
  saveResult: true,
  context: {
    onRequestError: () => {},
    errorParser: () => [],
    responseParser: ({ data }) => data,
    request: () => backoffice.get('/pendencies/types'),
  },
});

export const getNewPendencies = createAsyncAction('GET_NEW_PENDENCIES', {
  saveResult: true,
  initialValue: [],
  context: {
    errorParser: () => [],
    responseParser: ({ data }) => data,
    onRequestError: () => message.success('Erro ao carregar pendências'),
    request: ({ externalId, product, mode }) =>
      backoffice.get('/pendencies', { params: { externalId, product, mode } }),
  },
});

export const createNewPendency = createAsyncAction('CREATE_NEW_PENDENCY', {
  context: {
    responseParser: ({ data }) => data,
    onRequestError: () => message.error('Erro ao criar pendência'),
    request: (data) => backoffice.post('/pendencies', data),
  },
});
