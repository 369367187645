import React, { useCallback, useEffect } from 'react';

import useAsyncActionStatus from '~/hooks/useAsyncActionStatus';
import { getFiles } from '~/store/files/actions';
import { FileHistoryModal, FilesList } from '~/components';

import { useModal } from '~/hooks/useModal';
import { FilesContainer } from './Files.styled';

export type FilesProps = {
  id: string | number;
};

export function Files({ id }: FilesProps) {
  const { openModal } = useModal();

  const getFilesControl = useAsyncActionStatus(getFiles, `PERSON_${id}`);

  const handleShowHistory = useCallback(
    (file) => {
      openModal(
        <FileHistoryModal
          documentType={file.documentType}
          fileType={file.personImageType || file.type}
          id={id}
          type="PERSON"
        />,
        {
          title: 'Histórico da imagem',
          closable: true,
        },
      );
    },
    [id],
  );

  useEffect(() => {
    getFilesControl.start({ type: 'PERSON', id }, { actionId: `PERSON_${id}` });
  }, [id]);

  return (
    <FilesContainer>
      <FilesList
        type="PERSON"
        onHistoryClick={handleShowHistory}
        loading={getFilesControl.loading}
        files={getFilesControl.result}
        hideHeader
      />
    </FilesContainer>
  );
}
