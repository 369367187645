import moment from 'moment';

export default function (date) {
  if (date) {
    if (typeof date === 'string' && date.includes('/')) return date;

    return moment(date).utc().format('DD/MM/YYYY');
  }

  return null;
}
