import React, { useMemo } from 'react';

import { FilesList } from '~/components';

import { getDetails } from '~/store/contracts/actions';
import useAsyncActionStatus from '~/hooks/useAsyncActionStatus';
import { FilesContainer } from './Files.styled';

const FILE_NAMES = {
  FACE_FRONT: 'foto-verdade-biometria',
  DOCUMENT_FRONT: 'documento-frente',
  DOCUMENT_BACK: 'documento-verso',
};

export function Files() {
  const detailsControl = useAsyncActionStatus(getDetails);

  const files = useMemo(() => {
    let files = [];

    if (detailsControl.result?.formalizationData?.trustworthyPhoto) {
      files.push(detailsControl.result?.formalizationData?.trustworthyPhoto);
    }
    files = files.concat(detailsControl.result?.formalizationData?.checkImagePhotos);
    files = files.concat(detailsControl.result?.formalizationData?.documents);

    return (
      files?.map((el) => ({
        name: FILE_NAMES[el?.personImageType],
        url: el?.imageUrl,
        type: el?.personImageType,
        inactive: !el?.valid,
        ...el,
      })) || []
    );
  }, [detailsControl.result]);

  return (
    <FilesContainer>
      <FilesList type="CONTRACT" loading={detailsControl.loading} files={files} />
    </FilesContainer>
  );
}
