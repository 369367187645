import React, { useCallback } from 'react';

import pendencyTitle from '~/typings/enums/PendencyType';
import colors from '~/ui/theme/colors';
import { Tag, withStepperProps } from '~/ui/components';

import { PendencyStatusColors } from '~/typings/entities/Pendency';
import moment from 'moment';
import { formatName } from '~/common';
import { Container, CustomButton, InfoRow, InfoTitle, InfoValue, TagsRow } from './Pendency.styled';

type PendencyInfoProps = {
  pendency?: any;
  hasResolution?: boolean;
  solved?: boolean;
};

export const PendencyDetails = withStepperProps<PendencyInfoProps>(
  ({ pendency, hasResolution, solved, stepper }) => {
    const handleNextStep = useCallback(() => {
      stepper.nextStep();
    }, [stepper]);

    const renderDate = (date) => {
      if (date) {
        return moment(date).format('DD/MM/YYYY [às] HH:mm');
      }

      return 'Data indisponível';
    };

    return (
      <Container>
        <TagsRow>
          <Tag rounded small noWrap color={PendencyStatusColors?.[pendency?.status] || 'alert'}>
            {pendency?.status}
          </Tag>

          {!hasResolution && !solved && (
            <Tag color="negative" small rounded noWrap>
              Sem resolução
            </Tag>
          )}
        </TagsRow>

        <InfoRow>
          <InfoTitle>Tipo da pendência</InfoTitle>
          <InfoValue>{pendencyTitle(pendency?.pendingType)}</InfoValue>
        </InfoRow>

        <InfoRow>
          <InfoTitle>Agente que solicitou</InfoTitle>
          <InfoValue>
            {pendency.userWhoRequested && `${formatName(pendency.userWhoRequested, 2)}, `}
            {renderDate(pendency?.pendingDate)}
          </InfoValue>
        </InfoRow>

        <InfoRow width={294}>
          <InfoTitle>Motivo da pendência</InfoTitle>
          <InfoValue>{pendency?.reason}</InfoValue>
        </InfoRow>

        {solved && (
          <InfoRow withTopBorder>
            <InfoTitle>Resolução da pendência</InfoTitle>
            <InfoValue>
              {pendency.userWhoSolved && `${formatName(pendency.userWhoSolved, 2)}, `}
              {renderDate(pendency?.fixedDate)}
            </InfoValue>
          </InfoRow>
        )}

        {hasResolution && !solved && (
          <CustomButton onClick={handleNextStep} customColor={colors.gray.main} rounded fullWidth>
            Prosseguir
          </CustomButton>
        )}
      </Container>
    );
  },
);
