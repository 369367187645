import React from 'react';

import { TicketModal } from '~/components';

import { useDrawer } from '~/hooks/useDrawer';
import { StyledButton } from './TicketButton.styled';

export function TicketButton() {
  const { openDrawer } = useDrawer();

  const handleOpenTicket = () => {
    openDrawer('ticket-modal', <TicketModal />, {
      title: 'Registre seu ticket',
      width: 460,
      closable: true,
      maskClosable: false,
    });
  };

  return (
    <StyledButton
      title="Abrir ticket"
      onClick={handleOpenTicket}
      data-testid="ticket-open-button"
    />
  );
}
