import React, { useCallback } from 'react';
import { TableColumns } from '~/ui/components';
import { useAwaitControl } from 'react-redux-await-control';
import moment from 'moment/moment';

import CommissioningType from '~/typings/enums/CommissioningType';
import { cancelQiProposal, listReturnsParati, removeProposal } from '~/store/proposals/actions';
import { useDrawer } from '~/hooks/useDrawer';
import { MenuOptions } from '~/components';

import { useModal } from '~/hooks/useModal';
import { ParatiProposal } from '~/typings/entities/Proposals';
import { StubbornTable } from './StubbornTable';
import { ProposalDetails } from './ProposalDetails';
import { ActionButton, CustomTable, TableTitle } from '../../Returns.styled';

export type ProposalsTableProps = {
  contractId: number;
  loan?: string;
};

export function ProposalsTable({ contractId, loan }: ProposalsTableProps) {
  const { openDrawer } = useDrawer();
  const { openModal } = useModal();

  const listReturnsParatiControl = useAwaitControl(listReturnsParati);
  const removeProposalControl = useAwaitControl(removeProposal);
  const removeQiProposalControl = useAwaitControl(cancelQiProposal);

  const returnsParatiLoading = listReturnsParatiControl.isRunning(contractId);

  const { paratiProposals, paratiProposalReturns } = listReturnsParatiControl.result(contractId);

  const handleDeleteProposal = useCallback(
    (id) => () => {
      if (loan === 'FGTS_NEW') {
        removeQiProposalControl.start({ proposalId: id });
      } else {
        removeProposalControl.start({ id, contractId });
      }
    },
    [contractId],
  );

  const handleDetailsClick = (proposal) => () => {
    const proposalReturns = paratiProposalReturns.filter(
      (proposalReturn) => proposalReturn.paratiProposal === proposal.proposalNumber,
    );

    openDrawer(
      'account-details',
      <ProposalDetails
        contractId={contractId}
        proposal={proposal}
        proposalReturns={proposalReturns}
      />,
      {
        title: 'Retornos da proposta',
        closable: true,
        width: 1000,
      },
    );
  };

  const handleStubbornClick = (proposal) => () => {
    openModal(<StubbornTable contractId={contractId} returnId={proposal.proposalNumber} />, {
      title: 'Teimosinha',
      closable: true,
      width: 'auto',
    });
  };

  const getOptions = useCallback(
    (proposal) => [{ label: 'Cancelar', onClick: handleDeleteProposal(proposal?.id) }],
    [handleDeleteProposal],
  );

  const columns: TableColumns<ParatiProposal> = [
    { accessorKey: 'proposalNumber', header: () => 'COD', maxSize: 236 },
    { accessorKey: 'status', header: () => 'STATUS' },
    {
      accessorKey: 'creationDate',
      header: () => 'DATA',
      maxSize: 100,
      cell: (info) => {
        const date = info.getValue();
        return date ? moment(date).format('DD/MM/YYYY [às] HH:mm:ss') : '-';
      },
    },
    {
      accessorKey: 'type',
      header: () => 'TIPO',
      cell: (info) => CommissioningType(info.getValue()),
    },
    {
      id: 'stubborns',
      header: () => 'TEIMOSINHA',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: ({ row }) => (
        <ActionButton onClick={handleStubbornClick(row.original)} icon="FileSearch" />
      ),
    },
    {
      id: 'details',
      header: () => 'DETALHES',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: ({ row }) => (
        <ActionButton onClick={handleDetailsClick(row.original)} icon="FileSearch" />
      ),
    },
    {
      id: 'actions',
      header: () => 'AÇÕES',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: ({ row }) => (
        <MenuOptions options={getOptions(row.original)} width="auto">
          <ActionButton icon="Dots" />
        </MenuOptions>
      ),
    },
  ];

  if (!paratiProposals?.length) {
    return null;
  }

  return (
    <>
      <TableTitle>Propostas</TableTitle>
      <CustomTable
        noItemsMessage="Nenhum retorno de conta encontrado"
        loading={returnsParatiLoading}
        data={paratiProposals}
        columns={columns}
      />
    </>
  );
}
