import React, { useState } from 'react';
import { Popover } from '~/ui/components';
import { TooltipPlacement, TooltipTrigger } from 'antd/lib/tooltip';
import { ActionButton, Option, OptionsList } from './MenuOptions.styled';

export type OptionProps = {
  icon?: string;
  label: string;
  onClick?: () => void;
  disabled?: boolean;
  key?: string;
};

type MenuOptionsProps = {
  placement?: TooltipPlacement;
  trigger?: TooltipTrigger;
  onChangeVisibility?: (visible: boolean) => void;
  width?: number | string;
  options: OptionProps[];
  children?: React.ReactNode;
  closeOnClick?: boolean;
};

export function MenuOptions({
  placement,
  trigger,
  onChangeVisibility,
  width,
  options,
  children,
  closeOnClick = true,
  ...props
}: MenuOptionsProps) {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
    onChangeVisibility?.(visible);
  };

  const handleOptionClick = (option) => () => {
    if (closeOnClick) {
      handleVisibleChange(false);
    }
    option?.onClick();
  };

  return (
    <Popover
      placement={placement || 'bottomRight'}
      trigger={trigger || 'click'}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      width={width || 258}
      noHeader
      {...props}
      content={
        <OptionsList data-testid="menu-options-content">
          {options?.map((option) => (
            <Option
              key={option.key || option.label}
              disabled={option.disabled}
              icon={option.icon}
              onClick={handleOptionClick(option)}
            >
              {option.label}
            </Option>
          ))}

          {!options?.length && (
            <Option disabled>
              <span>Nenhuma opção disponível</span>
            </Option>
          )}
        </OptionsList>
      }
    >
      {children || <ActionButton />}
    </Popover>
  );
}
