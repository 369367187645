import styled from 'styled-components';

import { Table } from '~/ui/components';

export const UiTable = styled(Table)`
  padding: 0px 8px;

  tbody > tr {
    height: 32px;
    border-bottom: none;
  }
`;
