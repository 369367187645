import React, { useEffect, useMemo } from 'react';
import { Icon, Timeline } from 'antd';
import moment from 'moment';
import { useAwaitControl } from 'react-redux-await-control';

import { getContractLogs, getContractLogsByExternalID } from '~/store/contracts/actions';
import { LOAN_TYPES, Loans } from '~/typings/enums/LoanType';
import colors from '~/ui/theme/colors';

import {
  HistoryDate,
  HistoryItem,
  HistoryItemTitle,
  HistoryRow,
  LoadingContainer,
  TimelineContainer,
} from './ContractHistory.styled';

const CARD_PRODUCTS = [LOAN_TYPES.CONSIGNED_CARD_RMC, LOAN_TYPES.BENEFIT_CARD_RCC];

interface ContractHistoryProps {
  id: string;
  product?: Loans;
  externalId?: string;
}

export function ContractHistory({ id, product, externalId }: ContractHistoryProps) {
  const logsControl = useMemo(() => {
    if (CARD_PRODUCTS.includes(product)) {
      return useAwaitControl(getContractLogsByExternalID);
    }

    return useAwaitControl(getContractLogs);
  }, [product]);

  const loading = logsControl.isRunning();

  const logs = logsControl.result();

  const keysFilter = (el) => !['Data', 'Motivo'].includes(el);

  const formatDate = (date) =>
    moment(date, 'DD/MM/YYYY hh:mm:ss').format('DD [de] MMM YYYY HH[h]mm');

  useEffect(() => {
    if (id) {
      logsControl.start({ id, product, externalId });
    }

    return () => {
      logsControl.clear();
    };
  }, []);

  if (loading) {
    return (
      <LoadingContainer>
        <Icon type="loading" style={{ fontSize: '35px', color: colors.primary.main }} />
      </LoadingContainer>
    );
  }

  return (
    <TimelineContainer>
      <Timeline>
        {logs?.map((log, key) => (
          <HistoryRow key={key}>
            <HistoryDate>{formatDate(log.Data)}</HistoryDate>
            <HistoryItem>
              <HistoryItemTitle>{log.Motivo}</HistoryItemTitle>
              {Object.keys(log)
                .filter(keysFilter)
                .map((key) => (
                  <div key={key}>
                    <b>{key}: </b>
                    <span>{log[key]}</span>
                  </div>
                ))}
            </HistoryItem>
          </HistoryRow>
        ))}
      </Timeline>
    </TimelineContainer>
  );
}
