import styled, { css } from 'styled-components';
import get from 'lodash/get';

import { themeToggleColor } from '~/common/utils/theme';
import { Typography } from '~/ui/components';
import { ColorsTypes } from '~/ui/theme/colors';
import { CSSProperties } from 'react';

export const TagContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

export interface TagItemProps {
  style?: CSSProperties;
  className?: string;
  bgColor?: string;
  textColor?: string;
  colorPalette?: ColorsTypes;
  small?: boolean;
  rounded?: boolean;
  noWrap?: boolean;
  ellipsis?: boolean;
}

export const Tag = styled<TagItemProps>(Typography).attrs(
  ({ theme, colorPalette = 'neutral', textColor, small = true, rounded = true }) => ({
    element: 'span',
    type: small ? 'footnote' : 'paragraph',
    weight: rounded ? 600 : 400,
    color:
      get(theme.colors, textColor) ||
      textColor ||
      theme.colors[colorPalette]?.primaryAlt ||
      theme.colors[colorPalette]?.['800'],
    className: 'tag',
  }),
)`
  ${({ theme, colorPalette = 'neutral', bgColor, small, textColor, rounded, noWrap }) => css`
    background-color: ${get(theme.colors, bgColor) ||
    bgColor ||
    theme.colors[colorPalette]?.secondary ||
    theme.colors[colorPalette]?.['50']};
    padding: 12px 14px 12px 12px;
    gap: 12px;
    border-radius: 0 8px 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: ${noWrap ? 'nowrap' : 'normal'};

    svg {
      cursor: pointer;
      fill: ${textColor || theme.colors[colorPalette]['800']};
      flex: none;
    }

    ${small &&
    css`
      padding: 2px 10px 2px 8px;
      gap: 4px;
    `}

    ${rounded &&
    css`
      padding: ${small ? '2px 12px' : '4px 12px'};
      border-radius: 8px;
    `}
  `}

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    `}
`;

export const AddTagInput = styled(Tag).attrs(({ theme }) => ({
  element: 'div',
  color: theme.colors.element.primary,
  bgColor: themeToggleColor(theme, 'background.tertiary', { dark: 'background.primary' }),
  weight: 600,
  rounded: true,
}))`
  cursor: pointer;
`;
