import React from 'react';

import { cellToDate, cellToMoney } from '~/common/utils/table';
import { Invoice } from '~/typings/entities/Card';

import { Button, CellProps, TableColumns, Tag } from '~/ui/components';

import { ColorsTypes } from '~/ui/theme/colors';
import { UiTable } from './InvoicesTable.styles';

type InvoicesTableProps = {
  tableData: Invoice[];
};

function InvoicesTable({ tableData }: InvoicesTableProps) {
  const cellToDetails = () => (
    <Button
      onClick={() => null}
      customColor="brand.primary"
      iconOnly
      icon="FileSearch"
      variant="text"
    />
  );

  const cellToStatus = (info: CellProps<Invoice>) => {
    const value = info.getValue();
    const statusColors = { PAID: 'positive', PENDING: 'info', OVERDUE: 'negative' };
    const statusNames = { PAID: 'paga', PENDING: 'aberta', OVERDUE: 'vencida' };
    const color = statusColors[value as ColorsTypes] || 'neutral';
    return <Tag color={color}>Fatura {statusNames?.[value as ColorsTypes] || value}</Tag>;
  };

  const columns: TableColumns<Invoice> = [
    { accessorKey: 'closingDate', header: () => 'FECHAMENTO', cell: cellToDate('DD/MM/YY') },
    { accessorKey: 'dueDate', header: () => 'VENCIMENTO', cell: cellToDate('DD/MM/YY') },
    { accessorKey: 'status', header: () => 'STATUS', cell: cellToStatus },
    {
      accessorKey: 'discount',
      header: () => 'DESCONTO INSS',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: cellToMoney,
    },
    {
      accessorKey: 'payAmount',
      header: () => 'VALOR A PAGAR',
      cellAlign: 'right',
      headerAlign: 'right',
      cell: cellToMoney,
    },
    {
      accessorKey: 'totalAmount',
      header: () => 'TOTAL A PAGAR',
      cellAlign: 'right',
      headerAlign: 'right',
      cell: cellToMoney,
    },
    {
      id: 'details',
      header: () => 'DETALHES',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: cellToDetails,
    },
  ];

  return (
    <UiTable
      noItemsMessage="Nenhuma transação encontrada"
      loading={false}
      columns={columns}
      data={tableData}
      noPadding
      testId="invoice"
    />
  );
}

export default InvoicesTable;
