import styled from 'styled-components';

import { themeColor } from '~/common/utils/theme';
import { Table } from '~/ui/components';

export const UiTable = styled(Table)`
  thead > tr {
    border-bottom: 1px solid ${themeColor('divider.primary')};

    th {
      padding-bottom: 12px;
    }
  }

  tbody > tr {
    border-bottom: none;
  }
`;
