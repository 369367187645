import styled, { css } from 'styled-components';
import { Button, Typography } from '~/ui/components';
import { isBoolean } from 'lodash';
import { themeColor, themeToggleColor } from '~/common/utils/theme';

type ContainerProps = {
  disabled?: boolean;
  width?: string | number;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  width: 100%;
  max-width: ${({ width }) =>
    width && typeof width === 'number' ? `${width}px` : width || '344px'};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6 !important;
      pointer-events: none;
    `};
`;

export const InfoRow = styled.div<{ withTopBorder?: boolean; width?: number }>`
  width: 100%;
  max-width: ${({ width }) => (width ? `${width}px` : 'none')};
  gap: 4px;

  ${({ withTopBorder }) =>
    withTopBorder &&
    css`
      border-top: 1px solid ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
      padding-top: 16px;
      max-width: 262px;
    `}
`;

export const InfoTitle = styled(Typography).attrs({
  type: 'headingXSmall',
  weight: 500,
})``;

export const InfoValue = styled(Typography).attrs({
  type: 'caption',
  element: 'span',
})``;

export const DocumentsList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 98px;
  overflow: hidden;
  animation: expandIn 0.2s ease;

  @keyframes expandIn {
    0% {
      height: 0;
    }
    100% {
      height: 98px;
    }
  }
`;

export const TagsRow = styled.div`
  display: flex;
  gap: 16px;
`;
