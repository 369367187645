import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';

import { useModal } from '~/hooks/useModal';
import {
  AgentInfo,
  CustomizableModalContent,
  PermissionModal,
  Popover,
  TableColumns,
  Tag,
  ListGroup,
  Typography,
  Pagination,
  Button,
} from '~/ui/components';
import { Dots } from '~/ui/assets/icons';
import Images from '~/assets/images';
import { useDrawer } from '~/hooks/useDrawer';
import { useAwaitControl } from 'react-redux-await-control';
import { getUsers } from '~/store/permLevels/actions';
import { UserType } from '~/typings/entities/user';
import { CustomizedTable } from '../Users.styled';
import { MOCKED_AGENT } from '../Users.mocks';

const USERS_PER_PAGE = 10;
interface IUsersTableProps {
  name: string;
  email: string;
  clearSearch?: boolean;
}

const isDisable = (tableColumnData) => !tableColumnData.row.original.status;

export function UsersTable({ email, name, clearSearch = false }: IUsersTableProps) {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [paginatedUsers, setPaginatedUsers] = useState([]);
  const [viewPopover, setViewPopover] = useState('');

  const { openModal, closeAll } = useModal();
  const { openDrawer } = useDrawer();
  const getUsersControl = useAwaitControl(getUsers);
  const usersResult = getUsersControl.result();
  const loadingUsers = getUsersControl.isRunning();
  const theme = useTheme();

  const totalUsers = useMemo(() => usersResult?.total || 0, [usersResult]);

  const primary = theme.colors.primary.main;
  const gray = theme.colors.gray[200];
  const black = theme.colors.gray.main;

  const handleOnClickRowPermission = useCallback((user) => {
    openModal(<PermissionModal user={user} />, {
      id: 'permission-modal',
      closable: true,
      width: 1118,
    });
  }, []);

  const handleDeletAccount = useCallback(() => {
    // aplicar condicional se houver a exclusão
    closeAll();
    openModal(
      <CustomizableModalContent
        title="Usuário excluído"
        text="O usuário foi excluído e não possui mais acesso à plataforma."
        image={Images.error}
      />,
      {
        id: 'permission-modal',
        closable: true,
        width: 361,
      },
    );
  }, []);

  const handleOnClickActions = useCallback((item, row: UserType) => {
    if (item.id === 'information') {
      openDrawer(
        'userInformation',
        <AgentInfo
          image={Images.error}
          user={row}
          informReason="Essa agente foi desligada no dia 01/02/2023 e por isso estamos fazendo a exclusão da sua conta."
          agent={MOCKED_AGENT}
        />,
        { title: 'Informações do agente', closable: true, width: 427 },
      );
    }

    if (item.id === 'delete') {
      openModal(
        <CustomizableModalContent
          title="Insira o motivo da inativação"
          text="Essa informação é importante para entendermos melhor sua decisão."
          image={Images.error}
          withInput
          inputPlaceholder="Motivo da inativação"
          buttonLabel="Inativar conta"
          buttonColor="black"
          buttonVariant="contained"
          onClick={handleDeletAccount}
        />,
        {
          id: 'delete-user',
          title: 'Inativar usuário',
          closable: true,
          width: 361,
        },
      );
    }

    setViewPopover('');

    return null;
  }, []);

  const handleRowActions = useCallback(
    (row) =>
      isDisable(row)
        ? [{ id: 'information', label: 'Informações do usuário  ' }]
        : [
            { id: 'information', label: 'Informações do usuário' },
            { id: 'delete', label: 'Inativar agente' },
          ],
    [],
  );

  const usersTableColumns: TableColumns<UserType> = useMemo(
    () => [
      {
        accessorKey: 'fullName',
        header: 'NOME',
        cellAlign: 'left',
        cell: (tableColumnData) => (
          <Typography
            type="caption"
            element="span"
            color={isDisable(tableColumnData) ? gray : black}
          >
            {tableColumnData.row.original.fullName}
          </Typography>
        ),
      },
      {
        accessorKey: 'office',
        header: 'CARGO',
        cellAlign: 'left',
        cell: (tableColumnData) => (
          <Tag small rounded className="text-center" color="neutral" style={{ fontWeight: 700 }}>
            {tableColumnData.row.original.office}
          </Tag>
        ),
      },
      {
        accessorKey: 'status',
        header: 'STATUS',
        cell: (tableColumnData) => (
          <Tag
            small
            rounded
            className="text-center"
            color={isDisable(tableColumnData) ? 'negative' : 'positive'}
            style={{ fontWeight: 700 }}
          >
            {tableColumnData.row.original.status ? 'Ativo' : 'Inativo'}
          </Tag>
        ),
      },
      {
        accessorKey: 'group',
        header: 'TIME',
        cell: (tableColumnData) => (
          <Typography
            type="caption"
            element="span"
            color={isDisable(tableColumnData) ? gray : black}
          >
            {tableColumnData.row.original.group}
          </Typography>
        ),
      },
      {
        accessorKey: 'email',
        header: 'E-MAIL',
        headerAlign: 'left',
        cell: (tableColumnData) => (
          <Typography
            type="caption"
            element="span"
            color={isDisable(tableColumnData) ? gray : black}
          >
            {tableColumnData.row.original.email}
          </Typography>
        ),
      },
      {
        accessorKey: 'username',
        headerAlign: 'center',
        cellAlign: 'center',
        header: 'PERMISSÕES',
        cell: (tableColumnData) => (
          <Button
            disabled={isDisable(tableColumnData)}
            onClick={() => handleOnClickRowPermission(tableColumnData.row.original)}
            icon="UserCircle"
            color="primary"
            variant="text"
          />
        ),
      },
      {
        id: 'actions',
        cellAlign: 'center',
        headerAlign: 'center',
        header: () => 'AÇÕES',
        cell: (tableColumnData) => (
          <Popover
            width={250}
            placement="bottom"
            noHeader
            visible={viewPopover === tableColumnData.row.original.id}
            onVisibleChange={() => setViewPopover(tableColumnData.row.original.id)}
            content={
              <ListGroup
                items={handleRowActions(tableColumnData)}
                onClickItem={(e) => handleOnClickActions(e, tableColumnData.row.original)}
              />
            }
          >
            <Dots color={primary} />
          </Popover>
        ),
      },
    ],
    [viewPopover],
  );

  useEffect(() => {
    if (!paginatedUsers[currentPage]) {
      getUsersControl.start({ limit: USERS_PER_PAGE, page: currentPage, email, name });
    }
  }, [email, name, currentPage]);

  useEffect(() => {
    setPaginatedUsers([]);
    getUsersControl.start({ limit: USERS_PER_PAGE, page: 1, email, name });
  }, [email, name]);

  useEffect(() => {
    setPaginatedUsers([]);
    getUsersControl.start({ limit: USERS_PER_PAGE, page: 1 });
  }, [clearSearch]);

  useEffect(() => {
    setPaginatedUsers((currentValue) => ({ ...currentValue, [currentPage]: usersResult.users }));
  }, [usersResult]);

  return (
    <>
      <CustomizedTable
        data={paginatedUsers[currentPage]}
        columns={usersTableColumns}
        rowHeight={48}
        isTHeadFixed
        maxHeight={480}
        loading={loadingUsers}
        responsiveCols={['fullName', 'email', 'username', 'actions']}
        shimmerLines={10}
      />
      {usersResult && (
        <Pagination
          totalPages={Math.ceil(totalUsers / USERS_PER_PAGE)}
          onClick={(page) => setCurrentPage(page)}
        />
      )}
    </>
  );
}
