import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import { useAwaitControl } from 'react-redux-await-control';
import _ from 'lodash';

import { MultiSelect } from '~/ui/components/MultiSelect/MultiSelect';
import { fetchAgreements } from '~/store/utilities/actions';
import { fetchLoanTypes } from '~/store/contracts/actions';
import LoanType from '~/typings/enums/LoanType';
import { Button, Input, Select, SelectDate } from '~/ui/components';
import { listTagsTypes } from '~/store/tags/actions';
import { ActionButton } from '~/screens/ContractDetails/Panels/Returns/Returns.styled';
import { MenuOptions, OptionProps } from '~/components';
import { ActionsRow, FilterContainer, FilterForm, Title } from './Filters.styled';

export type FiltersProps = {
  onSubmit?: (values: any) => void;
  loading?: boolean;
  options?: OptionProps[];
};

export function Filters({ onSubmit, loading, options }: FiltersProps) {
  const history = useHistory();
  const params = queryString.parse(history.location.search);
  const fetchAgreementsControl = useAwaitControl(fetchAgreements);
  const fetchLoanTypesControl = useAwaitControl(fetchLoanTypes);
  const fetchTagsControl = useAwaitControl(listTagsTypes);

  const agreementsResult = fetchAgreementsControl.result();
  const loanTypesResult = fetchLoanTypesControl.result();
  const tagsResult = fetchTagsControl.result('CONTRACT');

  const agreements = useMemo(
    () => agreementsResult.map(({ id, name }) => ({ label: name, value: String(id) })),
    [agreementsResult],
  );

  const loanTypes = useMemo(
    () => loanTypesResult?.map((key) => ({ label: LoanType(key), value: key })),
    [loanTypesResult, LoanType],
  );

  const tags = useMemo(
    () => tagsResult?.map(({ id, name }) => ({ label: name, value: String(id) })),
    [tagsResult, LoanType],
  );

  const filters = useMemo(
    () => [
      {
        id: 'name',
        label: 'Nome do cliente:',
        initialValue: params.name,
        input: <Input placeholder="Insira o nome do cliente" allowClear />,
      },
      {
        id: 'document',
        label: 'CPF:',
        initialValue: params.document,
        input: <Input placeholder="Insira o CPF" allowClear />,
      },
      {
        id: 'dateRange',
        label: 'Período:',
        initialValue:
          params.startDate && params.endDate ? [params.startDate, params.endDate] : null,
        input: <SelectDate selectRange maxDate={new Date()} />,
      },
      {
        id: 'loanTypes',
        label: 'Tipo de produto:',
        initialValue: params.loanTypes,
        input: (
          <MultiSelect
            options={loanTypes}
            placeholder="Selecione o tipo do empréstimo"
            loading={!loanTypes.length}
          />
        ),
      },
      {
        id: 'agreementId',
        label: 'Convênio:',
        initialValue: params.agreementId ? [params.agreementId] : null,
        input: (
          <Select
            options={agreements}
            placeholder="Selecione o convênio"
            loading={!agreements.length}
          />
        ),
      },
      {
        id: 'tagTypes',
        label: 'Tags:',
        initialValue: params.tagTypes,
        input: (
          <MultiSelect options={tags} placeholder="Selecione as tags" loading={!tags.length} />
        ),
      },
      {
        id: 'financialInstitutionKey',
        label: 'Chave da instituição financeira:',
        initialValue: params.financialInstitutionKey,
        input: <Input placeholder="Insira a chave" allowClear />,
      },
      {
        id: 'financialInstitutionKeyPort',
        label: 'Chave de portabilidade da inst. fin.:',
        initialValue: params.financialInstitutionKeyPort,
        input: <Input placeholder="Insira a chave" allowClear />,
      },
      {
        id: 'ccbNumber',
        label: 'Número da CCB:',
        initialValue: params.financialInstitutionKeyPort,
        input: <Input placeholder="Insira o número da CCB" allowClear />,
      },
    ],
    [agreements, loanTypes, tags],
  );

  const handleOnSubmit = (values) => {
    onSubmit?.(_.omitBy(values, _.isEmpty));
  };

  useEffect(() => {
    if (!agreementsResult.length) fetchAgreementsControl.start();
    if (!loanTypesResult.length) fetchLoanTypesControl.start();
    if (!tagsResult.length) fetchTagsControl.start({ type: 'CONTRACT' }, { actionId: 'CONTRACT' });
  }, []);

  return (
    <FilterContainer>
      <Title>
        Filtros
        {options && (
          <MenuOptions options={options} width="auto">
            <ActionButton icon="Dots" />
          </MenuOptions>
        )}
      </Title>

      <FilterForm inputs={filters} onSubmit={handleOnSubmit} loading={loading ? 1 : 0}>
        <ActionsRow>
          <Button icon="Search" size="sm" rounded className="submit-button" loading={loading}>
            Filtrar
          </Button>
        </ActionsRow>
      </FilterForm>
    </FilterContainer>
  );
}
