import styled, { css } from 'styled-components';
import { Typography } from '~/ui/components';

export const SidebarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Content = styled.div`
  background-color: #ffffff;
  padding: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ActionsTitle = styled(Typography).attrs({
  type: 'bodyLarge',
  element: 'h3',
  color: 'element.primary',
  weight: 600,
})``;

type ActionItemProps = {
  disabled?: boolean;
};

export const ActionItem = styled(Typography).attrs({
  type: 'bodySmall',
  color: 'element.primary',
})<ActionItemProps>`
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.element.secondary};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    `}
`;
