import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import moment from 'moment';
import _ from 'lodash';
import { useTheme } from 'styled-components';
import { message } from 'antd';

import { CloseCircle, Loading, Transfer } from '~/ui/assets/icons';
import { Alert, Tag } from '~/ui/components';
import * as PeopleActions from '~/store/people/actions';
import * as ClixActions from '~/store/clix/actions';
import { useDrawer } from '~/hooks/useDrawer';
import { getInvoiceStatus } from '~/common/clix';
import { formatName, Money } from '~/common';
import { WarrantyType } from '~/typings/enums/Clix';

import { GroupedTransactions } from '../../Sections';

import {
  InvoiceHeader,
  InvoiceTitle,
  LoadingContainer,
  CircleIcon,
  InvoiceBody,
  InvoiceHistoryContainer,
  InvoiceDetailsWrapper,
  InvoiceValue,
  ClosingDate,
  DueDate,
  InvoiceWrapper,
  CopyableText,
  CopyButton,
  CopyableTextWrapper,
  NoContentMessage,
} from './InvoiceDetails.styled';

interface InvoiceDetailsProps {
  dueDate: string;
  warranty: WarrantyType;
}

export function InvoiceDetails({ dueDate, warranty }: InvoiceDetailsProps) {
  const { closeDrawer } = useDrawer();
  const [copied, setCopied] = useState(false);
  const { colors } = useTheme();

  const [getPersonDetailsControl, getClixInvoiceDetailsControl] = useAwaitControl([
    PeopleActions.getPersonDetail,
    ClixActions.getInvoiceDetails,
  ]);

  const personDetails = getPersonDetailsControl.result();
  const invoiceDetails = getClixInvoiceDetailsControl.result();
  const personDetailsLoading = getPersonDetailsControl.isRunning();
  const invoiceLoading = getClixInvoiceDetailsControl.isRunning();

  const loading = useMemo(
    () => personDetailsLoading || invoiceLoading,
    [personDetailsLoading, invoiceLoading],
  );

  const formattedDueDate = useMemo(
    () =>
      invoiceDetails?.invoice?.dueDate
        ? formatName(moment(invoiceDetails?.invoice?.dueDate).format('DD [de] MMMM'))
        : '-',
    [invoiceDetails],
  );

  const formattedClosingDate = useMemo(
    () =>
      invoiceDetails?.invoice?.closingDate
        ? formatName(moment(invoiceDetails?.invoice?.closingDate).format('DD [de] MMMM'))
        : '-',
    [invoiceDetails],
  );

  const groupedTransactions = useMemo(() => {
    const formattedDate = invoiceDetails.transactions?.map((el) => ({
      ...el,
      date: moment(el.dateProcessing).format('YYYY-MM-DD'),
    }));
    return _.groupBy(formattedDate, 'date') || [];
  }, [invoiceDetails.transactions]);

  const groupKeys = useMemo(
    () => _.sortBy(Object.keys(groupedTransactions)).reverse(),
    [groupedTransactions],
  );

  const renderInvoiceStatus = useCallback(() => {
    const { label, colorPalette } = getInvoiceStatus(invoiceDetails?.invoice);
    const textColor = colors?.[colorPalette]?.primaryAlt;
    const bgColor = colors?.[colorPalette]?.secondary;
    return (
      <Tag small rounded textColor={textColor} bgColor={bgColor}>
        {label || '-'}
      </Tag>
    );
  }, [invoiceDetails]);

  const handleCopyText = useCallback(() => {
    const clipBoard = navigator.clipboard;
    clipBoard.writeText(invoiceDetails.invoice?.paymentCode).then(
      () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 3000);
      },
      () => {
        message.error('Não foi possível copiar o código');
      },
    );
  }, [invoiceDetails.invoice?.paymentCode]);

  useEffect(() => {
    if (personDetails.document && dueDate) {
      getClixInvoiceDetailsControl.start({ dueDate, document: personDetails.document, warranty });
    }
  }, [dueDate, personDetails, warranty]);

  return (
    <>
      {!!loading && (
        <LoadingContainer>
          <Loading fill={colors.primary.main} height={45} width={45} />
        </LoadingContainer>
      )}

      {!!invoiceDetails && !loading && (
        <InvoiceDetailsWrapper>
          <InvoiceHeader>
            <CircleIcon>
              <Transfer width={12} height={12} />
            </CircleIcon>

            <InvoiceTitle>Detalhamento de fatura</InvoiceTitle>

            <CloseCircle
              width={25}
              height={25}
              className="close-icon"
              onClick={() => closeDrawer('invoiceDetails')}
            />
          </InvoiceHeader>

          <InvoiceWrapper>
            {renderInvoiceStatus()}

            <InvoiceValue>
              {invoiceDetails?.invoice?.value ? Money(invoiceDetails?.invoice?.value) : '-'}
            </InvoiceValue>

            <div>
              <ClosingDate>
                Dia de fechamento: <b>{formattedClosingDate}</b>
              </ClosingDate>

              <DueDate>
                Dia de vencimento: <b>{formattedDueDate}</b>
              </DueDate>
            </div>

            {!!invoiceDetails.invoice?.paymentCode && (
              <>
                <Alert
                  className="alert-copy"
                  label="Copie o código abaixo. Em seguida, você pode colar e enviar para o seu cliente."
                  status="neutral"
                />

                <CopyableTextWrapper>
                  <CopyableText>{invoiceDetails.invoice?.paymentCode}</CopyableText>
                </CopyableTextWrapper>

                <CopyButton copied={copied} onClick={handleCopyText}>
                  {copied ? 'Código de barras copiado' : 'Copiar código de barras'}
                </CopyButton>
              </>
            )}
          </InvoiceWrapper>

          {!loading && !invoiceDetails.transactions?.length && (
            <NoContentMessage>Nenhuma transação</NoContentMessage>
          )}

          {!loading && !!invoiceDetails.transactions?.length && (
            <InvoiceBody>
              <InvoiceHistoryContainer>
                {groupKeys.map((key) => (
                  <GroupedTransactions
                    key={key}
                    date={key}
                    transactions={groupedTransactions[key]}
                  />
                ))}
              </InvoiceHistoryContainer>
            </InvoiceBody>
          )}
        </InvoiceDetailsWrapper>
      )}
    </>
  );
}
