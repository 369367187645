import React, { memo, useCallback } from 'react';
import moment from 'moment';

import { Flex } from '~/ui/components';
import { FormalizationDataType } from '~/typings/entities/Clix';
import { Info, Label, Wrapper } from './Modals.styles';

enum unicoValidation {
  LOW = 'Altíssima probabilidade',
  MEDIUM = 'Alta probabilidade',
  HIGH = 'Baixa probabilidade',
}

interface ContractPopoverProps {
  formalization: FormalizationDataType;
}

function VerificationData({ formalization }: ContractPopoverProps) {
  const dateFormatting = useCallback((date) => moment(date).format('DD/MM/YYYY [às] HH:mm'), []);

  const unicoSerpro = formalization?.unico?.serproScore
    ? formalization.unico.serproScore * 100
    : null;

  return (
    <Wrapper>
      <Flex direction="column" gap={32}>
        <Flex direction="row" justify="space-between">
          <Flex direction="column" width="fit-content" gap={24}>
            <Label>SCORE DE AUTENTICAÇÃO</Label>
            <Flex direction="column" gap={16}>
              <Info>Único</Info>
              <Info>Datavalid (via serpro)</Info>
              <Info>Datavalid (via unico)</Info>
            </Flex>
          </Flex>
          <Flex direction="column" width="fit-content" gap={24}>
            <Label>PONTUAÇÃO</Label>
            <Flex direction="column" align="center" gap={16}>
              <Info unico score={formalization?.unico?.score}>
                {formalization?.unico?.score ?? '-'}
              </Info>
              <Info score={formalization?.serpro?.score}>
                {formalization?.serpro?.score ?? '-'}
              </Info>
              <Info score={unicoSerpro}>{unicoSerpro ?? '-'}</Info>
            </Flex>
          </Flex>
          <Flex direction="column" width="fit-content" gap={24}>
            <Label>DATA DA VERIFICAÇÃO</Label>
            <Flex direction="column" align="center" gap={16}>
              <Info>
                {formalization?.unico?.validateDate
                  ? dateFormatting(formalization?.unico?.validateDate)
                  : '-'}
              </Info>
              <Info>
                {formalization?.serpro?.validateDate
                  ? dateFormatting(formalization?.serpro?.validateDate)
                  : '-'}
              </Info>
              <Info>
                {formalization?.unico?.validateDate
                  ? dateFormatting(formalization?.unico?.validateDate)
                  : '-'}
              </Info>
            </Flex>
          </Flex>
          <Flex direction="column" width="fit-content" align="center" gap={24}>
            <Label>RETORNO</Label>
            <Flex direction="column" align="center" gap={16}>
              <Info>{unicoValidation[formalization?.unico?.riskLevel] ?? '-'}</Info>
              <Info>{unicoValidation[formalization?.serpro?.riskLevel] ?? '-'}</Info>
              <Info>{unicoValidation[formalization?.unico?.riskLevel] ?? '-'}</Info>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Wrapper>
  );
}

export default memo(VerificationData);
