import styled, { css } from 'styled-components';

import { themeToggleColor } from '~/common/utils/theme';
import { Typography } from '~/ui/components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-block: 24px;
  gap: 16px;
  background-color: #fff;
  padding: 24px;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Flex = styled.div<{ alignCenter?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${({ alignCenter }) =>
    alignCenter &&
    css`
      justify-content: center;
      gap: 12px;
      align-items: center;
    `}

  img {
    max-width: 56px;
  }

  div {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: ${({ theme }) => theme.colors.background.secondary};
  border-radius: 16px;
  padding: 24px;
`;

export const Divider = styled.div<{ center?: boolean }>`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider.primary};
`;

export const InfoValue = styled(Typography).attrs(({ theme, color }) => ({
  type: 'paragraphSmall',
  element: 'span',
  weight: 700,
  color: color || themeToggleColor(theme, 'neutral.primary'),
}))``;

export const InfoLabel = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'label',
  weight: 400,
  color: theme.colors.neutral.primary,
}))``;

export const Description = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'label',
  weight: 400,
  color: theme.colors.element.secondary,
}))`
  margin-bottom: 24px;
`;

export const Value = styled(Typography).attrs(({ theme }) => ({
  type: 'headingMedium',
  element: 'span',
  weight: 700,
  color: theme.colors.brand.primary,
}))``;
