import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import isHotkey from 'is-hotkey';
import { Editable, withReact, Slate } from 'slate-react';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { deserialize, HOTKEYS, toggleMark, withHtml } from '~/common/utils/richText';
import { Leaf, Element, MarkButton } from '~/ui/components/RichText';
import DOMPurify from 'dompurify';
import { slateToHtml } from '@slate-serializers/html';
import {
  ActionButton,
  ActionButtonWrapper,
  ControlsPanel,
  Preview,
  EditorContainer,
} from './RichText.styled';

export type RichTextProps = {
  placeholder?: string;
  onChange?: (value: any) => void;
  handleAddImage?: () => void;
  value?: any;
  small?: boolean;
  showActionButton?: boolean;
  controlsVisible?: boolean;
};

export const RichText = forwardRef(
  (
    {
      placeholder,
      onChange,
      value,
      handleAddImage,
      small,
      showActionButton,
      controlsVisible,
    }: RichTextProps,
    ref,
  ) => {
    const containerRef = useRef<any>(null);
    const renderElement = useCallback((props) => <Element {...props} />, []);
    const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
    const editor = useMemo(() => withHtml(withReact(withHistory(createEditor()))), []);
    const [animation, setAnimation] = useState(null);
    const [focused, setFocused] = useState(false);
    const [currentValue, setCurrentValue] = useState(value);

    const parseHtml = (values) => {
      const html = values || '<p></p>';
      const parsed = new DOMParser().parseFromString(html, 'text/html');
      const fragment = deserialize(parsed.body);
      return fragment || [];
    };

    function removeEmptyTags(htmlString) {
      const regex = /<p[^>]*>(\s|&nbsp;)*<\/p>/gi;
      return htmlString.replace(regex, '');
    }

    const handleFocus = useCallback(() => {
      setFocused(true);
    }, [setFocused]);

    const handleOnChange = useCallback((values) => {
      setCurrentValue(removeEmptyTags(slateToHtml(values)));
      onChange?.(removeEmptyTags(slateToHtml(values)));
    }, []);

    const handleClickOutside = useCallback(
      (event) => {
        if (
          containerRef.current &&
          !containerRef.current?.contains(event.target) &&
          focused !== null
        ) {
          setFocused(false);
        }
      },
      [focused, containerRef, setFocused, animation],
    );

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [handleClickOutside]);

    useEffect(() => {
      if (focused !== null) {
        setAnimation(focused ? 'show' : 'hide');
      }
    }, [focused]);

    useEffect(() => {
      if (value !== currentValue) {
        setCurrentValue(value);
      }
    }, [value]);

    return (
      <EditorContainer
        ref={containerRef}
        className="editor-container"
        small={small}
        data-testid="rich-text-editor"
      >
        {value !== currentValue ? (
          <span>{value}</span>
        ) : (
          <Slate editor={editor} initialValue={parseHtml(value)} onValueChange={handleOnChange}>
            <Editable
              onFocus={handleFocus}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder={placeholder}
              onKeyDown={(event) => {
                for (const hotkey in HOTKEYS) {
                  if (isHotkey(hotkey, event as any)) {
                    event.preventDefault();
                    const mark = HOTKEYS[hotkey];
                    toggleMark(editor, mark);
                  }
                }
              }}
            />

            <ControlsPanel
              controlsVisible={controlsVisible}
              animation={animation}
              data-test-id="controls-panel"
            >
              <MarkButton
                height={12}
                format="bold"
                icon="Bold"
                data-testid="rich-text-bold-button"
              />
              <MarkButton
                height={12}
                format="italic"
                icon="Italic"
                data-testid="rich-text-italic-button"
              />
              <MarkButton
                format="underline"
                icon="Underline"
                data-testid="rich-text-underline-button"
              />
              {!!handleAddImage && <MarkButton icon="ImageFile" onClick={handleAddImage} />}

              {showActionButton && (
                <ActionButtonWrapper>
                  <ActionButton data-testid="rich-text-publish-button">Publicar</ActionButton>
                </ActionButtonWrapper>
              )}
            </ControlsPanel>
          </Slate>
        )}
      </EditorContainer>
    );
  },
);

type RenderRichTextProps = {
  html: string;
  bordered?: boolean;
  children?: React.ReactNode | string;
};

export function RenderRichText({ html, bordered, children }: RenderRichTextProps) {
  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  return (
    <Preview bordered={bordered}>
      <div dangerouslySetInnerHTML={createMarkup(html)} />
      {children}
    </Preview>
  );
}
