import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { remoteValues } from '~/common/utils/firebase/remoteConfig';
import { Alert, Form, RichText, Select, withStepperProps } from '~/ui/components';
import { getContractsOfPerson, getFGTSOpportunity, getPersonDetail } from '~/store/people/actions';

import {
  getServiceChannels,
  getReasons,
  getTicketTeams,
  getTicketProducts,
  getTicketJourneys,
  getTicketStatus,
  getTicketResolutionReasons,
  getTicketSubResolutionReasons,
  getTicketJourneysSteps,
} from '~/store/tickets/actions';
import { TicketData, TwilioInput } from '~/components';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import LoanType from '~/typings/enums/LoanType';
import ContractStatus from '~/typings/enums/ContractStatus';
import { Money } from '~/common';
import { CreateTicket } from '~/typings/entities/Ticket';
import { getLoggedUser } from '~/store/user/actions';
import toNumber from '~/common/masked/toNumber';
import { createSelectValues } from '~/common/selectValues';
import { MultiSelect } from '~/ui/components/MultiSelect/MultiSelect';
import { FGTSInitialJourneyStep, INSSInitialJourneyStep, getJourneyId } from '~/common/tickets';
import { useDrawer } from '~/hooks/useDrawer';
import usePersistTicketParams from '~/hooks/usePersistTicketParams';
import { FormWrapper, CustomButton } from '../TicketModal.styled';

type TicketFormProps = {
  setData?: (data: TicketData) => void;
  title?: string;
  data?: TicketData;
  isUpdating?: boolean;
};

export const TicketForm = withStepperProps<TicketFormProps>(
  ({ setData, stepper, title, data, isUpdating = false }) => {
    const { setConfig } = useDrawer();
    const form = useRef<WrappedFormUtils>();
    const { hiddeSubJustification } = remoteValues;

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedContracts, setSelectedContracts] = useState([]);
    const [selectedJourney, setSelectedJourney] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [contractOptions, setContractOptions] = useState<{ label: string; value: string }[]>();
    const [selectedJustification, setSelectedJustification] = useState<string | number>('');

    const getContractsOfPersonControl = useAwaitControl(getContractsOfPerson);
    const getServiceChannelsControl = useAwaitControl(getServiceChannels);
    const getReasonsControl = useAwaitControl(getReasons);
    const getTeamsControl = useAwaitControl(getTicketTeams);
    const getProductsControl = useAwaitControl(getTicketProducts);
    const getJourneysControl = useAwaitControl(getTicketJourneys);
    const getJourneysStepsControl = useAwaitControl(getTicketJourneysSteps);
    const personControl = useAwaitControl(getPersonDetail);
    const getLoggedUserControl = useAwaitControl(getLoggedUser);
    const getTicketStatusControl = useAwaitControl(getTicketStatus);
    const getTicketResolutionReasonsControl = useAwaitControl(getTicketResolutionReasons);
    const getTicketResolutionSubReasonsControl = useAwaitControl(getTicketSubResolutionReasons);
    const getFGTSOpportunityControl = useAwaitControl(getFGTSOpportunity);

    const loggedUser = getLoggedUserControl.result();
    const contracts = getContractsOfPersonControl.result().entries;
    const reasons = getReasonsControl.result();
    const channels = getServiceChannelsControl.result();
    const person = personControl.result();
    const teams = getTeamsControl.result();
    const products = getProductsControl.result();
    const journeys = getJourneysControl.result();
    const journeysSteps = getJourneysStepsControl.result();
    const status = getTicketStatusControl.result();
    const resolutionReasons = getTicketResolutionReasonsControl.result();
    const resolutionSubReasons = getTicketResolutionSubReasonsControl.result();
    const fgtsOpportunity = getFGTSOpportunityControl.result();

    const reasonsLoading = getReasonsControl.isRunning();
    const channelsLoading = getServiceChannelsControl.isRunning();
    const teamsLoading = getTeamsControl.isRunning();
    const productsLoading = getProductsControl.isRunning();
    const journeysLoading = getJourneysControl.isRunning();
    const statusLoading = getTicketStatusControl.isRunning();
    const resolutionReasonsLoading = getTicketResolutionReasonsControl.isRunning();
    const resolutionSubReasonsLoading = getTicketResolutionSubReasonsControl.isRunning();

    const { getPreFilledParam } = usePersistTicketParams(person.id);

    const contactOriginInitialValue = useMemo(() => {
      if (isUpdating) {
        return data?.ticket?.contactOrigin ? [data?.ticket?.contactOrigin] : [];
      }

      return data?.ticket?.contactOrigin || getPreFilledParam('ticketContactOrigin')
        ? [data?.ticket?.contactOrigin || getPreFilledParam('ticketContactOrigin')]
        : [];
    }, [data, isUpdating]);

    const channelInitialValue = useMemo(() => {
      if (isUpdating) {
        return createSelectValues(channels, data?.ticket?.serviceChannelId);
      }

      return createSelectValues(
        channels,
        data?.ticket?.serviceChannelId || Number(getPreFilledParam('ticketChannel')),
      );
    }, [data, isUpdating, channels]);

    const protocolInitialValue = useMemo(() => {
      if (isUpdating) {
        return data?.ticket?.protocolTwilio || null;
      }

      return data?.ticket?.protocolTwilio || getPreFilledParam('twillioProtocol') || null;
    }, [data, isUpdating]);

    const handleSelectionContractChange = useCallback(
      (selectedContractsIDs: string[]) => setSelectedContracts(selectedContractsIDs),
      [setSelectedContracts, selectedContracts],
    );

    const handleSelectionStatusChange = useCallback(
      ([selectedStatus]) => {
        setSelectedStatus(selectedStatus);
        setSelectedJustification('');
      },
      [selectedStatus],
    );

    const handleSelectionJustification = useCallback(([justificationId]) => {
      setSelectedJustification(justificationId);
    }, []);

    const filteredStatus = useMemo(() => {
      const filteredItems = status?.filter((status) => status.steps?.includes('CREATE_TICKET'));
      return filteredItems || [];
    }, [status]);

    const filteredJourneys = useMemo(() => {
      const filteredItems = journeys?.filter((journey) =>
        journey.products?.includes(selectedProduct),
      );

      return filteredItems || [];
    }, [journeys, selectedProduct]);

    const filteredResolutionReasons = useMemo(() => {
      const filteredItems = resolutionReasons?.filter(
        (reason) =>
          reason.journeys?.includes(selectedJourney) && reason.status?.includes(selectedStatus),
      );
      return filteredItems || [];
    }, [resolutionReasons, selectedStatus, selectedJourney]);

    const filteredResolutionSubReasons = useMemo(() => {
      const filteredItems = resolutionSubReasons?.filter((reason) =>
        reason.justificationIds?.includes(selectedJustification),
      );
      return filteredItems || [];
    }, [resolutionSubReasons, selectedJustification]);

    const getContractSelectOptions = (loanType: string): { label: string; value: string }[] => {
      let contractSelectOptions: { label: string; value: string }[] = [];
      const options = contracts
        .filter((contract) => contract.loanType === loanType)
        .sort((a, b) => {
          const dateA = new Date(a.contractDate).getTime();
          const dateB = new Date(b.contractDate).getTime();
          return dateB - dateA;
        });
      contractSelectOptions = options.map((contract) => ({
        label: `${contract.contractId} (${LoanType(contract.loanType)} / ${ContractStatus(
          contract.contractSituation,
        )} / ${Money(contract.contractValue)})`,
        value: `${contract.contractId}`,
      }));
      return contractSelectOptions;
    };

    const handleProductChange = useCallback(
      ([loanType]) => {
        setSelectedProduct(loanType);
        setContractOptions(getContractSelectOptions(loanType));
        if (loanType !== selectedProduct) {
          setSelectedContracts([]);
          form.current?.setFieldsValue({ contracts: [], journeyId: [], ticketReasonId: [] });
        }
      },
      [setSelectedProduct, selectedProduct],
    );

    const handleJourneyChange = useCallback(
      ([journey]) => {
        setSelectedJourney(journey);
        if (journey !== selectedJourney) {
          form.current?.setFieldsValue({ ticketReasonId: [] });
        }
      },
      [setSelectedJourney, selectedJourney],
    );

    const validateSelectValue = (value, last = false) =>
      (Array.isArray(value) ? value[last ? value.length - 1 : 0] : value) || null;

    const handleSubmit = (values: any) => {
      const { channel, ticketScheduleDate: scheduleDate, ...rest } = values;
      const serviceChannelId = validateSelectValue(channel, true);
      const contractId = validateSelectValue(values?.contractId);
      const ticketScheduleDate = scheduleDate ? scheduleDate.format('YYYY-MM-DD HH:mm:ss') : null;
      const personId = person?.id;
      const userCreated = loggedUser?.name;
      const userCreatedId = loggedUser?.id;
      const contracts = selectedContracts?.map((id: string) => ({ id: Number(id) }));
      const id = data?.ticket?.id;
      const priorityTicket = data?.ticket?.priorityTicket;
      const resolvingAreaId = data?.ticket?.resolvingAreaId;
      const conversationId = getPreFilledParam('conversationId');

      const ticket: CreateTicket = {
        ...rest,
        ticketScheduleDate,
        serviceChannelId,
        personId,
        userCreated,
        userCreatedId,
        contractId,
        contracts,
        id,
        priorityTicket,
        resolvingAreaId,
        conversationId,
      };

      setData?.({ ticket });
      stepper.nextStep();
    };

    const contractProduct = useMemo(() => {
      if (data?.ticket?.product) {
        return [data?.ticket?.product];
      }
      if (contracts && contracts.length > 0) {
        return [contracts[0].loanType];
      }
      return null;
    }, [data, contracts]);

    const getInitialJourney = useMemo(() => {
      const inss = ['FUTUREMARGIN', 'NEW', 'PORTABILITY', 'REFIN'];
      const qiTechOpportunity = fgtsOpportunity?.marginValues?.marginInformation?.marginCode;

      const operationStep = contracts?.find(
        (contract) => contract.contractId === selectedContracts?.[0],
      )?.operationStepType;

      const operationStepId = journeysSteps?.find(
        (step) => step.operationStepType === operationStep,
      )?.journeyId;

      if (contractProduct && contractProduct[0] === 'FGTS_NEW') {
        return FGTSInitialJourneyStep(
          person,
          filteredJourneys,
          qiTechOpportunity,
          selectedContracts,
          operationStepId,
        );
      }

      if (contractProduct && inss.includes(contractProduct[0])) {
        return INSSInitialJourneyStep(person, filteredJourneys, selectedContracts, operationStepId);
      }

      if (contractProduct && contractProduct[0] === 'CLIX_CLEAN') {
        return getJourneyId('Cobrança', filteredJourneys);
      }

      return getJourneyId('Outros', filteredJourneys) || [];
    }, [contractProduct, person, contracts, filteredJourneys, fgtsOpportunity, selectedContracts]);

    const filteredReasons = useMemo(() => {
      const filteredItems = reasons?.filter(
        (reason) =>
          reason.journeys?.includes(selectedJourney) && reason.products?.includes(selectedProduct),
      );
      return filteredItems || [];
    }, [journeys, selectedJourney, selectedProduct, getInitialJourney]);

    useEffect(() => {
      if (contractProduct) {
        setSelectedProduct(contractProduct[0]);
        setContractOptions(getContractSelectOptions(contractProduct[0]));
      }
    }, [contractProduct]);

    useEffect(() => {
      if (selectedProduct && contractOptions?.length) {
        setSelectedContracts([contractOptions[0].value]);
      }
    }, [selectedProduct, contractOptions]);

    useEffect(() => {
      if (!data?.ticket?.journeyId) {
        setSelectedJourney(getInitialJourney[0]);
      }
    }, [getInitialJourney]);

    const inputs = useMemo(
      () => [
        {
          id: 'product',
          label: 'Produto',
          initialValue: contractProduct,
          input: (
            <Select
              options={products}
              placeholder="Selecione o produto"
              onChange={handleProductChange}
              loading={productsLoading}
              fieldNames={{ label: 'description', value: 'name' }}
              testId="product"
            />
          ),
          options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
        },
        {
          id: 'contracts',
          label: 'Contrato (Opcional)',
          initialValue: selectedContracts,
          input: (
            <MultiSelect
              options={contractOptions}
              placeholder="Selecione o contrato (Opcional)"
              onChange={handleSelectionContractChange}
              disabled={!selectedProduct || contractOptions?.length === 0}
              initialValue={selectedContracts}
              testId="contracts"
            />
          ),
        },
        {
          id: 'journeyId',
          label: 'Etapa da jornada',
          initialValue: data?.ticket?.journeyId ? [data?.ticket?.journeyId] : getInitialJourney,
          input: (
            <Select
              options={filteredJourneys}
              placeholder="Selecione a etapa da jornada"
              loading={journeysLoading}
              onChange={handleJourneyChange}
              fieldNames={{ label: 'name', value: 'id' }}
              disabled={!selectedProduct}
              testId="journey"
            />
          ),
          options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
        },
        {
          id: 'ticketReasonId',
          label: 'Motivo da abertura',
          initialValue: data?.ticket?.ticketReasonId ? [data?.ticket?.ticketReasonId] : [],
          input: (
            <Select
              options={filteredReasons}
              placeholder="Selecione o motivo"
              loading={reasonsLoading}
              fieldNames={{ label: 'name', value: 'id' }}
              disabled={!selectedJourney}
              testId="opening-reason"
            />
          ),
          options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
        },
        {
          id: 'channel',
          label: 'Canal',
          initialValue: channelInitialValue,
          input: (
            <Select
              options={channels}
              placeholder="Selecione o canal"
              loading={channelsLoading}
              fieldNames={{ label: 'name', value: 'id' }}
              testId="channel"
            />
          ),
          options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
        },
        {
          id: 'contactOrigin',
          label: 'Origem do contato',
          initialValue: contactOriginInitialValue,
          input: (
            <Select
              options={[
                { label: 'Ativo', value: 'ACTIVE' },
                { label: 'Receptivo', value: 'RECEPTIVE' },
              ]}
              placeholder="Selecione a origem do contato"
              testId="contract-origin"
            />
          ),
          options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
        },
        {
          id: 'teamId',
          label: <>Time</>,
          labelHelp: {
            title: 'Selecione seu Time',
            content: 'Por favor, selecione o nome do time ao qual você pertence aqui na meutudo.',
          },
          initialValue: data?.ticket?.teamId ? [data?.ticket?.teamId] : null,
          input: (
            <Select
              options={teams}
              placeholder="Selecione o time"
              loading={teamsLoading}
              fieldNames={{ label: 'name', value: 'id' }}
              testId="team-id"
            />
          ),
          options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
        },
        {
          id: 'ticketStatusId',
          label: 'Status de resolução',
          initialValue: createSelectValues(status, data?.ticket?.ticketStatusId),
          input: (
            <Select
              options={filteredStatus}
              placeholder="Selecione o status"
              loading={statusLoading}
              fieldNames={{ label: 'name', value: 'id' }}
              onChange={handleSelectionStatusChange}
              testId="status"
            />
          ),
          options: {
            rules: [{ required: true, message: 'Selecione o status de resolução' }],
          },
        },
        {
          id: 'justificationResolutionId',
          label: 'Justificativa da resolução',
          initialValue: data?.ticket?.justificationResolutionId
            ? [data?.ticket?.justificationResolutionId]
            : null,
          input: (
            <Select
              options={filteredResolutionReasons}
              placeholder="Selecione a justificativa da resolução"
              loading={resolutionReasonsLoading}
              disabled={!filteredResolutionReasons?.length}
              fieldNames={{ label: 'justification', value: 'id' }}
              onChange={handleSelectionJustification}
              testId="justification-resolution"
            />
          ),
          options: {
            rules: [
              {
                required: !!filteredResolutionReasons?.length,
                message: 'Selecione o status de resolução',
              },
            ],
          },
        },
        {
          id: 'subJustificationResolutionId',
          label: 'Sub-justificativa',
          hidden: hiddeSubJustification,
          initialValue: data?.ticket?.subJustificationResolutionId
            ? [data?.ticket?.subJustificationResolutionId]
            : null,
          input: (
            <Select
              options={filteredResolutionSubReasons}
              placeholder="Selecione o a sub-justificativa da resolução"
              loading={resolutionSubReasonsLoading}
              disabled={!filteredResolutionSubReasons?.length}
              fieldNames={{ label: 'name', value: 'id' }}
              testId="sub-justification-resolution"
            />
          ),
          options: {
            rules: [
              {
                required: !!filteredResolutionSubReasons?.length,
                message: 'Selecione o status de resolução',
              },
            ],
          },
        },
        {
          id: 'protocolTwilio',
          initialValue: protocolInitialValue,
          input: <TwilioInput defaultChecked={!!protocolInitialValue} />,
          options: {
            normalize: toNumber,
            rules: [{ max: 49, message: 'O protocolo deve conter no max 50 caracteres' }],
          },
        },
        {
          id: 'description',
          label: 'Descrição',
          input: <RichText placeholder="Digite aqui a descrição do ticket" />,
          initialValue: data?.ticket?.description,
          options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
        },
      ],
      [
        data,
        reasons,
        channels,
        teams,
        products,
        journeys,
        filteredStatus,
        reasonsLoading,
        resolutionReasons,
        filteredResolutionReasons,
        resolutionReasonsLoading,
        statusLoading,
        filteredJourneys,
        filteredReasons,
        channelsLoading,
        teamsLoading,
        productsLoading,
        journeysLoading,
        selectedContracts,
        selectedProduct,
        selectedJourney,
        filteredResolutionSubReasons,
        resolutionSubReasonsLoading,
        resolutionSubReasons,
        contactOriginInitialValue,
        channelInitialValue,
        protocolInitialValue,
      ],
    );

    useEffect(() => {
      if (!channels?.length) getServiceChannelsControl.start();
      if (!reasons?.length) getReasonsControl.start();
      if (!teams?.length) getTeamsControl.start();
      if (!products?.length) getProductsControl.start();
      if (!journeys?.length) getJourneysControl.start();
      if (!journeysSteps?.length) getJourneysStepsControl.start();
      if (!status?.length) getTicketStatusControl.start();
      if (!resolutionReasons?.length) getTicketResolutionReasonsControl.start();
      if (!resolutionSubReasons?.length) getTicketResolutionSubReasonsControl.start();

      if (data?.ticket?.product) {
        setSelectedProduct(data.ticket.product);
        setContractOptions(getContractSelectOptions(data.ticket.product));
      }

      if (data?.ticket?.journeyId) {
        setSelectedJourney(data.ticket.journeyId);
      }

      if (data?.ticket?.ticketStatusId) {
        setSelectedStatus(data.ticket.ticketStatusId);
      }

      if (data?.ticket?.justificationResolutionId) {
        setSelectedJustification(data.ticket.justificationResolutionId);
      }

      if (data?.ticket?.contracts) {
        setSelectedContracts(
          typeof data.ticket.contracts === 'object'
            ? data.ticket.contracts.map((contractDTO) => `${contractDTO.id}`)
            : data.ticket.contracts,
        );
      }

      setConfig('ticket-modal', {
        title: isUpdating ? 'Atualizar seu ticket' : title,
        backButton: false,
      });
    }, [isUpdating]);

    return (
      <FormWrapper data-testid="ticket-form-wrapper">
        {isUpdating && (
          <Alert
            label="Você está editando um ticket aberto; apenas times autorizados podem fazer isso."
            status="warning"
          />
        )}
        <Form ref={form} name="reset-form" onSubmit={handleSubmit} inputs={inputs}>
          <CustomButton rounded fullWidth type="submit" data-testid="ticket-form-button">
            Revisar a solicitação
          </CustomButton>
        </Form>
      </FormWrapper>
    );
  },
);
