export const OPERATION_STEPS_TYPES = {
  BPC_LOAS_ENDORSEMENT_PENDING: 'BPC_LOAS_ENDORSEMENT_PENDING',
  AUTOMATIC_CONFERENCE: 'Conferência automática',
  UNICO_CONFERENCE: 'Conferência - Único',
  POSSIBILITY_FRAUD: 'Possível fraude',
  AUTO_TYPING_ERROR: 'Erro na digitação automatica',
  UNDEFINED: 'Não definido',
  CONFERENCE_ADDITIONAL_WORKCODE_VALIDATION: 'Conferência Adicional do Código de Trabalho',
  CONFERENCE: 'Conferência',
  MINIMAL_CONFERENCE: 'Conferência mínima',
  SALARY_INCREASE_MINIMAL_CONFERENCE: 'SALARY_INCREASE_MINIMAL_CONFERENCE',
  CONFERENCE_PENDENCY: 'Pendência de Conferência',
  PRODUCT_SECURITY: 'Segurança do Produto',
  PENDENCY_MEU_TUDO: 'Pendência Meu TUDO',
  TYPING: 'Digitação',
  EXCEEDED_MARGIN: 'EXCEEDED_MARGIN',
  EXCEEDED_MARGIN_ANALISYS: 'Análise Margem Excedida',
  BANK_ANALYSIS: 'Análise Banco',
  CANCEL_REQUESTED_BY_CLIENT: 'Solicitação de cancelamento pelo cliente',
  DISAPPROVED: 'Reprovado (não utilizar)',
  ENDORSEMENT: 'Averbação',
  ENDORSEMENT_PENDING: 'Pendência Averbação',
  ENDORSEMENT_PENDING_TECHNOLOGY_REVIEW: 'Averbação pendente - Revisão pela T.I.',
  ENDORSED: 'Averbado',
  FORMALIZATION: 'Pendência Formalização',
  PENDING_CONSENT_SIGAC: 'Pendência Anuência Sigac',
  PENDING_AUTHORIZATION_SIGAC: 'Pendência Autorização Sigac',
  PENDENCY_BANK: 'Pendência Banco',
  PAID: 'Pago',
  REFUSED_BANK_ANALISYS: 'Reprovado Análise Banco',
  REFUSED_EXCEEDED_MARGIN: 'Reprovado Margem Excedida',
  REFUSED_ENDORSEMENT_FAIL: 'Reprovado Averbação Falhou',
  REFUSED_ENDORSEMENT_PENDING: 'Reprovado Averbação Pendente',
  REFUSED_CLIENT_PENDING_FORMALIZATION: 'Reprovado Pendente Cliente (Formalização)',
  REFUSED_CLIENT_PENDING_DOCUMENT: 'Reprovado Pendente Cliente (Documentação)',
  REFUSED_DOCUMENT_WITH_RESTRICTION: 'Reprovado CPF com Restrição',
  REFUSED_MAX_RISK: 'Reprovado Risco Máximo Atingido',
  REFUSED_INTERNAL_POLICY: 'Reprovado Política Interna',
  REFUSED_CONTRACT: 'Reprovado 2 (não utilizar)',
  REFUSED_NOT_LITERATE: 'Reprovado Não Alfabetizado',
  REFUSED_FRAUD: 'Port Reprovado Fraude',
  CANCELED_BY_CLIENT: 'Cancelado pelo cliente',
  CANCELED_MARGIN_INCREASE: 'Cancelado aumento de margem',
  UNICO_USED_OVER_35_PERCENT_CONFERENCE: 'UNICO_USED_OVER_35_PERCENT_CONFERENCE',
  UNICO_SALARY_INCREASE_CONFERENCE: 'Conferência aumento salarial',
  USED_OVER_35_PERCENT: '35% da Margem',
  SALARY_INCREASE: 'Aumento salarial',
  USED_OVER_35_PERCENT_CONFERED: '35% da Margem Conferido',
  SALARY_INCREASE_CONFERED: 'Aumento salarial conferido',
  USED_35_PERCENT_ENDORSEMENT: 'Averbação Margem de 35%',
  SALARY_INCREASE_ENDORSEMENT: 'Averbação aumento salarial',
  AUTOMATIC_CONFERENCE_USED_OVER_35_PERCENT: 'AUTOMATIC_CONFERENCE_USED_OVER_35_PERCENT',
  AUTOMATIC_CONFERENCE_SALARY_INCREASE: 'Conferência automática aumento salarial',
  REFUSED_INPROGRESS_PORTABILITY: 'Reprovado Portabilidade em Andamento',
  PAYMENT_RETURNED: 'Pagamento Devolvido',
  PAYMENT_RETURNING_BY_CLIENT: 'Devolução Pagamento por Desistência',
  UNDO_ENDORSEMENT: 'Desaverbação',
  UNDONE_ENDORSEMENT: 'Desaverbado',
  PAYMENT_RESUBMITTED: 'Pagamento Reapresentado',
  WAITING_UNLOCK_BENEFIT_DATE: 'Aguardando Desbloqueio do Benefício',
  ENDORSEMENT_PENDING_LOCKED_BENEFIT: 'Averbação pendente por benefício bloqueado',
  REFUSED_UNABLE_UNLOCK_BENEFIT: 'Reprovado por Bloqueio do Benefício',
  CONTRACT_SETTLEMENT_REQUIRED: 'Solicitação de quitação do empréstimo pelo cliente',
  CLIENT_INELIGIBLE_FOR_LOAN: 'Cliente não elegível para empréstimo',
  REFUSED_INSTALLMENTS_GREATER_THAN_BENEFIT_ENDED_DATE:
    'Reprovado - Prazo maior que a extinção do benefício',
  PAYMENT_WAITING_RESUBMIT: 'Aguardando confirmação de reapresentação de pagamento',
  REFUSED_AUTOMATIC: 'REFUSED_AUTOMATIC',
  WAITING_TO_SEND_TO_BANK: 'WAITING_TO_SEND_TO_BANK',
  CREATING_CCB: 'CREATING_CCB',
  COMPLIANCE: 'COMPLIANCE',
  COMPLIANCE_APPROVED: 'COMPLIANCE_APPROVED',
  REFUSED_PAB_OPERATIONAL_ISSUE: 'REFUSED_PAB_OPERATIONAL_ISSUE',
  CANCELED_MANUALLY: 'CANCELED_MANUALLY',
  PAB_ELEGIBILITY: 'PAB_ELEGIBILITY',
  REFUSED_VISUALLY_IMPAIRED: 'Reprovado Deficiente Visual',
  PAB_AUTOMATIC_CONFERENCE: 'PAB_AUTOMATIC_CONFERENCE',
  VALIDATE_AUTOMATIC_CONFERENCE: 'VALIDATE_AUTOMATIC_CONFERENCE',
  VALIDATE_PAB_AUTOMATIC_CONFERENCE: 'VALIDATE_PAB_AUTOMATIC_CONFERENCE',
  CONFERENCE_MAGNETIC_CARD: 'CONFERENCE_MAGNETIC_CARD',
  SALARY_INCREASE_MAGNETIC_CARD: 'SALARY_INCREASE_MAGNETIC_CARD',
  INVALID_HASH: 'INVALID_HASH',
  AUTOMATIC_PORT_CONFERENCE: 'Conferência automática - Portabilidade',
  FGTS_NEW_COMPLIANCE: 'FGTS - Analise de Compliance',
  FGTS_NEW_COMPLIANCE_APPROVED: 'FGTS - Compliance Aprovado',
  FGTS_MINIMAL_CONFERENCE: 'FGTS - Conferência minima',
  FGTS_NEW_VALIDATED_PRODUCT_SECURITY: 'FGTS - Validado Segurança do Produto',
  FGTS_NEW_CREATING_CCB: 'FGTS - Enviando CCB para QITECH',
  FGTS_NEW_PENDING_AUTORIZATION: 'FGTS - Pendência Autorização',
  FGTS_NEW_PAYMENT_ERROR: 'FGTS - Erro no Pagamento',
  FGTS_REFUSED_AUTOMATIC: 'FGTS - Reprovado Fraude Automaticamente',
  REFIN_CCB_NUMBER_NOT_FOUND: 'Refin. Saldo Devedor Não Encontrado',
  REFIN_PAYING_VALUE_FOR_DEPOSIT: 'Refin. Pagando Troco',
  REFIN_PAYING_OUTSTADING_BALANCE: 'Refin. Pagando Saldo Devedor',
  REFIN_OUTSTADING_BALANCE_PAID: 'Refin. Saldo Devedor Pago',
  REFIN_OUTDATED_OUTSTANDING_BALANCE: 'Refin. Saldo devedor expirado',
  PORT_UNDEFINED: 'Port Não definido',
  PORT_CONFERED_ONLINE_ENDORSEMENT: 'Port conferido averbação online',
  PORT_CONFERENCE: 'Port Conferência',
  PORT_PENDENCY: 'Port Pendência',
  PORT_PENDENCY_MEU_TUDO: 'Port Pendência Meu TUDO',
  PORT_TYPING: 'Port Digitação',
  PORT_AUTO_TYPING_ERROR: 'Port erro na digitação automática',
  PORT_PAID_WAITING_REFIN: 'Port paga aguardando refin',
  PORT_PAYING_OUTSTADING_BALANCE: 'Port pagando saldo devedor',
  PORT_BANK_ANALYSIS: 'Port Análise Banco',
  PORT_DISAPPROVED: 'Port Reprovado (não utilizar)',
  PORT_FORMALIZATION: 'Port Pendência Formalização',
  PORT_CIP: 'CIP',
  PORT_CLIENT_INELIGIBLE_FOR_LOAN: 'Port. Cliente não elegível',
  PORT_CANCEL_REQUESTED_BY_CLIENT: 'Port. Solicitação de cancelamento pelo cliente',
  PORT_RETENTION: 'CIP Retenção',
  PORT_LIQUIDATED: 'CIP Contrato Liquidado',
  PORT_DIVERGENT_CONTRACT: 'CIP Contrato Divergente',
  PORT_DIVERGING_BALANCE: 'CIP Saldo Divergente',
  PORT_DIVERGING_INSTALLMENT: 'CIP Parcela Divergente',
  PORT_FORMALIZATION_DIVERGING_BALANCE: 'CIP Saldo Divergente (Formalização)',
  PORT_RETYPING: 'Redigitação',
  PORT_PORTABILITY_SUCCESS: 'Portabilidade Sucesso',
  PORT_ENDORSEMENT: 'Port Averbação',
  PORT_ENDORSEMENT_PENDING: 'Port Pendência Averbação',
  PORT_ENDORSEMENT_PENDING_TECHNOLOGY_REVIEW: 'Port Pendente Revisão da Tecnologia',
  PORT_ENDORSED: 'Port Averbado',
  PORT_TYPING_PENDENCY: 'Port Digitação Pendência',
  PORT_RATE_MORE_THAN_ALLOWED: 'Port taxa maior do que 1,80',
  PORT_INTEGRATION_ERROR_FOR_RATE_MORE_THAN_ALLOWED: 'Port erro integração taxa maior que 1,80',
  PORT_PAID: 'Portabilidade Pago',
  PORT_MINIMAL_CONFERENCE: 'Port - Conferência minima',
  REF_PORT_FORMALIZATION: 'Refin Port Pendência Formalização',
  REF_PORT_PENDENCY: 'Refin Port Pendência',
  REF_PORT_ENDORSEMENT: 'Refin Port Averbação',
  REF_PORT_ENDORSEMENT_PENDING: 'Refin Port Pendência Averbação',
  REF_PORT_ENDORSED: 'Refin Port Averbado',
  REF_PORT_PAID: 'Refin Port Pago',
  REF_PORT_WAITING_UNLOCK_BENEFIT_DATE: 'Refin Port Aguardando Desbloqueio do Benefício',
  PORT_REFUSED_LIQUIDATED_CONTRACT: 'Port Reprovado Contrato Liquidado',
  PORT_REFUSED_DIFF_INSTALLMENT: 'Port Reprovado parcela divergente',
  PORT_REFUSED_DIFF_CONTRACT_NUMBER: 'Port Reprovado Número de Contrato Divergente',
  PORT_REFUSED_DIFF_OUTSTANDING_BALANCE: 'Port Reprovado Saldo Divergente',
  PORT_REFUSED_FRUSTRATED_PORTABILITY: 'Port Reprovado Portabilidade Frustrada',
  PORT_REFUSED_RETENTION: 'Port Reprovado Retenção',
  PORT_REFUSED_FINISHED_CONTRACT: 'Port Reprovado Contrato Liquidado',
  PORT_REFUSED_CLIENT_PENDING_FORMALIZATION: 'Port Reprovado Pendente Cliente (Formalização)',
  PORT_REFUSED_CLIENT_PENDING_DOCUMENT: 'Port Reprovado Pendente Cliente (Documentação)',
  PORT_REFUSED_ENDORSEMENT_PENDING: 'Port Reprovado Averbação Pendente',
  PORT_REFUSED_DOCUMENT_WITH_RESTRICTION: 'Port Reprovado CPF com Restrição',
  PORT_REFUSED_MAX_RISK: 'Port Reprovado Risco Máximo Atingido',
  PORT_REFUSED_INTERNAL_POLICY: 'Port Reprovado Política Interna',
  PORT_REFUSED_CONTRACT: 'Port Contrato reprovado (não utilizar)',
  PORT_REFUSED_VALUE_UNDER_MIN: 'Port Reprovado Valor inferior ao permitido',
  PORT_REFUSED_NOT_LITERATE: 'Port Reprovado Não Alfabetizado',
  PORT_REFUSED_FRAUD: 'Port Reprovado Fraude',
  PORT_CANCELED_BY_CLIENT: 'Port Cancelado pelo cliente',
  PORT_RETYPING_FORMALIZATION: 'Redigitação Formalização',
  PORT_RETYPING_RETENTION: 'Redigitação Retenção',
  PORT_RETYPING_DIVERGENT_CONTRACT: 'Redigitação Contrato Divergente',
  PORT_RETYPING_DIVERGING_BALANCE: 'Redigitação saldo divergente',
  PORT_REFUSED_NOT_PROFITABLE: 'Port Reprovado Não Rentável',
  PORT_PENDING_CONSENT_SIGAC: 'Port Pendência Anuência Sigac',
  PORT_PENDING_AUTHORIZATION_SIGAC: 'Port Pendência Autorização Sigac',
  PORT_CONFERENCE_ADDITIONAL_WORKCODE_VALIDATION:
    'Port Conferência Adicional do Código de Trabalho',
  PORT_CONFERENCE_PENDENCY: 'Port Pendência de Conferência',
  PORT_RETENTION_ANALYSIS: 'Port Análise de Retenção',
  PORT_PRODUCT_SECURITY: 'Port Segurança do Produto',
  PORT_EXCEEDED_MARGIN_ANALISYS: 'Port Análise Margem Excedida',
  PORT_REFUSED_INPROGRESS_PORTABILITY: 'Port Reprovado Contrato em Andamento',
  PORT_CIP_REJECTED: 'Port CIP Rejeitada',
  PORT_CIP_INPROGRESS_PORTABILITY: 'CIP Contrato em Andamento',
  PORT_PAYING_OUTSTANDING_BALANCE: 'Port Pagamento Saldo devedor',
  PORT_LOST_OUTSTANDING_BALANCE: 'Port Saldo Devedor Perdido',
  REF_PORT_PAYMENT_RETURNED: 'Refin Port Pagamento Devolvido',
  REF_PORT_PAYMENT_RESUBMITTED: 'Refin Port Pagamento Reapresentado',
  REF_PORT_PAYMENT_RETURNING_BY_CLIENT: 'Refin Port Devolução Pagamento por Desistência',
  REF_PORT_UNDO_ENDORSEMENT: 'Refin Port Desaverbação',
  REF_PORT_UNDONE_ENDORSEMENT: 'Refin Port Desaverbado',
  REF_PORT_ATTEMPTING_ENDORSEMENT: 'Refin Port Tentativa Averbação',
  REF_PORT_HALF_ENDORSED: 'Refin Port Parcialmente Averbada',
  REF_PORT_HALF_PAID: 'Refin Port Parcialmente Paga',
  REF_PORT_PAID_DIVERGENT_VALUE: 'Refin Port Pago Saldo Divergente',
  PORT_USED_OVER_35_PERCENT: 'Port 35% da Margem',
  PORT_USED_OVER_35_PERCENT_CONFERED: 'Port 35% da Margem Conferido',
  PORT_POSSIBILITY_FRAUD: 'Port possível fraude',
  FGTS_NEW_AUTOMATIC_CONFERENCE: 'FGTS - Conferência automática',
  FGTS_NEW_POSSIBILITY_FRAUD: 'FGTS - Conferência automática',
  FGTS_NEW_AUTO_TYPING_ERROR: 'FGTS - Erro na digitação automatica',
  FGTS_NEW_UNDEFINED: 'FGTS - Nao definido',
  FGTS_NEW_CONFERENCE_ADDITIONAL_WORKCODE_VALIDATION: 'FGTS - Conferência - Perícia',
  FGTS_NEW_CONFERENCE: 'FGTS - Conferência',
  FGTS_NEW_CONFERENCE_PENDENCY: 'FGTS - Conferência da pendência resolvida',
  FGTS_NEW_PRODUCT_SECURITY: 'FGTS - Segurança do Produto',
  FGTS_NEW_PENDENCY_MEU_TUDO: 'FGTS - Pendência Meu TUDO',
  FGTS_NEW_TYPING: 'FGTS - Digitação',
  FGTS_NEW_EXCEEDED_MARGIN_ANALISYS: 'FGTS - Análise Margem Excedida',
  FGTS_NEW_BANK_ANALYSIS: 'FGTS - Análise Banco',
  FGTS_NEW_CANCEL_REQUESTED_BY_CLIENT: 'FGTS - Cancelamento solicitado pelo cliente',
  FGTS_NEW_DISAPPROVED: 'FGTS - Reprovado (não utilizar)',
  FGTS_NEW_ENDORSEMENT: 'FGTS - Averbação',
  FGTS_NEW_ENDORSEMENT_PENDING: 'FGTS - Pendencia Averbação',
  FGTS_NEW_ENDORSEMENT_PENDING_TECHNOLOGY_REVIEW: 'FGTS - Pendencia Averbação',
  FGTS_NEW_ENDORSED: 'FGTS - Averbado',
  FGTS_NEW_FORMALIZATION: 'FGTS - Pendencia Formalização',
  FGTS_NEW_PENDING_CONSENT_SIGAC: 'FGTS - Pendência Anuência Sigac',
  FGTS_NEW_PENDING_AUTHORIZATION_SIGAC: 'FGTS - Pendência Autorização Sigac',
  FGTS_NEW_PENDENCY_BANK: 'FGTS - Pendencia Banco',
  FGTS_NEW_PAID: 'FGTS - Pago',
  FGTS_NEW_REFUSED_BANK_ANALISYS: 'FGTS - Reprovado Análise Banco',
  FGTS_NEW_REFUSED_EXCEEDED_MARGIN: 'FGTS - Reprovado Margem Excedida',
  FGTS_NEW_REFUSED_ENDORSEMENT_FAIL: 'FGTS - Reprovado Averbação Falhou',
  FGTS_NEW_REFUSED_ENDORSEMENT_PENDING: 'FGTS - Reprovado Averbação Pendente',
  FGTS_NEW_REFUSED_CLIENT_PENDING_FORMALIZATION: 'FGTS - Reprovado Pendente Cliente (Formalização)',
  FGTS_NEW_REFUSED_CLIENT_PENDING_DOCUMENT: 'FGTS - Reprovado Pendente Cliente (Documentação)',
  FGTS_NEW_REFUSED_DOCUMENT_WITH_RESTRICTION: 'FGTS - Reprovado CPF com Restrição',
  FGTS_NEW_REFUSED_MAX_RISK: 'FGTS - Reprovado Risco Máximo Atingido',
  FGTS_NEW_REFUSED_INTERNAL_POLICY: 'FGTS - Reprovado Política Interna',
  FGTS_NEW_REFUSED_CONTRACT: 'FGTS - Reprovado 2 (não utilizar)',
  FGTS_NEW_REFUSED_NOT_LITERATE: 'FGTS - Reprovado Não Alfabetizado',
  FGTS_NEW_REFUSED_FRAUD: 'FGTS - Reprovado Fraude',
  FGTS_NEW_CANCELED_BY_CLIENT: 'FGTS - Cancelado pelo cliente',
  FGTS_NEW_CANCELED_MARGIN_INCREASE: 'FGTS - Cancelado aumento de margem',
  FGTS_NEW_USED_OVER_35_PERCENT: 'FGTS - 35 por cento da margem',
  FGTS_NEW_USED_OVER_35_PERCENT_CONFERED: 'FGTS - 35 por cento da margem conferido',
  FGTS_NEW_USED_35_PERCENT_ENDORSEMENT: 'FGTS - Averbação margem 35%',
  FGTS_NEW_REFUSED_INPROGRESS_PORTABILITY: 'FGTS - Reprovado Port em andamento',
  FGTS_NEW_PAYMENT_RETURNED: 'FGTS - Pagamento devolvido',
  FGTS_NEW_PAYMENT_RETURNING_BY_CLIENT: 'FGTS - Pagamento em devoluçao pelo cliente',
  FGTS_NEW_UNDO_ENDORSEMENT: 'FGTS - Desaverbação',
  FGTS_NEW_UNDONE_ENDORSEMENT: 'FGTS - Desaverbado',
  FGTS_NEW_PAYMENT_RESUBMITTED: 'FGTS - Pagamento reapresentado',
  FGTS_NEW_WAITING_UNLOCK_BENEFIT_DATE: 'FGTS - Aguardando data de desbloqueio',
  FGTS_NEW_REFUSED_UNABLE_UNLOCK_BENEFIT: 'FGTS - Reprovado Desbloqueio não Disponível',
  FGTS_NEW_CONTRACT_SETTLEMENT_REQUIRED:
    'FGTS - Solicitação de liquidação do contrato pelo cliente',
  FGTS_NEW_CLIENT_INELIGIBLE_FOR_LOAN: 'FGTS - Cliente inelegivel para emprestimos',
  FGTS_NEW_REFUSED_VISUALLY_IMPAIRED: 'FGTS - Reprovado Deficiente Visual',
  FGTS_OPERATION_CANCEL: 'FGTS - Aguardando confirmação da averbação',
  PORT_REFUSED_VISUALLY_IMPAIRED: 'Port - Reprovado Deficiente Visual',
  FGTS_CONFERENCE_ON_HOLD: 'FGTS - Conferência em espera',
  PORT_CONFERENCE_ON_HOLD: 'Port Conferência em espera',
  CONFERENCE_ON_HOLD: 'Conferência em espera',
  REFIN_CONFERENCE_ON_HOLD: 'Conferência em espera',
  FUTURE_MARGIN_CONFERENCE_ON_HOLD: 'Conferência em espera',
};

export const CONFERENCE_BY_PERSON_OPERATION_STEPS = [
  'PORT_CONFERENCE',
  'VALIDATE_AUTOMATIC_PORT_CONFERENCE',
  'PORT_CONFERENCE_ADDITIONAL_WORKCODE_VALIDATION',
  'PORT_CONFERENCE_PENDENCY',
  'PORT_TYPING',
  'PORT_PRODUCT_SECURITY',
  'PORT_PENDENCY_MEU_TUDO',
];

function OperationStepType(step: any) {
  return OPERATION_STEPS_TYPES[step] || step || null;
}

export default OperationStepType;
