import styled from 'styled-components';
import { Typography } from '~/ui/components';

export const HeaderContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Title = styled(Typography).attrs({
  type: 'headingSmall',
  color: 'brand.primary',
})``;

export const Age = styled(Typography).attrs({
  type: 'bodySmall',
  color: 'element.secondary',
})``;
