import RouterManager from '~/common/utils/routerManager';
import ContractDetails from '~/screens/ContractDetails';
import FileHistory from '~/screens/FileHistory';
import FillContract from '~/screens/FillContract';
import PersonContractExtract from '~/screens/PersonContractExtract';
import OfferPersonRegistry from '~/screens/OfferPersonRegistry';
import Logs from '~/screens/Logs';
import DistributedContracts from '~/screens/DistributedContracts';
import Versions from '~/screens/Versions';
import ContractsClix from '~/screens/Clix/Contracts/Contracts';
import ContractClixDetails from '~/screens/Clix/ContractDetails/ContractDetails';
import Person from '~/screens/Person/PersonDetails';
import Users from '~/screens/Users/Users';
import Reports from '~/screens/Reports/Reports';
import Persons from '~/screens/Persons/Persons';
import Contracts from '~/screens/Contracts/Contracts';
import Tickets from '~/screens/Tickets/Tickets';
import Card from '~/screens/Card/CardDetails';
import TransactionHistory from '~/screens/Card/TransactionHistory/TransactionHistory';
import InvoiceHistory from '~/screens/Card/InvoiceHistory/InvoiceHistory';
import Contract from '~/screens/Contract/Contract';

const routes = [
  FillContract,
  FileHistory,
  Contracts,
  ContractDetails,
  DistributedContracts,
  PersonContractExtract,
  Logs,
  OfferPersonRegistry,
  Versions,
  ContractsClix,
  ContractClixDetails,
  Person,
  Persons,
  Users,
  Reports,
  Tickets,
  Card,
  TransactionHistory,
  InvoiceHistory,
  Contract,
];

const routerManager = new RouterManager(...routes);

const routesNames = {
  ...routerManager.getRoutesNames(),
};

export default { routesNames, manager: routerManager };
