import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { themeColor } from '~/common/utils/theme';

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: #fff;
  border-radius: 16px;
  padding: 24px 32px;
`;

export const DetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 16px;

  & > div {
    padding: 24px 32px;
  }

  nav {
    padding: 0 32px;
  }
`;

export const StyledLink = styled(Link)`
  color: ${themeColor('element.secondary')};
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-decoration-line: underline;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
