import { put, takeLatest } from 'redux-saga/effects';

import takeRequest from '~/store/takeRequest';
import { reloadContract } from '~/store/contracts/sagas';

import {
  createNewPendency,
  getAllPendencies,
  getNewPendencies,
  getPendencies,
  getPendencyTypes,
  getPendingReasons,
  requestPendingData,
  solveAddress,
  solveBankAccount,
  solveBiometry,
  solveDocument,
  solvePaycheck,
  solveProofOfAddress,
} from './actions';

function* loadPendencies({ meta: { payload } }: any) {
  yield put(getPendencies.start(payload));
}

function* loadNewPendencies({ meta: { payload } }: any) {
  const { externalId, product } = payload;
  yield put(getNewPendencies.start({ externalId, product }));
}

export function* pendenciesSaga() {
  yield takeRequest(getPendencies);
  yield takeRequest(getAllPendencies);
  yield takeRequest(getPendingReasons);
  yield takeRequest(requestPendingData);
  yield takeRequest(solveBiometry);
  yield takeRequest(solveDocument);
  yield takeRequest(solvePaycheck);
  yield takeRequest(solveBankAccount);
  yield takeRequest(solveProofOfAddress);
  yield takeRequest(solveAddress);
  yield takeRequest(getPendencyTypes);
  yield takeRequest(getNewPendencies);
  yield takeRequest(createNewPendency);

  yield takeLatest(requestPendingData.success.toString(), reloadContract);
  yield takeLatest(solveBiometry.success.toString(), loadPendencies);
  yield takeLatest(solveDocument.success.toString(), loadPendencies);
  yield takeLatest(solvePaycheck.success.toString(), loadPendencies);
  yield takeLatest(solveBankAccount.success.toString(), loadPendencies);
  yield takeLatest(solveProofOfAddress.success.toString(), loadPendencies);
  yield takeLatest(solveAddress.success.toString(), loadPendencies);
  yield takeLatest(createNewPendency.success.toString(), loadNewPendencies);
}
