import React from 'react';

import { Button, Typography } from '~/ui/components';
import LogoImg from '~/ui/assets/imgs/Tponto.png';
import { Money } from '~/common';
import {
  Divider,
  Flex,
  Content,
  Wrapper,
  InfoLabel,
  InfoValue,
  Description,
  Value,
  Section,
} from './TransactionDetail.styles';

type InfoType = {
  label: string;
  value: string;
  color?: string;
};

function TransactionDetail() {
  const Info = React.memo(({ label, value, color }: InfoType) => (
    <Flex>
      <InfoLabel>{label}</InfoLabel>
      <InfoValue color={color}>{value ?? '-'}</InfoValue>
    </Flex>
  ));

  return (
    <Wrapper>
      <Flex alignCenter>
        <img src={LogoImg} alt="Logo meutudo" />
        <Typography type="bodyLarge" weight={700} center>
          Transação realizada com sucesso!
        </Typography>
        <Section>
          <Value center>{Money(300)}</Value>
          <Description center>20/05/2022 • 14:41:35</Description>
        </Section>
      </Flex>

      <Typography type="bodyLarge" weight={700}>
        Quem solicitou o Pix
      </Typography>
      <Content>
        <Info label="Nome" value="Giulliana Maria Dantas Murinelli" />
        <Divider />
        <Info label="CPF" value="066.875.533-22" />
      </Content>

      <Typography type="bodyLarge" weight={700}>
        Quem enviou
      </Typography>
      <Content>
        <Info label="Pagador" value="Giulliana Maria Dantas Murinelli" />
        <Divider />
        <Info label="CNPJ" value="066.875.533-22" />
      </Content>

      <Typography type="bodyLarge" weight={700}>
        Quem recebeu
      </Typography>
      <Content border>
        <Info label="Nome" value="Giulliana Maria Dantas Murinelli" />
        <Divider />
        <Info label="CPF" value="066.875.533-22" />
        <Divider />
        <Info label="Chave Pix" value="0009" />
        <Divider />
        <Info label="Instituição bancária recebedora" value="0009" />
      </Content>
      <Typography type="bodyLarge" weight={700}>
        Dados da transação
      </Typography>
      <Content>
        <Info label="ID da transação Pix" value="Giulliana Maria Dantas Murinelli" />
        <Divider />
        <Info label="Instituição bancária pagadora" value="066.875.533-22" />
      </Content>
    </Wrapper>
  );
}

export default TransactionDetail;
