import styled, { css } from 'styled-components';
import { Timeline } from 'antd';

import { themeToggleColor } from '~/common/utils/theme';
import { Popover, Typography } from '~/ui/components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-block: 24px;
  gap: 24px;
`;

export const Flex = styled.div<{ column?: boolean }>`
  display: flex;
  gap: 4px;

  ${({ column }) =>
    column &&
    css`
      flex-direction: column;
    `}
`;

export const Content = styled.div<{ border?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${({ border }) =>
    border &&
    css`
      border-left: 5px solid ${({ theme }) => theme.colors.element.disabled};
      padding-left: 16px;
    `}
`;

export const Divider = styled.div<{ center?: boolean }>`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider.primary};
`;

export const StyledPopover = styled(Popover)`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.element.primary};
  display: flex;
  gap: 7px;
`;

export const InfoValue = styled(Typography).attrs(({ theme, color }) => ({
  type: 'caption',
  element: 'span',
  weight: 600,
  color: color || themeToggleColor(theme, 'neutral.primary'),
}))``;

export const InfoLabel = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'label',
  weight: 400,
  color: theme.colors.neutral.primary,
}))``;

export const Description = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'label',
  weight: 400,
  color: theme.colors.element.secondary,
}))``;

export const StyledTimeline = styled(Timeline)`
  padding-top: 16px;

  li > div {
    min-height: 0px !important;
  }
`;
