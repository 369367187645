import React from 'react';

import { SideActions } from '~/components';
import { DisapproveCustomer } from '~/components/OtherOptions/components/DisapproveCustomer';
import { AccessByCostumerButton } from '~/components/SideActions/PersonActions/components';

type SideBarActionsProps = {
  loading?: boolean;
  className?: string;
};

function SideBarActions({ loading, className }: SideBarActionsProps) {
  const actions = [
    { component: <AccessByCostumerButton /> },
    { component: <DisapproveCustomer /> },
  ];

  return <SideActions className={className} loading={loading} actions={actions} />;
}

export default SideBarActions;
