import React, { useCallback, useEffect, useState } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import moment from 'moment';

import { getAgreementPasswordHistory } from '~/store/people/actions';
import { Loading } from '~/ui/assets/icons';
import { Alert, Tag } from '~/ui/components';

import { Content } from '../AgreementPasswordActions.styled';

import {
  HistoryRow,
  RowLabel,
  HistoryInfo,
  LoadingWrapper,
  LoadMoreButton,
  HistoryList,
  Divider,
} from './AgreementPasswordHistoryModal.styled';

export type AgreementPasswordHistoryModalProps = {
  id: string;
};

export function AgreementPasswordHistoryModal({ id }: AgreementPasswordHistoryModalProps) {
  const getAgreementPasswordHistoryControl = useAwaitControl(getAgreementPasswordHistory);
  const [history, setHistory] = useState([]);
  const [pagination, setPagination] = useState({ last: false, totalPages: 0 });
  const [currentPage, setCurrentPage] = useState(0);

  const historyLoading = getAgreementPasswordHistoryControl.isRunning();
  const historyResult = getAgreementPasswordHistoryControl.result();

  const getRawData = useCallback<any>((data: any) => {
    try {
      return JSON.parse(data);
    } catch (e) {
      return '-';
    }
  }, []);

  const getDescription = useCallback((data: any) => getRawData(data)?.description, []);
  const isSuccess = useCallback((data: any) => getRawData(data)?.success, []);
  const hasStatusInfo = useCallback((data: any) => getRawData(data)?.success !== undefined, []);

  const getHistory = useCallback(
    (page) => {
      getAgreementPasswordHistoryControl.start({ personId: id, page });
    },
    [id],
  );

  const handleLoadMore = useCallback(() => {
    setCurrentPage(currentPage + 1);
    getHistory(currentPage + 1);
  }, [currentPage]);

  useEffect(() => {
    if (historyResult?.content?.length) {
      const { last, totalPages, content } = historyResult;
      setPagination({ last, totalPages });
      setHistory((current) => [...current, ...content]);
    }
  }, [historyResult]);

  useEffect(() => {
    getHistory(currentPage);

    return () => {
      getAgreementPasswordHistoryControl.clear();
    };
  }, []);

  if (!history?.length && historyLoading) {
    return (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    );
  }

  return (
    <Content loading={historyLoading}>
      {!!history?.length && (
        <Alert
          status="warning"
          label={
            <>
              Histórico referente a exclusão da senha <b>Meu INSS</b>
            </>
          }
        />
      )}

      {!history?.length && <HistoryInfo>Não há nenhum registro no histórico</HistoryInfo>}

      {!!history && (
        <HistoryList>
          {history?.map((item) => (
            <>
              <HistoryRow key={item.id}>
                <RowLabel>Dados do agente</RowLabel>
                <HistoryInfo>
                  {item.user}, {moment(item.dateTime).format('DD/MM/YYYY [às] HH:mm')}
                </HistoryInfo>
                <HistoryInfo weight={700}>{getDescription(item.data)}</HistoryInfo>

                {hasStatusInfo(item.data) && (
                  <Tag small rounded color={isSuccess(item.data) ? 'positive' : 'negative'}>
                    {isSuccess(item.data) ? 'Sucesso' : 'Tentativa falha'}
                  </Tag>
                )}
              </HistoryRow>
              <Divider />
            </>
          ))}
        </HistoryList>
      )}

      {!!history?.length && !pagination?.last && (
        <LoadMoreButton onClick={handleLoadMore} loading={historyLoading}>
          Carregar mais
        </LoadMoreButton>
      )}
    </Content>
  );
}
