import styled, { css } from 'styled-components';
import { Tabs as AntTabs } from 'antd';
import { Button } from '~/ui/components';
import { themeColor } from '~/common/utils/theme';

interface CollapseProps {
  collapsed?: boolean;
}

interface LoadingProps {
  loading?: boolean;
}

export const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
`;

export const TabsBody = styled<CollapseProps & LoadingProps>(AntTabs)`
  width: 100%;
  background: ${themeColor('background.primary', { dark: 'background.secondary' })};
  border-radius: 8px;
  padding: 0 24px 24px 16px;

  .ant-tabs-nav {
    & > div {
      display: flex;
    }

    .ant-tabs-tab {
      padding: 12px 32px;
      height: 80px;
      color: ${themeColor('element.secondary', { dark: 'element.disabled' })};
      font-family: ${({ theme }) => theme.typography.fonts.libreFranklin};
      font-weight: 400;
      font-size: 14px;
      margin: 0;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      &.ant-tabs-tab-active {
        font-weight: 600;
        color: ${themeColor('element.primary', { dark: 'element.secondary' })};
      }

      &.ant-tabs-tab-disabled {
        pointer-events: none;
      }
    }
  }

  .ant-tabs-bar {
    border-color: ${themeColor('neutral.secondaryAlt', { dark: 'element.disabled' })};
  }

  .ant-tabs-top-bar {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin: 0;
  }

  .ant-tabs-extra-content {
    margin-left: 30px;
  }

  .ant-tabs-nav-container {
    flex: 1;
  }

  .ant-tabs-ink-bar {
    background-color: ${themeColor('element.primary', {
      dark: 'brand.primaryAlt',
    })};
  }

  .ant-tabs-tab-disabled {
    opacity: 0.5;
  }

  .extra-actions {
    margin-left: 42px;
  }

  .ant-tabs-top {
    padding: 0 24px 24px 16px;
  }

  ${({ collapsed }) =>
    collapsed &&
    css`
      padding: 0 24px 0 16px;

      .ant-tabs-ink-bar {
        background-color: transparent;
      }

      .ant-tabs-top-bar {
        border: none;
      }

      .ant-tabs-nav {
        .ant-tabs-tab {
          pointer-events: none;

          &.ant-tabs-tab-active {
            color: ${({ theme }) => theme.colors.gray['500']};
            font-weight: 400;
          }
        }
      }
    `}

  ${({ loading }) =>
    loading &&
    css`
      opacity: 0.8;
      pointer-events: none;
    `}
  
  //tab type: card/
  &.ant-tabs-card {
    padding: 0;
    background: transparent;
    border-radius: 0;

    .ant-tabs-bar {
      border: none;
    }

    .ant-tabs-extra-content {
      display: none;
    }

    .ant-tabs-nav-container {
      height: 60px !important;
    }

    .ant-tabs-ink-bar {
      background-color: transparent;
    }

    .ant-tabs-nav-wrap {
      .ant-tabs-tab {
        margin-right: 24px;
        border: none;
        height: 60px;
        background: ${({ theme }) => theme.colors.background.primary};
        border-bottom: 1px dashed ${({ theme }) => theme.colors.divider.primary};
        padding: 12px 32px;
        width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
        color: ${({ theme }) => theme.colors.element.secondary};

        &:last-of-type {
          margin-right: 0;
        }

        &:hover {
          color: ${({ theme }) => theme.colors.brand.primary};
        }

        &.ant-tabs-tab-active {
          background: ${({ theme }) => theme.colors.brand.secondary};
          color: ${({ theme }) => theme.colors.brand.primary};
          border-bottom: 1px solid ${({ theme }) => theme.colors.divider.brand};

          svg {
            color: ${({ theme }) => theme.colors.brand.primary};
          }
        }
      }
    }

    .ant-tabs-nav {
      width: 100%;

      & > {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &.internal-tabs {
    padding: 0;
    background: transparent;
    border-radius: 0;

    .ant-tabs-nav-wrap {
      .ant-tabs-tab {
        width: 100%;
        max-width: 340px;
        margin-right: 24px;
        border: none;
        height: 60px;
        background: ${({ theme }) => theme.colors.background.primary};
        border-bottom: 1px dashed ${({ theme }) => theme.colors.divider.primary};
        padding: 12px 32px;
        color: ${({ theme }) => theme.colors.element.secondary};

        &:last-of-type {
          margin-right: 0;
        }

        &:hover {
          color: ${({ theme }) => theme.colors.brand.primary};
        }

        &.ant-tabs-tab-active {
          background: ${({ theme }) => theme.colors.background.primary} !important;
          color: ${({ theme }) => theme.colors.element.primary} !important;
          border-bottom: none !important;
          border-bottom: 1px solid ${({ theme }) => theme.colors.divider.secondary} !important;
        }
      }
    }

    .ant-tabs-nav {
      width: 100%;

      .ant-tabs-ink-bar {
        background-color: transparent;
      }

      & > div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

type TabPaneProps = {
  noPadding?: boolean;
};

export const TabPane = styled(AntTabs.TabPane)<TabPaneProps>`
  width: 100%;

  .ant-collapse-content-box {
    background-color: ${themeColor('background.primary', {
      dark: 'background.secondary',
    })} !important;
  }

  .ant-collapse-header {
    display: none;
  }

  .ant-collapse-borderless {
    background-color: ${themeColor('background.primary', {
      dark: 'background.secondary',
    })};
  }

  .ant-collapse-item {
    border: none;
  }

  .ant-collapse-content-box {
    padding: ${({ noPadding }) => (noPadding ? 0 : '26px 28px 30px')} !important;
    background: white;
    overflow-x: auto;
  }
`;

export const CollapseButton = styled<CollapseProps>(Button).attrs(({ collapsed }) => ({
  size: 'sm',
  iconOnly: true,
  variant: 'text',
  icon: 'CaretUp',
  title: collapsed ? 'Expandir' : 'Recolher',
  customColor: '#1a1a1a',
}))`
  max-width: 32px;
  min-width: 32px;
  max-height: 32px;
  min-height: 32px;
  background: white;
  border-radius: 8px;
  position: absolute;
  left: -18px;
  top: 24px;
  z-index: 99;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background-color: ${themeColor('brand.overPrimary', { dark: 'brand.primaryAlt' })};

  svg {
    color: ${themeColor('brand.primary', { dark: 'background.primary' })};
    width: 12px;
    height: 12px;
    transition: transform 0.24s, -webkit-transform 0.24s;
    transform: rotate(180deg);
  }

  ${({ collapsed }) =>
    collapsed &&
    css`
      svg {
        transform: rotate(90deg);
      }
    `}

  &:disabled {
    opacity: 1;

    svg {
      opacity: 0.4;
    }
  }
`;

export const Flex = styled.div`
  display: flex;
  gap: 4px;
`;
