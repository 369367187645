import React, { useEffect, useMemo, useRef, useState } from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';

import { createNewPendency, getPendencyTypes } from '~/store/pendencies/actions';
import { getLoggedUser } from '~/store/user/actions';
import useAsyncActionStatus from '~/hooks/useAsyncActionStatus';
import { useModal } from '~/hooks/useModal';
import { Button, Flex, Form, Select } from '~/ui/components';
import { Textarea } from '~/components';
import { getDetails } from '~/store/contracts/actions';

export function CreatePendencyModal() {
  const form = useRef<WrappedFormUtils>(null);
  const { closeAll, setLoading } = useModal();

  const [selectedType, setSelectedType] = useState(null);
  const [selectedReason, setSelectedReason] = useState(null);

  const detailsControl = useAsyncActionStatus(getDetails);

  const { result: pendencyTypes, ...pendencyTypesControl } = useAsyncActionStatus(getPendencyTypes);
  const { result: loggedUser } = useAsyncActionStatus(getLoggedUser);
  const createPendencyControl = useAsyncActionStatus(createNewPendency);

  const handleChangeType = (value) => {
    form.current?.setFields({ reason: undefined, detail: undefined });
    setSelectedReason(null);
    setSelectedType(value?.[0]);
  };

  const handleChangeReason = (value) => {
    form.current?.setFields({ detail: undefined });
    setSelectedReason(value?.[0]);
  };

  const handleSubmit = (values) => {
    const { contract } = detailsControl.result;

    const payload = {
      externalId: contract?.externalId,
      product: contract?.product,
      externalUserId: loggedUser?.id,
      ...values,
    };

    createPendencyControl.start(payload);
  };

  const reasons = useMemo(() => {
    if (!pendencyTypes) return [];
    return pendencyTypes.find((type) => type.typeKey === selectedType)?.reasons || [];
  }, [pendencyTypes, selectedType]);

  const details = useMemo(() => {
    if (!reasons) return [];
    return reasons.find((reason) => reason.reasonKey === selectedReason)?.details || [];
  }, [reasons, selectedReason]);

  const inputs = useMemo(() => {
    const inputs = [
      {
        id: 'type',
        label: 'Tipo da pendência',
        options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
        input: (
          <Select
            options={pendencyTypes}
            fieldNames={{ label: 'type', value: 'typeKey' }}
            onChange={handleChangeType}
          />
        ),
      },
      {
        id: 'reason',
        label: 'Motivo',
        options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
        input: (
          <Select
            disabled={!reasons}
            options={reasons}
            onChange={handleChangeReason}
            fieldNames={{ label: 'reason', value: 'reasonKey' }}
          />
        ),
      },
    ];

    if (details?.length) {
      inputs.push({
        id: 'detail',
        label: 'Descrição do motivo',
        input: <Select options={details} fieldNames={{ label: 'detail', value: 'detailKey' }} />,
        options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
      });
    }

    if (selectedReason === 'OTHER') {
      inputs.push({
        id: 'comment',
        label: 'Descrição do motivo',
        input: <Textarea placeholder="Descrição do motivo" />,
        options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
      });
    }
    return inputs;
  }, [pendencyTypes, reasons, details]);

  useEffect(() => {
    setLoading('create-pendency-modal', !!createPendencyControl.loading);
  }, [createPendencyControl.loading]);

  useEffect(() => {
    if (createPendencyControl.success) {
      closeAll();
    }
  }, [createPendencyControl.success]);

  useEffect(() => {
    pendencyTypesControl.start();

    return () => {
      createPendencyControl.clear();
    };
  }, []);

  return (
    <Form ref={form} inputs={inputs} onSubmit={handleSubmit}>
      <Flex gap={8}>
        <Button
          rounded
          fullWidth
          type="button"
          color="black"
          title="Cancelar"
          variant="outline"
          onClick={closeAll}
        />
        <Button type="submit" title="Solicitar" fullWidth rounded size="md" color="black" />
      </Flex>
    </Form>
  );
}
