import styled, { css } from 'styled-components';
import { themeColor } from '~/common/utils/theme';

export const Wrapper = styled.div`
  align-items: flex-start;
  width: 100%;
  min-width: 720px;
`;

type SectionType = {
  border?: boolean;
};

export const Section = styled.div<SectionType>`
  display: flex;
  flex-direction: column;
  padding: 24px;

  ${({ border }) =>
    border &&
    css`
      border-bottom: 2px dotted ${themeColor('divider.primary')};
    `}
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 12px;
`;

export const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: baseline;
  justify-content: center;
  width: 25%;
`;

export const Content = styled.div`
  margin-top: 24px;
`;
