import React, { useCallback, useMemo, useState } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import { useTheme } from 'styled-components';
import { CardSummary } from '~/screens/Contract/components/Tabs/Summary/CardSummary/CardSummary';

import { ClixSummary } from '~/screens/Contract/components/Tabs/Summary/ClixSummary/ClixSummary';
import { Button, Caret, InfoGroup, Flex, Tag, Typography, Alert } from '~/ui/components';
import toPersonDocument from '~/common/masked/toPersonDocument';
import { AlertCircle } from '~/ui/assets/icons';
import { useModal } from '~/hooks/useModal';
import toDate from '~/common/masked/toDate';
import toNumber from '~/common/masked/toNumber';
// import {
//   ClixContractType,
//   ClixContractsPopover,
// } from '~/components/ClixContractsPopover/ClixContractsPopover';
// import { listClixContracts } from '~/store/clix/actions';
import { Contract } from '~/typings/entities/Clix';
import { getDetails } from '~/store/contracts/actions';
import { ClearSaleScore } from '~/components';
import { LOAN_TYPES } from '~/typings/enums/LoanType';
import { Section, Title, Wrapper, Grid, Content, Score } from './Summary.styles';
import VerificationData from './components/Modals/VerificationData';
import DocumentValidation from './components/Modals/DocumentValidation';
import OperationStep from './components/OperationStep/OperationStep';
import TabLoading from '../components/TabLoading/TabLoading';

const Risk = {
  LOW: {
    label: 'Baixo Risco',
    color: 'positive',
  },
  MEDIUM: {
    label: 'Médio Risco',
    color: 'alert',
  },
  HIGH: {
    label: 'Alto Risco',
    color: 'negative',
  },
};

interface SummaryProps {
  contract: Contract;
}

function Summary({ contract }: SummaryProps) {
  const [sectionVisibility, setSectionVisibility] = useState([]);
  const { colors } = useTheme();
  const { openModal } = useModal();

  const person = contract?.personData;
  const contractModel = contract?.contract;
  const formalization = contract?.formalizationData;

  // const getClixContractsControl = useAwaitControl(listClixContracts);
  // const clixContracts = getClixContractsControl.result();

  const contractDetailsControl = useAwaitControl(getDetails);
  const loadingContract = contractDetailsControl.isRunning();
  const contractHasFailed = contractDetailsControl.hasFailure();

  // const contractsList: ClixContractType[] = clixContracts?.contracts;

  const handleOpenModal = useCallback(
    (content, title, width, button?) => {
      openModal(content, {
        width,
        title,
        closable: true,
        footer: button ? (
          <Button title={button} variant="outline" rounded size="md" color="black" />
        ) : null,
      });
    },
    [openModal],
  );

  const renderScoreTag = useMemo(() => {
    const risk = Risk[formalization?.biometricsRisk?.risk];
    const textColor = colors?.[risk?.color]?.primaryAlt;
    const bgColor = colors?.[risk?.color]?.secondary;

    return (
      <Flex align="center" gap={8}>
        <Tag small rounded textColor={textColor} bgColor={bgColor}>
          {risk?.label ?? 'Não analisado'}
        </Tag>
        <AlertCircle
          width={16}
          height={16}
          color={colors.brand.primary}
          data-testid="contract-biometric-score-info"
          onClick={() =>
            handleOpenModal(
              <VerificationData formalization={formalization} />,
              'Dados da verificação',
              1000,
            )
          }
        />
      </Flex>
    );
  }, [formalization]);

  const renderDocumentValidation = useMemo(() => {
    const risk = Risk[formalization?.documentValidationRisk?.risk];
    const textColor = colors?.[risk?.color]?.primaryAlt;
    const bgColor = colors?.[risk?.color]?.secondary;

    return (
      <Flex align="center" gap={8}>
        <Tag small rounded textColor={textColor} bgColor={bgColor}>
          {risk?.label ?? 'Não analisado'}
        </Tag>
        <AlertCircle
          width={16}
          height={16}
          color={colors.brand.primary}
          data-testid="contract-document-validation-info"
          onClick={() =>
            handleOpenModal(
              <DocumentValidation formalization={formalization} />,
              'Validação do documento ',
              790,
            )
          }
        />
      </Flex>
    );
  }, [formalization]);

  const renderClearSaleScore = useCallback((contract) => {
    const clearSale = contract?.formalizationData?.clearSaleList;

    if (!Array.isArray(clearSale) || clearSale.length === 0) {
      return <Score score={null}>-</Score>;
    }

    const mostRecentObject = clearSale.reduce((prev, current) =>
      new Date(prev.validationDate) > new Date(current.validationDate) ? prev : current,
    );

    const score = mostRecentObject?.score;

    return <ClearSaleScore score={score} scoreHistory={clearSale} />;
  }, []);

  const handleDetailsVisibility = (section: number) => {
    setSectionVisibility((prevSections) => {
      if (prevSections.includes(section)) {
        return prevSections.filter((n) => n !== section);
      }

      return [...prevSections, section];
    });
  };

  const checkProductType = useCallback(
    (products: string[]) => products.includes(contract?.contract?.product),
    [contract],
  );

  // useEffect(() => {
  //   if (person?.document) {
  //     getClixContractsControl.start({ document: person.document });
  //   }
  // }, [person]);

  return (
    <Wrapper>
      {loadingContract && <TabLoading />}
      {!contractHasFailed && !loadingContract && (
        <>
          <Section border>
            <Title onClick={() => handleDetailsVisibility(1)} data-testid="contract-summary-score">
              <Typography type="bodyLarge" weight={600}>
                Status score
              </Typography>
              <Caret caretOpen={!sectionVisibility.includes(1)} />
            </Title>

            {!sectionVisibility.includes(1) && (
              <Grid gridNumber={3}>
                <InfoGroup label="Status lógico" value={contractModel?.logicalStatus} />
                <InfoGroup label="Score biométrico" value={renderScoreTag} />
                <InfoGroup label="Score clear sale" value={renderClearSaleScore(contract)} />
                <InfoGroup
                  label="Passo da esteira"
                  value={
                    <OperationStep contract={contract} step={contractModel?.operationStep?.name} />
                  }
                />
                <InfoGroup label="Validação do documento" value={renderDocumentValidation} />
              </Grid>
            )}
          </Section>

          <Section border>
            <Title
              onClick={() => handleDetailsVisibility(3)}
              data-testid="contract-summary-personal-info"
            >
              <Typography type="bodyLarge" weight={600}>
                Informações pessoais
              </Typography>
              <Caret caretOpen={!sectionVisibility.includes(3)} />
            </Title>

            {!sectionVisibility.includes(3) && (
              <Grid>
                <InfoGroup label="CPF" value={toPersonDocument(person?.document)} />
                <InfoGroup label="Nome da mãe" value={person?.mothersName} />
                <InfoGroup label="Telefone" value={toNumber(person?.phoneNumber1)} />
                {/* <InfoGroup */}
                {/*  label="Contrato clix" */}
                {/*  value={<ClixContractsPopover contracts={contractsList} />} */}
                {/* /> */}
                <InfoGroup label="RG" value={person?.registerNumber} />
                <InfoGroup label="Data de nascimento" value={toDate(person?.birthDate)} />
                <InfoGroup label="E-mail" value={person?.email} />
              </Grid>
            )}
          </Section>

          {/* Clix summary */}
          {checkProductType([LOAN_TYPES.PIX_CREDIT_CARD]) && <ClixSummary contract={contract} />}

          {/* Cards summary */}
          {checkProductType([LOAN_TYPES.BENEFIT_CARD_RCC, LOAN_TYPES.CONSIGNED_CARD_RMC]) && (
            <CardSummary contract={contract} />
          )}

          {/* TODO: implementar resumos de outros tipos de produtos */}
        </>
      )}
      {contractHasFailed && !loadingContract && (
        <Content>
          <Alert label="Não foi possível carregar as informações." status="neutral" fullWidth />
        </Content>
      )}
    </Wrapper>
  );
}

export default Summary;
