import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { Checkbox } from 'antd';
import { CaretDown, Loading } from '~/ui/assets/icons';
import useOutsideClickEffect from '~/common/useOutsideClickEffect';
import { Popover } from '~/ui/components';
import { createPortal } from 'react-dom';
import { Input } from '../Input/Input';

import {
  ContentContainer,
  CustomComponentsAreaProps,
  MultiSelectTrigger,
  PortalSelect,
} from './MultiSelect.styled';

interface MultiSelectProps {
  options: { label: string; value: string }[];
  initialValue?: any[] | string;
  onChange?: React.Dispatch<React.SetStateAction<string[]>>;
  placeholder?: string;
  disabled?: boolean;
  loading?: boolean;
  label?: string;
  testId?: string;
}

const Portal = (props: CustomComponentsAreaProps) =>
  createPortal(
    <PortalSelect id="mt-multiselect-portal" {...props} />,
    document.getElementById('portal'),
  );

export const MultiSelect = forwardRef(
  (
    {
      options,
      initialValue,
      label,
      onChange,
      placeholder,
      disabled,
      testId = 'cascader',
      loading,
    }: MultiSelectProps,
    ref,
  ) => {
    const [selectedValues, setSelectedValues] = useState(
      typeof initialValue === 'string' ? [initialValue] : initialValue || [],
    );
    const [currentValue, setCurrentValue] = useState([]);
    const [visible, setVisible] = useState(false);
    const containerRef = useRef(null);
    const selectRef = useRef(ref);

    const handleSelectionChange = useCallback((ev) => {
      setSelectedValues(ev);
    }, []);

    const handleClear = useCallback((ev) => {
      if (!ev || ev.length === 0) {
        handleSelectionChange([]);
        setVisible(false);
      }
    }, []);

    const multiSelectText = () => {
      if (loading) return '';

      const valuesToRender = selectedValues
        .slice(0, 3)
        .map((el) => options.find((opt) => opt.value === el)?.label);

      if (selectedValues.length === 4) {
        return `${valuesToRender.join(', ')} (+ 1 outro)`;
      }

      if (selectedValues.length > 4) {
        return `${valuesToRender.join(', ')} (+${selectedValues.length - 3} outros)`;
      }

      if (selectedValues.length > 0) {
        return `${valuesToRender.join(', ')}`;
      }

      return '';
    };

    const getPopupContainer = () => document.getElementById('mt-multiselect-portal');

    const getElementSize = useCallback((r) => r?.current?.clientWidth || 'auto', []);

    useEffect(() => {
      onChange?.(selectedValues);
    }, [selectedValues]);

    useEffect(() => {
      if (currentValue !== initialValue) {
        setSelectedValues(typeof initialValue === 'string' ? [initialValue] : initialValue || []);
      }
    }, [initialValue]);

    useEffect(() => {
      setCurrentValue(selectedValues);
    }, [selectedValues]);

    useOutsideClickEffect(containerRef, () => {
      setVisible(false);
    });

    return (
      <>
        <Popover
          placement="bottomLeft"
          trigger="click"
          visible={visible}
          noHeader
          getPopupContainer={getPopupContainer}
          noArrow
          data-testid={`${testId}-multiselect`}
          content={
            <ContentContainer ref={containerRef}>
              <Checkbox.Group
                options={options}
                onChange={handleSelectionChange}
                disabled={disabled || loading}
                value={selectedValues}
              />
            </ContentContainer>
          }
        >
          <MultiSelectTrigger ref={selectRef} disabled={disabled} onClick={() => setVisible(true)}>
            <Input
              ref={ref}
              placeholder={loading || !selectedValues?.length ? placeholder : ''}
              allowClear
              onChange={handleClear}
              value={multiSelectText()}
              disabled={disabled || loading}
              label={label}
              testId={`${testId}-multiselect`}
            />
            {loading ? <Loading className="icon-custom" /> : <CaretDown className="icon-custom" />}
          </MultiSelectTrigger>
        </Popover>
        <Portal width={getElementSize(selectRef)} />
      </>
    );
  },
);
