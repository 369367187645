import React, { forwardRef, useEffect, useRef, useState } from 'react';

import { Input, Switch, Tooltip } from '~/ui/components';

import { TwilioInputContainer } from './TwilioInput.styled';

export type TwilioInputProps = {
  defaultChecked?: boolean;
  onChange?: (value: any) => void;
  testId?: string;
};

export const TwilioInput = forwardRef(
  ({ defaultChecked = false, onChange, testId = 'twilio', ...props }: TwilioInputProps, ref) => {
    const [checked, setChecked] = useState(defaultChecked);
    const containerRef = useRef<HTMLDivElement>(null);

    const tooltipContent =
      'Caso o atendimento tenha sido realizado pela Twilio, por favor, ative o botão e insira o protocolo gerado pela plataforma.';

    useEffect(() => {
      if (!checked) {
        onChange?.('');
      }
    }, [checked, containerRef]);

    return (
      <TwilioInputContainer checked={checked} ref={containerRef}>
        <Switch
          checked={checked}
          onChange={setChecked}
          label={
            <>
              Inserir protocolo da Twilio?{' '}
              <Tooltip width={230} title="Twilio" content={tooltipContent} />
            </>
          }
          small
          data-testid={`${testId}-switch`}
        />
        {checked && (
          <Input
            placeholder="Insira o protocolo Twilio (Opcional)"
            maxLength={50}
            onChange={onChange}
            {...props}
            label="Protocolo"
            testId={`${testId}-switch`}
          />
        )}
      </TwilioInputContainer>
    );
  },
);
