import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useDrawer } from '~/hooks/useDrawer';

import { useModal } from '~/hooks/useModal';
import { DownloadNodeModal, MenuOptions, TicketModal } from '~/components';
import { DotsVertical } from '~/ui/assets/icons';
import TransactionDetail from '../TransactionDetail/TransactionDetail';

function TransactionActions() {
  const { openModal } = useModal();
  const { openDrawer } = useDrawer();
  const theme = useTheme();

  const openObjection = () => {
    openDrawer(
      'ticket-modal',
      <TicketModal
        ticket={{
          ticketReasonId: 1,
          ticketSubReasonId: 1,
          personId: 1,
        }}
      />,
      {
        title: 'Abrir contestação',
        width: 460,
        closable: true,
        maskClosable: false,
      },
    );
  };

  const openDownloadTransaction = () => {
    openModal(
      <DownloadNodeModal fileName="Comprovante de transação">
        <TransactionDetail />
      </DownloadNodeModal>,
      {
        width: 442,
        closable: true,
        noPadding: true,
        maskClosable: false,
      },
    );
  };

  const options = useMemo(
    () => [
      {
        key: 'open-objection',
        label: 'Abrir contestação',
        onClick: openObjection,
      },
      {
        key: 'download-transaction-detail',
        label: 'Baixar detalhes da transação',
        onClick: openDownloadTransaction,
      },
    ],
    [],
  );

  return (
    <MenuOptions options={options} placement="bottomRight">
      <DotsVertical
        data-testid="transaction-actions"
        color={theme.colors.primary.main}
        width={16}
        height={16}
      />
    </MenuOptions>
  );
}

export default TransactionActions;
