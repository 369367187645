import React from 'react';
import useTimer from '~/hooks/useTimer';
import { Card } from '~/components';

import { TimerValue } from './ContractDetails.Styles';

type TimerProps = {
  endTime: string;
  onFinish: () => any;
};

export default function Timer({ endTime, onFinish }: TimerProps) {
  const value = useTimer(endTime, true, 1_000, onFinish);

  return (
    <Card style={{ marginLeft: '16px', textAlign: 'center', padding: '16px' }}>
      {value && <TimerValue>{value}</TimerValue>}
    </Card>
  );
}
