import styled from 'styled-components';

import { themeColor } from '~/common/utils/theme';
import { Typography } from '~/ui/components';

export const Wrapper = styled.div`
  display: flex;
  gap: 14px;
  justify-content: center;
`;

export const InfoValue = styled(Typography).attrs(({ theme, color, weight, type }) => ({
  type: type || 'caption',
  element: 'span',
  weight: weight || 400,
  color: color || theme.colors.neutral.primaryAlt,
}))`
  cursor: pointer;
  background-color: ${themeColor('background.tertiary')};
  inline-size: fit-content;
  min-width: 125px;
  padding: 8px 8px 8px 8px;
  border-radius: 8px;
`;
