import React, { useEffect } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import {
  getTicketComments,
  getTicketDetails,
  getTicketStatus,
  selectTicket,
} from '~/store/tickets/actions';
import { Tag } from '~/ui/components';
import { TicketPriorities, TicketPrioritiesPalette } from '~/typings/enums/Ticket/TicketPriorities';
import { Link as LinkIcon, Loading } from '~/ui/assets/icons';

import { findLastNameById } from '~/common/selectValues';
import { getTicketStatusColor } from '~/common/tickets';
import { SectionTitle, TagsRow, TicketContainer, LoadingContainer } from './TicketDetails.styled';
import { TicketResume } from './TicketResume/TicketResume';
import { TicketComments } from './TicketComments/TicketComments';

export function TicketDetails({ id }: { id: string }) {
  const getTicketDetailsControl = useAwaitControl(getTicketDetails);
  const getTicketCommentsControl = useAwaitControl(getTicketComments);
  const getTicketStatusControl = useAwaitControl(getTicketStatus);
  const selectTicketControl = useAwaitControl(selectTicket);

  const ticket = getTicketDetailsControl.result(id);
  const comments = getTicketCommentsControl.result(id);
  const status = getTicketStatusControl.result();

  const ticketLoading = getTicketDetailsControl.isRunning(id);
  const commentsLoading = getTicketCommentsControl.isRunning(id);

  useEffect(() => {
    if (id && typeof id === 'number') {
      getTicketDetailsControl.start({ id }, { actionId: id });
      getTicketCommentsControl.start(id, { actionId: id });
    }

    return () => {
      getTicketDetailsControl.clear();
      getTicketCommentsControl.clear();
      selectTicketControl.clear();
    };
  }, [id]);

  if (ticketLoading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  return (
    <TicketContainer>
      <SectionTitle>Detalhes do Ticket</SectionTitle>

      <TagsRow>
        {ticket?.parentId && (
          <Tag small rounded bgColor="#D6E4FF" textColor="#0100B2">
            <LinkIcon width={14} height={14} /> Ticket atrelado
          </Tag>
        )}

        <Tag small rounded color={TicketPrioritiesPalette[ticket?.priorityTicket]?.palette}>
          {TicketPriorities[ticket?.priorityTicket]}
        </Tag>

        <Tag small rounded {...getTicketStatusColor(status, ticket?.ticketStatusId)}>
          {findLastNameById(status, ticket?.ticketStatusId)}
        </Tag>
      </TagsRow>

      <TicketResume ticket={ticket} />

      <TicketComments ticketId={id} comments={comments} loading={commentsLoading} />
    </TicketContainer>
  );
}
