import styled, { css } from 'styled-components';
import { themeColor } from '~/common/utils/theme';
import { Typography } from '~/ui/components';

export const Wrapper = styled.div`
  align-items: flex-start;
  width: 100%;
  min-width: 720px;
`;

type SectionType = {
  border?: boolean;
};

export const Section = styled.div.attrs({
  className: 'section',
})<SectionType>`
  display: flex;
  flex-direction: column;
  padding: 24px;

  ${({ border }) =>
    border &&
    css`
      border-bottom: 2px dotted ${themeColor('divider.primary')};
    `}

  &:last-child {
    border-bottom: none;
  }
`;

export const Content = styled.div`
  margin-top: 24px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 12px;
`;

export const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: baseline;
  justify-content: center;
  width: 25%;
`;

type FlexType = {
  gridNumber: number;
};

export const Grid = styled.div<FlexType>`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  margin-top: 24px;

  svg {
    cursor: pointer;
  }

  ${({ gridNumber }) =>
    !gridNumber &&
    css`
      @media (max-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media (max-width: 1100px) {
        grid-template-columns: repeat(2, 1fr);
      }
    `}

  ${({ gridNumber }) => css`
    grid-template-columns: repeat(${gridNumber}, 1fr);
  `}
`;

type ScoreProps = {
  score: number;
};

export const Score = styled<ScoreProps>(Typography).attrs(() => ({
  type: 'caption',
  element: 'span',
  weight: 600,
}))`
  color: ${themeColor('element.primary')} !important;

  ${({ score }) =>
    score &&
    score >= 90 &&
    css`
      color: ${themeColor('negative.primaryAlt')} !important;
    `};

  ${({ score }) =>
    score &&
    score < 90 &&
    css`
      color: ${themeColor('negative.primary')} !important;
    `};

  ${({ score }) =>
    score &&
    score < 80 &&
    css`
      color: ${themeColor('alert.primaryAlt')} !important;
    `};

  ${({ score }) =>
    score &&
    score < 46 &&
    css`
      color: ${themeColor('positive.primary')} !important;
    `};

  ${({ score }) =>
    score &&
    score < 10 &&
    css`
      color: ${themeColor('positive.primaryAlt')} !important;
    `};
`;
