import React, { memo, useCallback, useMemo } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { Alert } from '~/ui/components';
import { getDetails, getContractHistory } from '~/store/contracts/actions';
import {
  Container,
  EventList,
  Event,
  IconContent,
  Dot,
  Line,
  Label,
  Info,
  Section,
} from './History.styles';
import TabLoading from '../components/TabLoading/TabLoading';

interface HistoryProps {
  events: any[];
}

const isEvenOrOdd = (number: number) => (number % 2 === 0 ? 'par' : 'ímpar');

function InfoLine({ label, info }) {
  return (
    <Info>
      <Label>{label}:</Label> {info}
    </Info>
  );
}

function History({ events }: HistoryProps) {
  const detailsControl = useAwaitControl(getDetails);
  const contractHistoryControl = useAwaitControl(getContractHistory);

  const historyLoading = contractHistoryControl.isRunning();
  const detailsLoading = detailsControl.isRunning();

  const loading = useMemo(() => historyLoading || detailsLoading, [historyLoading, detailsLoading]);

  const renderEventSection = useCallback(
    (event, isHidden: boolean, evenOrOdd) => (
      <Section isHidden={isHidden} evenOrOdd={evenOrOdd}>
        {Object.entries(event).map(([key, value]) => (
          <InfoLine key={key} label={key} info={value} />
        ))}
      </Section>
    ),
    [],
  );

  const documentations = useMemo(
    () =>
      Array.isArray(events) &&
      events?.map((event, index) => {
        const evenOrOdd = isEvenOrOdd(index);
        const isOdd = evenOrOdd === 'ímpar';

        return (
          <Event key={index}>
            {renderEventSection(event, !isOdd, evenOrOdd)}
            <IconContent>
              <Dot />
              <Line isConected={index + 1 < events.length} />
            </IconContent>
            {renderEventSection(event, isOdd, evenOrOdd)}
          </Event>
        );
      }),
    [events],
  );

  return (
    <Container>
      {loading && <TabLoading />}
      {documentations && !loading && (
        <EventList data-testid="time-line-documentation">{documentations}</EventList>
      )}
      {!documentations && !loading && (
        <Alert label="Não foi possível carregar as informações." status="neutral" fullWidth />
      )}
    </Container>
  );
}

export default memo(History);
