import React, { useMemo } from 'react';
import fromNow from '~/common/masked/fromNow';

import useAsyncActionStatus from '~/hooks/useAsyncActionStatus';
import { getDetails } from '~/store/contracts/actions';
import formatName from '~/common/formatName';
import { Shimmer } from '~/ui/components';

import { Link } from 'react-router-dom';
import { HeaderContainer, Title, Age } from './Header.styled';
import { Tags } from '../Tags/Tags';

export function Header() {
  const detailsControl = useAsyncActionStatus(getDetails);

  const name = useMemo(() => detailsControl.result?.personData?.name, [detailsControl.result]);
  const age = useMemo(() => detailsControl.result?.personData?.birthDate, [detailsControl.result]);
  const id = useMemo(() => detailsControl.result?.personData?.id, [detailsControl.result]);

  if (detailsControl.loading) {
    return (
      <HeaderContainer>
        <Shimmer width={250} height={32} />
        <Shimmer width={150} height={20} />
      </HeaderContainer>
    );
  }

  return (
    <HeaderContainer>
      <Link to={`/backoffice/people/${id}`}>
        <Title>{formatName(name)}</Title>
      </Link>

      <Age>{age ? `${fromNow(age)} de idade` : '-'}</Age>
      <Tags id={id} type="PERSON" />
    </HeaderContainer>
  );
}
