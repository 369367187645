import moment from 'moment';
import { CellProps } from '~/ui/components';

export function cellToDate<TData>(format: string = 'DD/MM/YYYY') {
  return (props: CellProps<TData>) => {
    const value = props?.getValue<number | string>();
    const date = moment(value).format(format);
    return moment(value).isValid() ? date : '-';
  };
}
