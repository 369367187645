import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { Money } from '~/common';
import toPercentage from '~/common/masked/toPercentage';

import { Contract } from '~/typings/entities/Clix';
import getAccountType from '~/typings/enums/AccountType';
import ContractStatus from '~/typings/enums/ContractStatus';
import LoanType from '~/typings/enums/LoanType';
import { InfoGroup } from '~/ui/components';

import { Section } from '../components/Section/Section';
import { Grid } from '../Summary.styles';

export type CardSummaryProps = {
  contract: Contract;
};

export function CardSummary({ contract }: CardSummaryProps) {
  const formatDate = useCallback(
    (date: string) => (date ? moment(date).format('DD/MM/YYYY') : '-'),
    [],
  );
  const formatDueDate = useCallback((date: string) => {
    if (!date) return '-';

    const formattedDate = moment(date).format('DD');
    return `Dia ${formattedDate}`;
  }, []);

  const formattedAgency = useMemo(() => {
    if (!contract?.contractInfo?.agency) return '-';
    return `${contract?.contractInfo?.agency}-${contract?.contractInfo?.agencyDigit}`;
  }, [contract]);

  const formattedAccount = useMemo(() => {
    if (!contract?.contractInfo?.account) return '-';
    return `${contract?.contractInfo?.account}-${contract?.contractInfo?.accountDigit}`;
  }, [contract]);

  return (
    <>
      <Section title="Contas de pagamento">
        <Grid>
          <InfoGroup label="Banco" value={contract?.contractInfo?.financialInstitution} />
          <InfoGroup
            label="Tipo de conta"
            value={getAccountType(contract?.contractInfo?.accountType)}
          />
          <InfoGroup label="Agência" value={formattedAgency} />
          <InfoGroup label="Conta" value={formattedAccount} />
          <InfoGroup
            label="Tipo de conta do benefício"
            value={getAccountType(contract?.contractInfo?.benefitAccountType)}
          />
          <InfoGroup label="Código do benefício" value={contract?.contractInfo?.benefitCode} />
          <InfoGroup label="Convênio" value={contract?.contractInfo?.agreement} />
          <InfoGroup label="Matrícula" value={contract?.contractInfo?.enrollment} />
          <InfoGroup label="Produto" value={LoanType(contract?.contractInfo?.contractCardType)} />
        </Grid>
      </Section>

      <Section title="Informações do contrato">
        <Grid>
          <InfoGroup
            label="Limite disponível"
            value={Money(contract?.contractInfo?.availableLimit)}
          />
          <InfoGroup
            label="Taxa nominal (mês)"
            value={toPercentage(contract?.contractInfo?.nominalRatePerMonth)}
          />
          <InfoGroup
            label="Custo efetivo total (mês)"
            value={toPercentage(contract?.contractInfo?.totalEffectiveCostPerMonth)}
          />
          <InfoGroup
            label="Data inicial do contrato"
            value={formatDate(contract?.contractInfo?.date)}
          />
          <InfoGroup
            label="Data assinatura do contrato"
            value={formatDate(contract?.contract?.contractDate)}
          />
          <InfoGroup
            label="Data de vencimento"
            value={formatDueDate(contract?.contractInfo?.paymentDate)}
          />
          <InfoGroup label="Atendente" value={contract?.contractInfo?.attendant} />
          <InfoGroup
            label="Status do contrato"
            value={ContractStatus(contract?.contractInfo?.status)}
          />
          <InfoGroup label="N. do contrato" value={contract?.contractInfo?.id} />
          <InfoGroup label="Consignatária" value={contract?.contractInfo?.consignee} />
        </Grid>
      </Section>
    </>
  );
}
