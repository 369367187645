import React, { useEffect } from 'react';
import { CellContext } from '@tanstack/table-core';
import moment from 'moment/moment';

import { NewPendency } from '~/typings/entities/Pendency';
import { getNewPendencies } from '~/store/pendencies/actions';
import useAsyncActionStatus from '~/hooks/useAsyncActionStatus';
import { useModal } from '~/hooks/useModal';
import { Alert, Button, Flex, Table, TableColumns, Tag } from '~/ui/components';
import { PendencyStatusNames } from '~/typings/enums/Pendencies/PendencyStatus';
import { getDetails } from '~/store/contracts/actions';
import { PendencyDetailsModal } from '../../Modals/PendencyDetailsModal/PendencyDetailsModal';

export function Pendencies() {
  const { openModal } = useModal();
  const pendenciesControl = useAsyncActionStatus(getNewPendencies);

  const detailsControl = useAsyncActionStatus(getDetails);

  const handlePendencyDetails = (id: string | number) => {
    const pendency = pendenciesControl.result.find((pendency) => pendency.id === id);
    openModal(<PendencyDetailsModal pendency={pendency} />, {
      id: 'pendency-details',
      title: 'Pendência',
      closable: true,
      width: 'auto',
    });
  };

  const cellToDate = (info: CellContext<any, any>) => moment(info.getValue()).format('DD/MM/YYYY');

  const cellToStatus = (info: CellContext<any, any>) => {
    const statusValue: string = info.getValue<string>();
    const palettes = { CREATED: 'alert', RESOLVED: 'positive' };
    const palette = palettes?.[statusValue as string] || 'warning';
    return (
      <Tag rounded small color={palette}>
        {PendencyStatusNames?.[statusValue] || statusValue}
      </Tag>
    );
  };

  const cellToDetails = (info: CellContext<any, any>) => (
    <Button
      iconOnly
      icon="Paperclip"
      variant="text"
      customColor="brand.primary"
      onClick={() => handlePendencyDetails(info.row.original.id)}
    />
  );

  const sortDate = (rowA: any, rowB: any, columnId: any): number => {
    const dateA = moment(rowA.getValue(columnId));
    const dateB = moment(rowB.getValue(columnId));
    return dateA.isBefore(dateB) ? -1 : 1;
  };

  const columns: TableColumns<NewPendency> = [
    { accessorKey: 'pendingDate', header: () => 'DATA', sortingFn: sortDate, cell: cellToDate },
    { accessorKey: 'status', header: () => 'STATUS', cell: cellToStatus },
    { accessorKey: 'type', header: () => 'TIPO DA PENDÊNCIA' },
    { accessorKey: 'reason', header: () => 'MOTIVO DA PENDÊNCIA' },
    { id: 'details', header: () => 'DETALHES', cell: cellToDetails },
  ];

  useEffect(() => {
    if (detailsControl.success) {
      const { contract } = detailsControl.result;
      pendenciesControl.start({
        externalId: contract.externalId,
        product: contract.product,
        mode: 'FULL',
      });
    }
  }, [detailsControl.success]);

  if (!detailsControl.loading && !pendenciesControl.loading && !pendenciesControl.result) {
    return (
      <Flex width="100%" pt={24}>
        <Alert label="Nenhuma pendência encontrada." status="neutral" fullWidth />
      </Flex>
    );
  }

  return (
    <Table
      noItemsMessage="Nenhuma pendência encontrada"
      loading={pendenciesControl.loading || detailsControl.loading}
      data={pendenciesControl.result}
      responsiveCols={['pendingDate', 'status', 'type', 'reason', 'details']}
      columns={columns}
    />
  );
}
