import React from 'react';
import { useTheme } from 'styled-components';

import { themeToggleValue } from '~/common/utils/theme';
import { CaretDown, CaretUp } from '~/ui/assets/icons';

export interface CaretProps {
  caretOpen?: boolean;
  size?: number;
}

export function Caret({ caretOpen, size = 14 }: CaretProps) {
  const theme = useTheme();

  const { brand } = theme.colors;

  return (
    <>
      {caretOpen ? (
        <CaretUp
          color={themeToggleValue(theme, brand.primary, { dark: brand.secondaryAlt })}
          className="action-icon"
          width={size}
          height={size}
        />
      ) : (
        <CaretDown
          color={themeToggleValue(theme, brand.primary, { dark: brand.secondaryAlt })}
          className="action-icon"
          width={size}
          height={size}
        />
      )}
    </>
  );
}
