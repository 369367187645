import React, { Fragment } from 'react';
import { useHistory } from 'react-router';
import { BreadcrumbItem, BreadcrumbWrapper, Separator, BackButton } from './Breadcrumb.styled';

export type BreadcrumbProps = {
  items: { label: string; href?: string }[];
  withBackButton?: boolean;
};

export function Breadcrumb({ items, withBackButton }: BreadcrumbProps) {
  const history = useHistory();

  const handleClick = (href: string) => {
    if (href) {
      history.push(href);
    }
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <BreadcrumbWrapper>
      {withBackButton && <BackButton onClick={handleBack} />}

      {items.map((item, index) => {
        const isLast = index === items.length - 1;
        return (
          <Fragment key={index}>
            <BreadcrumbItem key={index} onClick={() => handleClick(item.href)} $isLast={isLast}>
              {item.label}
            </BreadcrumbItem>
            {isLast ? null : <Separator />}
          </Fragment>
        );
      })}
    </BreadcrumbWrapper>
  );
}
