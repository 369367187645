import React, { useCallback, useMemo, useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { useTheme } from 'styled-components';
import { Timeline } from 'antd';

import { CaretDown, CaretUp, CloseCircle, Loading } from '~/ui/assets/icons';
import * as Icons from '~/ui/assets/icons';
import { useDrawer } from '~/hooks/useDrawer';
import {
  TransactionStatusColors,
  TransactionStatusHelpText,
  TransactionStatusIcons,
  TransactionStatusString,
  TransactionTypeIcon,
} from '~/typings/enums/Clix';
import { formatName, Money } from '~/common';

import { Alert, Button, Flex, Popover, Shimmer, Tag, Tooltip } from '~/ui/components';
import { TransactionGroup } from '~/typings/entities/Wallet';
import { useAwaitControl } from 'react-redux-await-control';
import { getPersonDetail } from '~/store/people/actions';
import toCPFFormat from '~/common/masked/toCPFFormat';
import { getTransactionHistory, getTransactionReceipt } from '~/store/wallet/actions';
import { getTransactionFile } from '~/store/clix/actions';
import { useModal } from '~/hooks/useModal';
import { DownloadImageModal, DownloadNodeModal } from '~/components';
import {
  ActionsRow,
  ActionButton,
  TransactionHeader,
  TransactionTitle,
  TransactionDate,
  SectionTitle,
  InfoGroup,
  InfoLabel,
  InfoValue,
  TransactionRecipient,
  InstallmentsInfo,
  TotalValue,
  CircleIcon,
  TransactionDetailsWrapper,
  TopAlertsWrapper,
  InfoRow,
  TransactionOrigin,
  RecipientAlertsWrapper,
  Information,
  StyledTimeline,
  InfoData,
  SectionWrapper,
} from './CreditTransactionDetails.styled';
import Download from '../../Modals/Download';

interface CreditTransactionDetailsProps {
  transaction: TransactionGroup;
}

export function CreditTransactionDetails({ transaction }: CreditTransactionDetailsProps) {
  const [isTransactionsOpen, setIsTransactionsOpen] = useState(false);

  const theme = useTheme();
  const { closeDrawer } = useDrawer();
  const { openModal } = useModal();

  const personControl = useAwaitControl(getPersonDetail);
  const person = personControl.result();

  const transactionsControl = useAwaitControl(getTransactionHistory);
  const transactionsHistory = transactionsControl.result();
  const transactionsIsLoading = transactionsControl.isRunning();

  const getClixTransactionFile = useAwaitControl(getTransactionFile);
  const fileLoading = getClixTransactionFile.isRunning();
  const fileResult = getClixTransactionFile.result();
  const fileError = getClixTransactionFile.hasFailure();

  const getClixTransactionReceipt = useAwaitControl(getTransactionReceipt);
  const transactionReceipt = getClixTransactionReceipt.result();
  const loadingTransaction = getClixTransactionReceipt.isRunning();
  const transactionFailed = getClixTransactionReceipt.hasFailure();

  const getTransactionBiometryFile = useCallback(() => {
    getClixTransactionFile.start({
      id: transaction.cashIn.transactionId,
      document: person.document,
      type: 'BIOMETRY',
      warranty: 'CREDIT',
    });
  }, [transaction, person]);

  const openFile = useCallback((fileResult: any) => {
    const url = `data:image/png;base64,${fileResult?.base64}`;
    const name = formatName(person?.name, 2);
    openModal(
      <DownloadImageModal
        url={url}
        fileName={`Foto assinatura - ${name} - #${transaction.cashIn.transactionId}`}
      />,
      {
        width: 450,
      },
    );
  }, []);

  const renderTransactionStatus = useCallback(
    (status: string) => {
      if (!status) return '-';
      const colorPalette = TransactionStatusColors[status];
      const textColor = theme.colors?.[colorPalette]?.primaryAlt;
      const bgColor = theme.colors?.[colorPalette]?.secondary;
      const StatusIcon = TransactionStatusIcons?.[status];

      return (
        <Tooltip
          width={265}
          title={TransactionStatusString?.[status] || status}
          content={TransactionStatusHelpText?.[status]}
          placement="bottomLeft"
        >
          <Tag rounded textColor={textColor} bgColor={bgColor} small>
            {StatusIcon && <StatusIcon width={16} height={16} />}{' '}
            {TransactionStatusString?.[status] || status || '-'}
          </Tag>
        </Tooltip>
      );
    },
    [transaction],
  );

  const IconComponent = useMemo(() => {
    const iconName = TransactionTypeIcon.TRANSFER;
    return iconName && Icons[iconName] ? Icons[iconName] : Icons.Paste;
  }, [transaction]);

  const handleDownload = useCallback((transaction) => {
    const fileName = `Comprovante da transação`;

    openModal(
      <DownloadNodeModal fileName={fileName}>
        <Download transaction={transaction} />
      </DownloadNodeModal>,
      {
        closable: true,
        maskClosable: false,
        noPadding: true,
        width: 500,
      },
    );
  }, []);

  const handleDownloadNFSE = async (url: string) => {
    const link = document.createElement('a');
    link.download = `nota_fiscal_eletronica.jpg`;
    link.href = url;
    link.click();
    link.remove();
  };

  const transactionSuccess =
    transaction?.cashIn?.status === 'PROCESSED' && transaction?.cashOut?.status === 'PROCESSED';

  useEffect(() => {
    if (fileResult?.url && !fileError) {
      window.open(fileResult, '_blank');
    }

    if (fileResult?.base64 && !fileError) {
      openFile(fileResult);
    }
  }, [fileResult]);

  useEffect(() => {
    if (transaction) {
      transactionsControl.start({
        reference: transaction?.cashIn?.reference,
      });
    }

    if (transaction && transactionSuccess) {
      getClixTransactionReceipt.start({ reference: transaction?.cashIn?.reference });
    }

    return () => {
      getClixTransactionFile.clear();
    };
  }, [transaction, transactionSuccess]);

  return (
    <TransactionDetailsWrapper>
      <TransactionHeader>
        <CircleIcon>
          <IconComponent width={12} height={12} />
        </CircleIcon>

        <TransactionTitle>
          Transação <b>#{transaction?.cashOut?.transactionId}</b>
        </TransactionTitle>

        <Flex width="auto" align="center">
          {transactionSuccess && (
            <Button
              variant="text"
              size="md"
              iconOnly
              icon="Download"
              title="Baixar resumo"
              disabled={false}
              onClick={() => handleDownload(transaction)}
            />
          )}
          <CloseCircle
            width={25}
            height={25}
            className="close-icon"
            onClick={() => closeDrawer('credit-transaction-details')}
          />
        </Flex>
      </TransactionHeader>

      <ActionsRow>
        <ActionButton disabled={fileLoading} onClick={getTransactionBiometryFile}>
          {fileLoading ? <Loading /> : 'Foto assinatura'}
        </ActionButton>
      </ActionsRow>

      <TransactionOrigin>
        <TopAlertsWrapper>{renderTransactionStatus(transaction.cashIn?.status)}</TopAlertsWrapper>

        <SectionTitle>Dados de origem</SectionTitle>

        <TransactionDate>
          {moment(transaction?.cashOut?.date).format('DD/MM/YYYY - HH:mm:ss')}
        </TransactionDate>

        <SectionWrapper>
          <InfoLabel>Status da transação</InfoLabel>
          {transactionsIsLoading && <InfoValue>Carregando...</InfoValue>}
          {!transactionsIsLoading && transactionsHistory && transactionsHistory.length > 0 && (
            <Popover
              trigger="click"
              onVisibleChange={(ev) => setIsTransactionsOpen(ev)}
              visible={isTransactionsOpen}
              noHeader
              content={
                <StyledTimeline>
                  {transactionsHistory.map((item) => (
                    <Timeline.Item key={item.reference}>
                      <InfoLabel>{item.operationStep.title}</InfoLabel>
                      <InfoData>{item.operationStep.type}</InfoData>
                      <Information>{item.operationStep.description}</Information>
                      <Information>
                        {moment(item.createdAt).format('DD/MM/YYYY - HH:mm:ss')}
                      </Information>
                    </Timeline.Item>
                  ))}
                </StyledTimeline>
              }
            >
              <Information>
                <InfoValue>{transactionsHistory?.[0]?.operationStep?.title}</InfoValue>
                {isTransactionsOpen ? (
                  <CaretUp className="action-icon" width={14} height={14} />
                ) : (
                  <CaretDown className="action-icon" width={14} height={14} />
                )}
              </Information>
            </Popover>
          )}
          {!transactionsIsLoading && !transactionsHistory?.length && (
            <InfoValue>Nenhum status disponível.</InfoValue>
          )}
        </SectionWrapper>

        <InfoRow>
          <InfoGroup>
            <InfoLabel>Nome</InfoLabel>
            <InfoValue>{person?.name ? formatName(person?.name) : '-'}</InfoValue>
          </InfoGroup>

          <InfoGroup>
            <InfoLabel>CPF</InfoLabel>
            <InfoValue>{person?.document ? toCPFFormat(person?.document) : '-'}</InfoValue>
          </InfoGroup>
        </InfoRow>
        <InfoRow>
          <InfoGroup>
            <InfoLabel>Cartão (4 últimos dígitos)</InfoLabel>
            <InfoValue>{transaction.cashOut?.card?.last4 || '-'}</InfoValue>
          </InfoGroup>

          <InfoGroup>
            <InfoLabel>Bandeira</InfoLabel>
            <InfoValue>{transaction.cashOut?.card?.brand || '-'}</InfoValue>
          </InfoGroup>
        </InfoRow>

        <InfoRow>
          <InfoGroup>
            <InfoLabel>ID da Transação (CashIn)</InfoLabel>
            <InfoValue>{transaction.cashIn?.transactionId || '-'}</InfoValue>
          </InfoGroup>

          <InfoGroup>
            <InfoLabel>NSU</InfoLabel>
            <InfoValue>{transaction.cashIn?.nsu || '-'}</InfoValue>
          </InfoGroup>
        </InfoRow>

        <InfoRow>
          <InfoGroup>
            <InfoLabel>Reference</InfoLabel>
            <InfoValue>{transaction.cashIn?.reference || '-'}</InfoValue>
          </InfoGroup>
        </InfoRow>
      </TransactionOrigin>

      <TransactionRecipient>
        <RecipientAlertsWrapper>
          {renderTransactionStatus(transaction.cashOut?.status)}
        </RecipientAlertsWrapper>

        <SectionTitle>Dados de destino</SectionTitle>

        <InfoRow>
          <InfoGroup>
            <InfoValue type="paragraphSmall" weight={600}>
              {transaction?.cashOut?.recipientName}
            </InfoValue>
          </InfoGroup>
        </InfoRow>

        <InfoRow>
          <InfoGroup>
            <InfoLabel>Instituição</InfoLabel>
            <InfoValue>{transaction?.cashOut?.recipientInstitution || '-'}</InfoValue>
          </InfoGroup>

          <InfoGroup>
            <InfoLabel>Agência</InfoLabel>
            <InfoValue>{transaction?.cashOut?.recipientAgency || '-'}</InfoValue>
          </InfoGroup>
        </InfoRow>

        <InfoRow>
          <InfoGroup>
            <InfoLabel>Conta</InfoLabel>
            <InfoValue>{transaction?.cashOut?.recipientAccount || '-'}</InfoValue>
          </InfoGroup>

          <InfoGroup>
            <InfoLabel>CPF</InfoLabel>
            <InfoValue>{transaction?.cashOut?.recipientDocument || '-'}</InfoValue>
          </InfoGroup>
        </InfoRow>

        <InfoRow>
          <InfoGroup>
            <InfoLabel>ID da Transação (CashOut)</InfoLabel>
            <InfoValue>{transaction.cashOut?.transactionId || '-'}</InfoValue>
          </InfoGroup>
        </InfoRow>
      </TransactionRecipient>

      <InstallmentsInfo>
        <SectionTitle>Detalhes da transação</SectionTitle>
        <InfoRow>
          <InfoGroup>
            <InfoLabel>Total transferido</InfoLabel>
            <TotalValue>{Money(transaction?.cashIn?.amount)} </TotalValue>
          </InfoGroup>
        </InfoRow>

        <InfoRow>
          <InfoGroup>
            <InfoLabel>Total a pagar</InfoLabel>
            <TotalValue>
              {Money(transaction?.cashIn?.totalAmount)}{' '}
              {transaction?.cashIn?.installments > 1 ? (
                <small>em {transaction?.cashIn?.installments} parcelas</small>
              ) : (
                <small>à vista</small>
              )}
            </TotalValue>
          </InfoGroup>
        </InfoRow>

        <InfoRow>
          <InfoGroup>
            <InfoLabel>Taxa de serviço</InfoLabel>
            <InfoValue>{Money(transaction?.cashIn?.serviceCharge)}</InfoValue>
          </InfoGroup>
        </InfoRow>
      </InstallmentsInfo>

      {transactionSuccess && (
        <TransactionRecipient>
          <SectionTitle>Detalhes da nota fiscal</SectionTitle>

          {loadingTransaction && (
            <>
              <InfoRow>
                <InfoGroup>
                  <Shimmer br={8} width={200} height={20} />
                  <Shimmer br={8} width={190} height={20} />
                </InfoGroup>
              </InfoRow>

              <InfoRow>
                <InfoGroup>
                  <Shimmer br={8} width={240} height={20} />
                  <Shimmer br={8} width={140} height={20} />
                </InfoGroup>
              </InfoRow>

              <InfoRow>
                <InfoGroup>
                  <Shimmer br={8} width={120} height={20} />
                  <Shimmer br={8} width={200} height={20} />
                </InfoGroup>
              </InfoRow>
            </>
          )}

          {!loadingTransaction && !transactionFailed && (
            <>
              <InfoRow>
                <InfoGroup>
                  <InfoLabel>Nota fiscal</InfoLabel>
                  <InfoValue
                    pointer
                    onClick={() => handleDownloadNFSE(transactionReceipt?.nfsePdfLink)}
                    weight={500}
                  >
                    {transactionReceipt?.nfsePdfLink || '-'}
                  </InfoValue>
                </InfoGroup>
              </InfoRow>
              <InfoRow>
                <InfoGroup>
                  <InfoLabel>E-mail</InfoLabel>
                  <InfoValue>{transactionReceipt?.email || '-'}</InfoValue>
                </InfoGroup>
              </InfoRow>
              <InfoRow>
                <InfoGroup>
                  <InfoLabel>Data e hora</InfoLabel>
                  <InfoValue>
                    {transactionReceipt?.sendDate
                      ? moment(transactionReceipt?.sendDate).format('DD/MM/YYYY - HH:mm:ss')
                      : '-'}
                  </InfoValue>
                </InfoGroup>
              </InfoRow>{' '}
            </>
          )}

          {!loadingTransaction && transactionFailed && (
            <Alert label="Não foi possível carregar as informações." status="neutral" />
          )}
        </TransactionRecipient>
      )}
    </TransactionDetailsWrapper>
  );
}
