import styled, { css } from 'styled-components';
import { themeColor } from '~/common/utils/theme';

type SectionType = {
  border?: boolean;
};

export const SectionContainer = styled.div.attrs({
  className: 'section',
})<SectionType>`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-bottom: 2px dotted ${themeColor('divider.primary')};

  &:last-child {
    border-bottom: none;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 12px;
`;
