import styled from 'styled-components';

export const Textarea = styled.textarea`
  background: ${({ theme }) => theme.colors.background.primary};
  border: 1px solid #c6c6c6;
  border-radius: 12px;
  width: 100%;
  padding: 12px;
  min-height: ${({ small }) => (small ? '52px' : '68px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  outline: none;
`;
