import React, { useMemo } from 'react';
import { PopoverProps } from 'antd/lib/popover';

import { AlertCircle, InfoCircle } from '~/ui/assets/icons';
import colors from '~/ui/theme/colors';
import { Content, ContentWrapper, Popover, Title } from './Tooltip.styled';

export interface TooltipProps extends PopoverProps {
  content?: React.ReactElement | string;
  title?: string;
  iconColor?: string;
  width?: number | string;
  maxWidth?: number | string;
  small?: boolean;
}

export function Tooltip({
  content,
  title,
  width = 'auto',
  maxWidth = 'none',
  iconColor = colors.gray['500'],
  children,
  ...props
}: TooltipProps) {
  const contentWrapper = useMemo(
    () => (
      <ContentWrapper width={width} maxWidth={maxWidth}>
        {title && (
          <Title data-testid="tooltip-title-text">
            <InfoCircle width={20} height={20} />
            {title}
          </Title>
        )}
        {!!content && <Content data-testid="tooltip-content">{content}</Content>}
      </ContentWrapper>
    ),
    [content],
  );

  return (
    <Popover content={contentWrapper} placement="rightTop" {...props}>
      {children || (
        <AlertCircle width={16} height={16} color={iconColor} data-testid="tooltip-alert-circle" />
      )}
    </Popover>
  );
}
