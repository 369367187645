import React, { useState } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { Alert, Caret, Flex, InfoGroup, Typography } from '~/ui/components';
import { Money } from '~/common';
import formatZipcode from '~/common/formatZipcode';
import { PersonDataType } from '~/typings/entities/Clix';
import { getDetails } from '~/store/contracts/actions';
import { Content, InfoColumn, Section, Title, Wrapper } from './OtherInfo.styles';
import TabLoading from '../components/TabLoading/TabLoading';

enum civilStatus {
  MARRIED = 'Casado(a)',
  SEPARATED = 'Separado(a)',
  PAID = 'Divorciado(a)',
  DIVORCED = 'Cancelado',
  SINGLE = 'Solteiro(a)',
  WIDOWER = 'Viúvo(a)',
}

interface OherInfoProps {
  personData: PersonDataType;
}

function OtherInfo({ personData }: OherInfoProps) {
  const [sectionVisibility, setSectionVisibility] = useState([]);

  const contractDetailsControl = useAwaitControl(getDetails);

  const loadingContract = contractDetailsControl.isRunning();

  const handleDetailsVisibility = (section: number) => {
    setSectionVisibility((prevSections) => {
      if (prevSections.includes(section)) {
        return prevSections.filter((n) => n !== section);
      }

      return [...prevSections, section];
    });
  };

  return (
    <Wrapper>
      {loadingContract && <TabLoading />}
      {personData && !loadingContract && (
        <Section>
          <Title
            onClick={() => handleDetailsVisibility(1)}
            data-testid="contract-summary-recipient"
          >
            <Typography type="bodyLarge" weight={600}>
              Outras informações
            </Typography>
            <Caret caretOpen={!sectionVisibility.includes(1)} />
          </Title>

          {!sectionVisibility.includes(1) && (
            <Flex gap={24} mt={24}>
              <InfoColumn>
                <InfoGroup
                  label="Logradouro"
                  value={
                    personData?.street
                      ? `${personData.street}, ${personData?.addressNumber ?? '-'}`
                      : '-'
                  }
                />
                <InfoGroup label="Bairro" value={personData?.district} />
                <InfoGroup
                  label="Renda"
                  value={personData?.income ? Money(personData.income) : '-'}
                />
              </InfoColumn>

              <InfoColumn>
                <InfoGroup
                  label="Código postal"
                  value={personData?.zipCode ? formatZipcode(personData.zipCode) : '-'}
                />
                <InfoGroup label="Cidade" value={personData?.city} />
                <InfoGroup label="Estado civil" value={civilStatus[personData?.maritalStatus]} />
              </InfoColumn>

              <InfoColumn>
                <InfoGroup label="Estado" value={personData?.state} />
                <InfoGroup label="Complemento" value={personData?.addressComplement} />
              </InfoColumn>
            </Flex>
          )}
        </Section>
      )}
      {!personData && !loadingContract && (
        <Content>
          <Alert label="Não foi possível carregar as informações." status="neutral" fullWidth />
        </Content>
      )}
    </Wrapper>
  );
}

export default OtherInfo;
