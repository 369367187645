import moment from 'moment/moment';
import React, { useCallback, useState } from 'react';
import { Money } from '~/common';
import toPersonDocument from '~/common/masked/toPersonDocument';
import { Grid, Section, Title } from '~/screens/Contract/components/Tabs/Summary/Summary.styles';
import { Contract } from '~/typings/entities/Clix';
import { TransactionStatusString } from '~/typings/enums/Clix';
import LoanType from '~/typings/enums/LoanType';
import { Caret, Flex, InfoGroup, Tag, Typography } from '~/ui/components';

export type ClixSummaryProps = {
  contract: Contract;
};

export function ClixSummary({ contract }: ClixSummaryProps) {
  const [sectionVisibility, setSectionVisibility] = useState([]);

  const handleDetailsVisibility = (section: number) => {
    setSectionVisibility((prevSections) => {
      if (prevSections.includes(section)) {
        return prevSections.filter((n) => n !== section);
      }

      return [...prevSections, section];
    });
  };

  const renderProductTag = useCallback((product) => {
    if (!product) return '-';

    return (
      <Flex align="center" gap={8}>
        <Tag small rounded textColor="info.primaryAlt" bgColor="info.secondary">
          {LoanType(product) || product}
        </Tag>
      </Flex>
    );
  }, []);

  return (
    <>
      <Section>
        <Title
          onClick={() => handleDetailsVisibility(4)}
          data-testid="contract-summary-transactions-data"
        >
          <Typography type="bodyLarge" weight={600}>
            Detalhes da transação + dados de origem
          </Typography>
          <Caret caretOpen={!sectionVisibility.includes(4)} />
        </Title>

        {!sectionVisibility.includes(4) && (
          <Grid>
            <InfoGroup
              label="Data e hora"
              value={
                contract?.contractInfo?.date
                  ? moment(contract?.contractInfo?.date).format('DD/MM/YYYY [às] HH:mm[h]')
                  : '-'
              }
            />
            <InfoGroup
              label="Valor transferido"
              value={contract?.contractInfo?.amount ? Money(contract?.contractInfo?.amount) : '-'}
            />
            <InfoGroup
              label="Taxa de serviço"
              value={
                contract?.contractInfo?.serviceCharge
                  ? Money(contract?.contractInfo?.serviceCharge)
                  : '-'
              }
            />
            <InfoGroup
              label="Tag cash in"
              value={TransactionStatusString[contract?.contractInfo?.cashInStatus]}
            />
            <InfoGroup
              label="Total a pagar"
              value={
                contract?.contractInfo?.totalAmount
                  ? Money(contract?.contractInfo?.totalAmount)
                  : '-'
              }
            />
            <InfoGroup
              label="Quantidade de parcelas"
              value={contract?.contractInfo?.installments}
            />
            <InfoGroup
              label="Cartão (4 últimos dígitos)"
              value={contract?.contractInfo?.cardLast4}
            />
            <InfoGroup label="Bandeira" value={contract?.contractInfo?.cardBrand} />
            <InfoGroup label="NSU" value={contract?.contractInfo?.nsu} />
          </Grid>
        )}
      </Section>

      <Section border>
        <Title onClick={() => handleDetailsVisibility(2)} data-testid="contract-summary-recipient">
          <Typography type="bodyLarge" weight={600}>
            Dados do destinatário
          </Typography>
          <Caret caretOpen={!sectionVisibility.includes(2)} />
        </Title>

        {!sectionVisibility.includes(2) && (
          <Grid>
            <InfoGroup
              label="Informações da conta"
              value={contract?.contractInfo?.recipientInstitution}
            />
            <InfoGroup label="Tipo de conta" value="-" />
            <InfoGroup label="Agência" value={contract?.contractInfo?.recipientAgency} />
            <InfoGroup label="Conta" value={contract?.contractInfo?.recipientAccount} />
            <InfoGroup label="Nome do destinatário" value={contract?.contractInfo?.recipientName} />
            <InfoGroup
              label="CPF do destinatário"
              value={toPersonDocument(contract?.contractInfo?.recipientDocument)}
            />
            <InfoGroup
              label="Produto"
              value={
                contract.contract?.product ? renderProductTag(contract.contract?.product) : '-'
              }
            />
            <InfoGroup
              label="Tag cash out"
              value={TransactionStatusString[contract?.contractInfo?.cashOutStatus]}
            />
          </Grid>
        )}
      </Section>
    </>
  );
}
