import { message } from 'antd';
import { createAsyncAction } from 'react-redux-await-control';
import { backoffice } from '~/services';

export const getCardDetails = createAsyncAction('GET_CARD_DETAIL', {
  saveResult: true,
  initialValue: {},
  context: {
    onRequestError: () => message.error('Erro ao consultar dados do cartão'),
    responseParser: ({ data }) => data,
    request: ({ id }) => backoffice.get(`/cards/details/${id}`),
  },
});

export const getTransactionHistory = createAsyncAction('GET_TRANSACTION_HISTORY', {
  saveResult: true,
  initialValue: [],
  context: {
    onRequestError: () => message.error('Erro ao consultar histórico de transações'),
    responseParser: ({ data }) => data || { transactions: [] },
    errorParser: () => [],
    request: ({ id, ...params }) => backoffice.get(`/cards/details/${id}/transactions`, { params }),
  },
});
