import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 8px;
`;

export const TimerValue = styled.span`
  font-size: 32px;
  font-weight: bold;
  color: ${(props) => props.theme.primaryColor};
`;
