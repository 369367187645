import React, { useEffect, useMemo } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import _ from 'lodash';

import { OpportunityListItem } from '~/typings/entities/Opportunity';
import { Contract } from '~/typings/entities/contract';
import { Pendency } from '~/typings/entities/Pendency';
import { Person } from '~/typings/entities/person';
import { Ticket } from '~/typings/entities/Ticket';
import { Reset } from '~/typings/entities/Reset';
import { getTicketsByUser } from '~/store/tickets/actions';
import { getOpportunities } from '~/store/opportunities/actions';
import { TabItem, Tabs, Tag } from '~/ui/components';
import { remoteValues } from '~/common/utils/firebase/remoteConfig';

import { Enrollment } from '~/typings/entities/Enrollment';
import { getPersonDetail } from '~/store/people/actions';
import { Contracts, GeneralHistory, Opportunities, Pendencies, Resets, Tickets } from '../Tabs';
import { Comments } from './AuxiliaryInfo.styled';
import { Enrollments } from '../Tabs/Enrollment/Enrollment';
import { Files } from '../Tabs/Files/Files';

interface AuxiliaryInfoProps {
  loading?: boolean;
  person: Person;
  contracts: Contract[];
  pendencies: Pendency[];
  resets: Reset[];
  tickets: Ticket[];
  enrollments: Enrollment[];
}

export function AuxiliaryInfo({
  person,
  contracts,
  loading,
  pendencies,
  resets,
  tickets,
  enrollments,
}: AuxiliaryInfoProps) {
  const getTicketsByUserControl = useAwaitControl(getTicketsByUser);
  const opportunitiesControl = useAwaitControl(getOpportunities);
  const personControl = useAwaitControl(getPersonDetail);
  const personDetails = personControl.result();
  const opportunities: OpportunityListItem[] = opportunitiesControl.result();
  const isTicketsLoading = getTicketsByUserControl.isRunning();

  const { showCommentsInPerson } = remoteValues;

  const pendenciesBadge = useMemo(() => {
    const unsolvedPendencies = pendencies?.filter((p) => p.status !== 'Resolvida');

    if (unsolvedPendencies?.length) {
      return (
        <Tag small rounded bgColor="negative.secondary" textColor="negative.primaryAlt">
          +{unsolvedPendencies.length}
        </Tag>
      );
    }
  }, [pendencies]);

  const opportunityBadge = useMemo(() => {
    const availableOpportunities = opportunities?.filter(
      (opportunity) => opportunity.status === 'OK',
    );

    if (availableOpportunities?.length) {
      return (
        <Tag small rounded bgColor="brand.secondary" textColor="brand.primaryAlt">
          +{opportunities.length}
        </Tag>
      );
    }
  }, [opportunities]);

  const tabs = useMemo(() => {
    const tabsList: TabItem[] = [
      {
        key: 'tickets',
        title: 'Tickets',
        noPadding: true,
        component: <Tickets tickets={tickets} />,
      },
      {
        key: 'contratos',
        title: 'Contratos',
        noPadding: true,
        component: <Contracts contracts={contracts?.entries} />,
      },
      {
        key: 'opportunities',
        title: 'Oportunidades',
        badge: opportunityBadge,
        noPadding: true,
        component: <Opportunities />,
      },
      {
        key: 'matriculas',
        title: 'Matrículas',
        noPadding: true,
        component: <Enrollments enrollments={enrollments} />,
      },
      {
        key: 'history',
        title: 'Histórico geral',
        component: <GeneralHistory loading={loading} />,
        noPadding: true,
      },
      {
        key: 'pendencias',
        title: 'Pendências',
        badge: pendenciesBadge,
        noPadding: true,
        component: <Pendencies pendencies={pendencies} />,
      },
      {
        key: 'arquivos',
        title: 'Arquivos',
        noPadding: true,
        component: <Files id={person?.id} />,
      },
      {
        key: 'resets',
        title: 'Resets',
        noPadding: true,
        component: <Resets resets={resets?.entries} />,
      },
    ];

    if (showCommentsInPerson) {
      tabsList.push({
        key: 'comentarios',
        title: 'Comentários',
        component: <Comments contracts={contracts} person={person} loading={loading} />,
      });
    }

    return tabsList;
  }, [person, contracts, loading, pendencies, isTicketsLoading, opportunities]);

  useEffect(() => {
    if (person && !personDetails) {
      personControl.start({ id: person?.id });
    }
  }, [person, personDetails]);

  useEffect(() => {
    if (!_.isEmpty(person) && personDetails?.registrationStatus !== 'REFUSED') {
      opportunitiesControl.start({ personId: person?.id, agreementId: person?.agreementId });
    }
  }, [person, personDetails]);

  return (
    <Tabs loading={loading} tabs={tabs} persistOnQuery={{ key: 'aux' }} testId="auxiliary-info" />
  );
}
