import styled from 'styled-components';
import { Content } from '~/ui/components';

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 16px;
  padding: 32px;
  gap: 24px;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContentWithBreadcrumb = styled(Content)`
  & > ${Section} {
    margin-top: -16px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  @media (max-width: 1200px) {
    & > ${Section} {
      margin-top: 0;
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
    }

    & > div:first-of-type {
      margin-top: -16px;
      border-top-right-radius: 0;
      border-top-left-radius: 0;

      & > div {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }
`;
