import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { useTheme } from 'styled-components';
import toMoney from '~/common/masked/toMoney';

import { CardDetails } from '~/typings/entities/Card';
import { Dots } from '~/ui/assets/icons';
import { useModal } from '~/hooks/useModal';
import { Shimmer, Tag, Tooltip, Typography } from '~/ui/components';
// import { MenuOptions } from '~/components';
// import { Money } from '~/common';

import {
  CardContractStatus,
  CardContractStatusPalette,
  CardStatusPalette,
} from '~/typings/enums/Card';
import GeneratedCardsHistory from '../components/GeneratedCardsHistory/GeneratedCardsHistory';

import { Flex, Grid, Label, Section, TextAlign, Value, Wrapper } from './Details.styles';

const cardsMock = [
  {
    date: '13/020',
    number: '#3455',
    expiration: '05/09',
    status: 'bloqueado',
  },
  {
    date: '13/020',
    number: '#3455',
    expiration: '05/09',
    status: 'bloqueado',
  },
];

type DetailsProps = {
  details: CardDetails;
  loading?: boolean;
};

function Details({ details, loading }: DetailsProps) {
  const theme = useTheme();
  const { openModal } = useModal();

  const handleGeneratedCardsHistory = useCallback(() => {
    openModal(<GeneratedCardsHistory tableData={cardsMock} />, {
      width: 500,
    });
  }, []);

  const formatCardNumber = useCallback(
    (cardNumber: string) => (cardNumber ? `**** ${String(cardNumber).padStart(4, '0')}` : '-'),
    [],
  );

  const renderContractStatus = useCallback((status) => {
    const colorPalette = CardContractStatusPalette[status];
    const textColor = theme.colors?.[colorPalette]?.primaryAlt;
    const bgColor = theme.colors?.[colorPalette]?.secondary;
    return (
      <Tag small rounded textColor={textColor} bgColor={bgColor}>
        {CardContractStatus[status] || '-'}
      </Tag>
    );
  }, []);

  const renderCardStatus = useCallback((status) => {
    const colorPalette = CardStatusPalette[status];
    const textColor = theme.colors?.[colorPalette]?.primaryAlt;
    const bgColor = theme.colors?.[colorPalette]?.secondary;
    return (
      <Tag small rounded textColor={textColor} bgColor={bgColor}>
        {status || '-'}
      </Tag>
    );
  }, []);

  const options = useMemo(
    () => [
      {
        key: 'request',
        label: 'Bloquear cartão',
        onClick: () => null,
      },
      {
        key: 'exclude',
        label: 'Cancelar cartão',
        onClick: () => null,
      },
      {
        key: 'history',
        label: 'Histórico de cartões gerados',
        onClick: () => handleGeneratedCardsHistory(),
      },
    ],
    [],
  );

  return (
    <Section>
      <Section>
        <Typography type="paragraphLarge" weight={600}>
          Detalhes
        </Typography>
      </Section>

      <Section>
        <Grid>
          <Section>
            <Flex>
              <Label>Status do contrato:</Label>

              {loading ? (
                <Shimmer width={70} height={24} />
              ) : (
                <Value>
                  {details?.contractStatus ? renderContractStatus(details?.contractStatus) : '-'}
                </Value>
              )}
            </Flex>

            <Flex marginTop>
              <Label>Data da contratação:</Label>

              {loading ? (
                <Shimmer width={50} height={24} />
              ) : (
                <Value>
                  {details?.contractDate ? moment(details?.contractDate).format('DD/MM/YY') : '-'}
                </Value>
              )}
            </Flex>

            <Flex marginTop>
              <Label>Vencimento da fatura:</Label>

              {loading ? (
                <Shimmer width={50} height={24} />
              ) : (
                <Value>Dia {details?.expirationDate || '-'}</Value>
              )}
            </Flex>
          </Section>

          <Section>
            <Flex>
              <Label>Cartão virtual:</Label>

              {loading ? (
                <Shimmer width={70} height={24} />
              ) : (
                <>
                  {details?.virtualCardStatus ? (
                    <Value>
                      {renderCardStatus(details?.virtualCardStatus?.status)}
                      {/* <TextAlign> */}
                      {formatCardNumber(details?.virtualCardStatus?.cardNumber)}
                      {/* <MenuOptions options={options} placement="bottomLeft"> */}
                      {/*  <Dots data-testid="virtual-card-action" color={theme.colors.primary.main} /> */}
                      {/* </MenuOptions> */}
                      {/* </TextAlign> */}
                    </Value>
                  ) : (
                    <Value>-</Value>
                  )}
                </>
                
              )}
            </Flex>

            <Flex marginTop>
              <Label>Cartão físico:</Label>

              {loading ? (
                <Shimmer width={70} height={24} />
              ) : (
                <>
                  {details?.physicalCardStatus ? (
                    <Value>
                      {renderCardStatus(details?.physicalCardStatus?.status)}

                      <TextAlign>
                        {formatCardNumber(details?.physicalCardStatus?.cardNumber)}
                        <Dots
                          data-testid="physical-card-action"
                          color={theme.colors.primary.main}
                        />
                      </TextAlign>
                    </Value>
                  ) : (
                    <Value>-</Value>
                  )}
                </>
              )}
            </Flex>

            <Flex marginTop>
              <Label>Código do benefício:</Label>

              {loading ? (
                <Shimmer width={40} height={24} />
              ) : (
                <Wrapper>
                  <Value>{details?.benefitCode || '-'}</Value>
                  {details?.benefitName && <Tooltip title={details?.benefitName} />}
                </Wrapper>
              )}
            </Flex>
          </Section>

          <Section>
            <Flex>
              <Label>Limite total:</Label>

              {loading ? (
                <Shimmer width={60} height={24} />
              ) : (
                <Value>{toMoney(details?.limitTotal)}</Value>
              )}
            </Flex>

            <Flex marginTop>
              <Label>Saldo utilizado:</Label>
              {loading ? (
                <Shimmer width={60} height={24} />
              ) : (
                <Value>{toMoney(details?.balanceUsed)}</Value>
              )}
            </Flex>

            <Flex marginTop>
              <Label>Saldo disponível:</Label>

              {loading ? (
                <Shimmer width={60} height={24} />
              ) : (
                <Value>{toMoney(details?.balanceAvailable)}</Value>
              )}
            </Flex>
          </Section>
        </Grid>
      </Section>
    </Section>
  );
}

export default Details;
