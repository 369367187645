import React, { memo, useCallback, useEffect } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { useModal } from '~/hooks/useModal';
import { getCancellationReasons, getDetails } from '~/store/contracts/actions';
import CancelContractModal from './CancelContractModal';
import { CancelButton } from './CancelContractButton.styles';

type CancelContractButtonProps = {
  product: string;
  externalId: string;
};

function CancelContractButton({ product, externalId }: CancelContractButtonProps) {
  const { openModal } = useModal();

  const contractDetailsControl = useAwaitControl(getDetails);

  const contract = contractDetailsControl.result();
  const isCancelled = contract?.contract?.conferenceStatus === 'CANCELED';

  const cancellationReasonsControl = useAwaitControl(getCancellationReasons);
  const cancellationReasons = cancellationReasonsControl.result();

  useEffect(() => {
    if (!cancellationReasons?.length) cancellationReasonsControl.start({ productName: product });
  }, [product]);

  const handleOpenModal = useCallback(
    () =>
      openModal(<CancelContractModal product={product} externalId={externalId} />, {
        title: 'Cancelamento de contrato',
        id: 'cancel-contract-modal',
        width: 500,
        closable: true,
      }),
    [],
  );

  return (
    <CancelButton fullWidth rounded disabled={isCancelled} color="error" onClick={handleOpenModal}>
      Cancelar contrato
    </CancelButton>
  );
}

export default memo(CancelContractButton);
