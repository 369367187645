import React, { useCallback, useMemo } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import moment from 'moment/moment';

import useUserRoles from '~/hooks/useUserRoles';
import { TableColumns } from '~/ui/components';

import { updateEventReturn } from '~/store/contracts/actions';
import { ParatiAccountReturn } from '~/typings/entities/Proposals';
import { ActionButton, SmallTable } from '../../Returns.styled';

export type ReturnsTableProps = {
  accountReturns: ParatiAccountReturn[];
  contractId: number;
};

export function ReturnsTable({ contractId, accountReturns }: ReturnsTableProps) {
  const { isAdmin } = useUserRoles();

  const updateEventReturnControl = useAwaitControl(updateEventReturn);

  const onUpdateEvent = useCallback(
    (id) => () => {
      updateEventReturnControl.start({ contractId, eventId: id });
    },
    [],
  );

  const tableColumns = useMemo(() => {
    const columns: TableColumns<ParatiAccountReturn> = [
      {
        accessorKey: 'eventDate',
        header: () => 'DATA',
        maxSize: 92,
        cell: (info) => moment(info.getValue()).format('DD/MM/YYYY [às] HH:mm:ss'),
      },
      { accessorKey: 'message', header: () => 'MENSAGEM' },
      { accessorKey: 'status', header: () => 'STATUS', cellAlign: 'center' },
      { accessorKey: 'eventId', header: () => 'ID DO EVENTO', cellAlign: 'center' },
      { accessorKey: 'attempt', header: () => 'TENTATIVA', cellAlign: 'center' },
    ];

    if (isAdmin) {
      columns.push({
        id: 'reply',
        header: () => 'REPROCESSAR',
        cellAlign: 'center',
        headerAlign: 'center',
        cell: ({ row }) => (
          <ActionButton icon="Refresh" onClick={onUpdateEvent(row.original.eventId)} />
        ),
      });
    }

    return columns;
  }, [isAdmin]);

  return (
    <SmallTable
      noItemsMessage="Nenhum retorno encontrado"
      data={accountReturns}
      columns={tableColumns}
    />
  );
}
