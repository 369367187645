import React, { ReactNode, useState } from 'react';
import slugify from '~/common/masked/slugify';

import { Caret, Typography } from '~/ui/components';

import { SectionContainer, Title } from './Section.styled';

export type SectionProps = {
  title: string;
  children: ReactNode;
};

export function Section({ title, children }: SectionProps) {
  const [visible, setVisible] = useState(true);

  return (
    <SectionContainer>
      <Title onClick={() => setVisible(!visible)} data-testid={`section-${slugify(title)}`}>
        <Typography type="bodyLarge" weight={600}>
          {title}
        </Typography>
        <Caret caretOpen={visible} />
      </Title>

      {visible && children}
    </SectionContainer>
  );
}
