import React, { useState } from 'react';
import { Button, Flex, Input, Typography } from '~/ui/components';

export type ConfirmModalProps = {
  text?: string;
  onConfirm?: (comment?: string) => void;
  onCancel?: () => void;
};

export function ConfirmModal({
  text = 'Tem certeza que deseja realizar essa ação?',
  onConfirm,
  onCancel,
}: ConfirmModalProps) {
  const [comment, setComment] = useState('');
  return (
    <Flex direction="column" gap={24}>
      <Typography type="bodyMedium">{text}</Typography>
      <Input placeholder="Insira um comentário" onChange={(value) => setComment(value)} />

      <Flex gap={8} width="100%">
        <Button fullWidth rounded variant="outline" customColor="black" onClick={onCancel}>
          Cancelar
        </Button>

        <Button fullWidth rounded customColor="black" onClick={() => onConfirm(comment)}>
          Confirmar
        </Button>
      </Flex>
    </Flex>
  );
}
