import React, { useCallback } from 'react';
import { useTheme } from 'styled-components';
import { Timeline } from 'antd';

import { Button, Caret, Tag, Typography } from '~/ui/components';
import { themeToggleColor } from '~/common/utils/theme';
import {
  Divider,
  Flex,
  Content,
  Wrapper,
  InfoLabel,
  InfoValue,
  Description,
  StyledPopover,
  StyledTimeline,
} from './TransactionDetails.styles';

type InfoType = {
  label: string;
  value: string;
  color?: string;
};

function TransactionDetails() {
  const theme = useTheme();

  const Info = React.memo(({ label, value, color }: InfoType) => (
    <Flex column>
      <InfoLabel>{label}</InfoLabel>
      <InfoValue color={color}>{value ?? '-'}</InfoValue>
    </Flex>
  ));

  const content = useCallback(
    () => (
      <StyledTimeline>
        <Timeline.Item>
          <InfoValue>Limite indisponível</InfoValue>
          <Typography type="bodyXSmall" weight="600">
            Erro na transação
          </Typography>
          <Typography type="bodyXSmall">
            Houve algum erro na transação, não sabemos informar o motivo.
          </Typography>
          <Typography type="bodyXSmall" color={themeToggleColor(theme, 'element.secondary')}>
            20/05/2022 - 14:41:35
          </Typography>
        </Timeline.Item>
      </StyledTimeline>
    ),
    [],
  );

  return (
    <Wrapper>
      <Flex>
        <Tag small rounded>
          Fechada
        </Tag>
      </Flex>
      <StyledPopover
        placement="bottom"
        trigger="click"
        title="Status da transação"
        content={content()}
      >
        Status da transação <Caret caretOpen={false} />
      </StyledPopover>
      <Flex>
        <Button color="black" rounded data-testid="signature-button">
          Foto assinatura
        </Button>
        <Button color="black" variant="outline" rounded data-testid="transaction-button">
          CCB de transação
        </Button>
      </Flex>
      <Divider />
      <Typography type="bodyLarge" weight={700}>
        Resumo da transação
      </Typography>
      <Description>20/05/2022 • 14:41:35</Description>
      <Content>
        <Info label="Valor recebido" value="R$ 2.259,20" />
        <Info label="Parcelas" value="84x de R$ 62,60" />
        <Info label="Valor total com juros" value="R$ 5.980,29" />
        <Info label="Origem" value="QI Sociedade de Credito Direto S.A." />
        <Info label="Tipo de saída" value="Saque" />
        <Info label="Pagamento efetuado via" value="Contactless" />
        <Info label="Limite restante após transferência" value="R$ 0,00" />
      </Content>
      <Divider />
      <Typography type="bodyLarge" weight={700}>
        Detalhes do destino
      </Typography>
      <Content>
        <Info label="Nome" value="Giulliana Maria Dantas Murinelli" />
        <Info label="CPF" value="066.875.533-22" />
        <Info label="Agência" value="0009" />
        <Info label="Conta" value="24234234-3" />
      </Content>
      <Divider />
      <Typography type="bodyLarge" weight={700}>
        Detalhes adicionais
      </Typography>
      <Content border>
        <Info label="Taxa de juros" value="Giulliana Maria Dantas Murinelli" />
        <Info label="Custo efetivo total (CET)" value="066.875.533-22" />
        <Info label="IOF (Imposto sobre operações financeiras)" value="0009" />
      </Content>
    </Wrapper>
  );
}

export default TransactionDetails;
