import React from 'react';

import { cellToDate, cellToMoney, cellToValue } from '~/common/utils/table';
import { useDrawer } from '~/hooks/useDrawer';
import { Transaction } from '~/typings/entities/Card';
import { Button, CellProps, Tag, Tooltip } from '~/ui/components';

import TransactionActions from '../TransactionActions/TransactionActions';
import TransactionDetails from '../../Drawers/TransactionDetails/TransactionDetails';

import { UiTable } from './TransactionTable.styles';

type TransactionHistoryProps = {
  tableData: Transaction[];
  loading?: boolean;
};

function TransactionTable({ tableData, loading }: TransactionHistoryProps) {
  const { openDrawer } = useDrawer();

  const openTransaction = () => {
    openDrawer('transaction-details-drawer', <TransactionDetails />, {
      title: 'Transação',
      width: 442,
      closable: true,
      maskClosable: false,
      extraAction: <TransactionActions />,
    });
  };

  function cellToStatus<TData>(props: CellProps<TData>) {
    const value = props?.getValue<number | string>();

    if (!value) return '-';

    return (
      <Tooltip content="Texto" small>
        <Tag small rounded>
          {value}
        </Tag>
      </Tooltip>
    );
  }

  function cellToCard<TData>(props: CellProps<TData>) {
    const value = props?.getValue<number | string>();

    if (!value) return '-';

    return `**** ${value}`;
  }

  function cellToInstallment<TData>(props: CellProps<TData>) {
    const value = props?.getValue<number | string>();

    if (!value) return '-';

    return `${value}x`;
  }

  function cellToDetails() {
    return (
      <Button
        customColor="brand.primary"
        iconOnly
        icon="FileSearch"
        variant="text"
        disabled
        onClick={openTransaction}
      />
    );
  }

  const columns = [
    {
      accessorKey: 'id',
      header: () => 'Nº DA TRANSAÇÃO',
      cell: cellToValue,
    },
    {
      accessorKey: 'dateTime',
      header: () => 'DATA/HORA',
      cell: cellToDate('DD/MM/YY HH:mm'),
    },
    {
      header: () => 'TIPO DE SAÍDA',
      accessorKey: 'typeExit',
    },
    {
      header: () => 'CARTÃO',
      accessorKey: 'card',
      cell: cellToCard,
    },
    {
      header: () => 'STATUS',
      accessorKey: 'status',
      cell: cellToStatus,
    },
    {
      header: () => 'PARCELAS',
      cellAlign: 'right',
      headerAlign: 'right',
      accessorKey: 'installment.amount',
      cell: cellToInstallment,
    },
    {
      header: () => 'TOTAL ENVIADO',
      cellAlign: 'right',
      headerAlign: 'right',
      accessorKey: 'amount',
      cell: cellToMoney,
    },
    {
      header: () => 'DETALHES',
      cellAlign: 'center',
      headerAlign: 'center',
      accessorKey: 'details',
      cell: cellToDetails,
    },
  ];

  return (
    <UiTable
      noItemsMessage="Nenhuma transação encontrada"
      loading={loading}
      columns={columns}
      data={tableData}
      noPadding
      testId="transaction"
    />
  );
}

export default TransactionTable;
