import React, { useCallback, useEffect } from 'react';

import useAsyncActionStatus from '~/hooks/useAsyncActionStatus';
import { getFiles } from '~/store/files/actions';
import { FileHistoryModal, FilesList } from '~/components';

import { useModal } from '~/hooks/useModal';
import { FilesContainer } from './Files.styled';

export type FilesProps = {
  id: string;
};

export function Files({ id }: FilesProps) {
  const { openModal } = useModal();

  const getFilesControl = useAsyncActionStatus(getFiles, `CONTRACT_${id}`);

  const description = (
    <>
      É possível baixar os arquivos em massa ou bojo digital.{' '}
      <u>Os boletos tem validade de um dia útil.</u>
    </>
  );

  const handleShowHistory = useCallback(
    (file) => {
      openModal(
        <FileHistoryModal
          documentType={file.documentType}
          fileType={file.personImageType || file.type}
          id={id}
          type="CONTRACT"
        />,
        {
          title: 'Histórico da imagem',
          closable: true,
        },
      );
    },
    [id],
  );

  useEffect(() => {
    getFilesControl.start({ type: 'CONTRACT', id }, { actionId: `CONTRACT_${id}` });
  }, [id]);

  return (
    <FilesContainer>
      <FilesList
        type="CONTRACT"
        description={description}
        onHistoryClick={handleShowHistory}
        loading={getFilesControl.loading}
        files={getFilesControl.result}
        allowedTypes={['FACEFRONT', 'FACEBIOMETRY', 'DOCUMENTFRONT']}
        allowUpload
      />
    </FilesContainer>
  );
}
