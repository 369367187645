import React from 'react';
import moment from 'moment';

import LoanType from '~/typings/enums/LoanType';
import { Tag } from '~/ui/components';
import { formatName } from '~/common';

import { PendencyStatusNames } from '~/typings/enums/Pendencies/PendencyStatus';
import { Container, InfoRow, InfoTitle, InfoValue, TagsRow } from './PendencyDetailsModal.styled';

type PendencyInfoProps = {
  pendency?: any;
};

export function PendencyDetailsModal({ pendency }: PendencyInfoProps) {
  const palettes = { CREATED: 'alert', RESOLVED: 'positive' };

  const renderDate = (date) => {
    if (date) {
      return moment(date).format('DD/MM/YYYY [às] HH:mm');
    }
    return 'Data indisponível';
  };

  return (
    <Container>
      <TagsRow>
        <Tag rounded small noWrap color={palettes?.[pendency?.status as string] || 'warning'}>
          {PendencyStatusNames?.[pendency?.status]}
        </Tag>
      </TagsRow>

      <InfoRow>
        <InfoTitle>Produto</InfoTitle>
        <InfoValue>{LoanType(pendency?.product)}</InfoValue>
      </InfoRow>

      <InfoRow>
        <InfoTitle>Tipo da pendência</InfoTitle>
        <InfoValue>{pendency.type}</InfoValue>
      </InfoRow>

      <InfoRow width={294}>
        <InfoTitle>Motivo da pendência</InfoTitle>
        <InfoValue>{pendency?.reason}</InfoValue>
      </InfoRow>

      {pendency.logicalStatus && (
        <InfoRow>
          <InfoTitle>Descrição do motivo</InfoTitle>
          <InfoValue>{pendency?.logicalStatus}</InfoValue>
        </InfoRow>
      )}

      <InfoRow>
        <InfoTitle>Agente que solicitou</InfoTitle>
        <InfoValue>
          {pendency.createdBy && `${formatName(pendency.createdBy, 2)}, `}
          {renderDate(pendency?.pendingDate)}
        </InfoValue>
      </InfoRow>

      {pendency.status === 'RESOLVED' && (
        <InfoRow withTopBorder>
          <InfoTitle>Resolução da pendência</InfoTitle>
          <InfoValue>
            {pendency.solvedBy && `${formatName(pendency.solvedBy, 2)}, `}
            {renderDate(pendency?.resolutionDate)}
          </InfoValue>
        </InfoRow>
      )}
    </Container>
  );
}
