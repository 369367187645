import React, { useCallback } from 'react';

import { useModal } from '~/hooks/useModal';

import useAsyncActionStatus from '~/hooks/useAsyncActionStatus';
import { getDetails } from '~/store/contracts/actions';
import { CreatePendencyModal } from '../../Modals/CreatePendencyModal/CreatePendencyModal';

import { ActionItem } from '../Sidebar.styled';

function CreatePendencyAction() {
  const { openModal } = useModal();
  const detailsControl = useAsyncActionStatus(getDetails);

  const isCancelled = detailsControl?.result?.logicalStatus === 'Cancelado';

  const handleOpenModal = useCallback(() => {
    openModal(<CreatePendencyModal />, {
      title: 'Solicitação de pendência',
      id: 'create-pendency-modal',
      width: 450,
      closable: true,
    });
  }, []);

  return (
    <ActionItem disabled={isCancelled} onClick={handleOpenModal}>
      Solicitar pendência
    </ActionItem>
  );
}

export default CreatePendencyAction;
