import React, { useCallback, useEffect } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import { AwaitControlHook } from 'react-redux-await-control/dist/types';
import moment from 'moment';

import {
  cancelQiProposal,
  cancelQiReservation,
  forceResubmitPaymentQiContract,
  revokeCancelQiProposal,
  signContract,
  listContractProposals,
} from '~/store/proposals/actions';
import { Table, TableColumns } from '~/ui/components';
import { useDrawer } from '~/hooks/useDrawer';
import { MenuOptions } from '~/components';

import { QiProposal } from '~/typings/entities/Proposals';
import { ActionButton, TableTitle } from '../Returns.styled';
import { ReturnDetails } from './ReturnDetails';

export type QiReturnsProps = {
  contractId: number;
};

export function QiReturns({ contractId }: QiReturnsProps) {
  const proposalType = 'qiproposal';
  const { openDrawer } = useDrawer();

  const listProposalsControl = useAwaitControl(listContractProposals);
  const cancelQiProposalControl = useAwaitControl(cancelQiProposal);
  const revokeCancelQiProposalControl = useAwaitControl(revokeCancelQiProposal);
  const signContractControl = useAwaitControl(signContract);
  const forceResubmitPaymentControl = useAwaitControl(forceResubmitPaymentQiContract);
  const cancelQiReservationControl = useAwaitControl(cancelQiReservation);

  const proposals = listProposalsControl.result(contractId);
  const proposalsLoading = listProposalsControl.isRunning(contractId);

  const handleDetailsClick = (proposal) => () => {
    openDrawer('return-details', <ReturnDetails proposal={proposal} />, {
      title: 'Detalhes da proposta',
      closable: true,
      width: 700,
    });
  };

  const onActionDispatched = useCallback(
    (action: AwaitControlHook, proposalId: string) => () => {
      action.start({ proposalId, proposalType });
    },
    [proposalType],
  );

  const options = useCallback(
    (proposal) =>
      [
        {
          label: 'Cancelar',
          onClick: onActionDispatched(cancelQiProposalControl, proposal.id),
          key: 'CANCEL',
        },
        {
          label: 'Anular cancelamento',
          onClick: onActionDispatched(revokeCancelQiProposalControl, proposal.id),
          key: 'CANCEL_REVOKE',
        },
        {
          label: 'Assinar contrato',
          onClick: onActionDispatched(signContractControl, proposal.id),
          key: 'SIGN_CONTRACT',
        },
        {
          label: 'Forçar reenvio de pagamento',
          onClick: onActionDispatched(forceResubmitPaymentControl, proposal.id),
          key: 'FORCE_RESUBMIT_PAYMENT',
        },
        {
          label: 'Desaverbar proposta',
          onClick: onActionDispatched(cancelQiReservationControl, proposal.id),
          key: 'CANCEL_RESERVATION_QITECH',
        },
        {
          label: 'Cancelar Proposta na QiTech',
          onClick: onActionDispatched(cancelQiProposalControl, proposal.id),
          key: 'CANCEL_QITECH',
        },
      ].filter((option) => proposal.actions.includes(option.key)),
    [],
  );

  const columns: TableColumns<QiProposal> = [
    { accessorKey: 'externalId', header: () => 'COD' },
    { accessorKey: 'lastActionType', header: () => 'AÇÃO' },
    { accessorKey: 'lastStatus', header: () => 'STATUS' },
    { id: 'baas', header: () => 'BAAS', cell: () => 'QiTech' },
    {
      accessorKey: 'creationDate',
      header: () => 'DATA',
      cell: (info) => moment(info.getValue()).format('DD/MM/YYYY [às] HH:mm:ss'),
    },
    {
      id: 'details',
      header: () => 'DETALHES',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: (info) => (
        <ActionButton onClick={handleDetailsClick(info.row.original)} icon="FileSearch" />
      ),
    },
    {
      id: 'actions',
      header: () => 'AÇÕES',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: (info) => (
        <MenuOptions options={options(info.row.original)} width="auto">
          <ActionButton icon="Dots" />
        </MenuOptions>
      ),
    },
  ];

  useEffect(() => {
    listProposalsControl.start({ contractId, proposalType }, { actionId: contractId });
  }, []);

  return (
    <>
      <TableTitle>Propostas</TableTitle>
      <Table
        noItemsMessage="Nenhuma proposta encontrada"
        loading={proposalsLoading}
        data={proposals}
        columns={columns}
      />
    </>
  );
}
