import styled from 'styled-components';

import { Typography } from '~/ui/components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  align-items: center;
`;

export const EventList = styled.ul`
  display: flex;
  align-items: center;
  flex-direction: column;
  list-style-type: none;
  max-width: 1000px;
`;

export const Label = styled(Typography).attrs({
  type: 'paragraphSmall',
  weight: 600,
})`
  text-wrap: nowrap;
  display: contents;
`;

export const Info = styled(Typography).attrs({
  type: 'caption',
  element: 'span',
})`
  font-style: italic;
`;

export const Event = styled.li`
  display: flex;
  padding-bottom: 14px;
`;

export const IconContent = styled.div`
  position: relative;
  margin-inline: 16px;
`;

export const Dot = styled.div`
  width: 16px;
  height: 16px;
  display: block;
  background-color: #ffa3d0;
  border-radius: 16px;
  border: 4px solid #ffe5f3;
`;

export const Line = styled.div<{ isConected: boolean }>`
  position: absolute;
  left: 0;
  width: 50%;
  border-right: 1px dashed;
  border-color: ${({ isConected }) => (isConected ? '#666666' : 'transparent')};
  height: 100%;
`;

export const Section = styled.div<{ isHidden: boolean; evenOrOdd: string }>`
  display: flex;
  flex-direction: column;
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
  padding-bottom: 10px;

  & > * {
    align-self: ${({ evenOrOdd }) => (evenOrOdd === 'par' ? 'flex-start' : 'flex-end')};
  }
`;
