import React, { useMemo } from 'react';

import SideBarActions from '~/screens/Card/SideBarActions/SideBarActions';
import { Person } from '~/typings/entities/person';
import screenRegister from '~/hoc/screenRegister';
import { Content, Flex, SelectDate, Typography } from '~/ui/components';
import { Filters, ProfileWithActions, ResponsiveMainActions } from '~/components';

import { getInvoicesMock } from '~/typings/entities/__mocks__/Invoice';
import { MultiSelect } from '~/ui/components/MultiSelect/MultiSelect';

import InvoicesTable from '../components/InvoicesTable/InvoicesTable';

import { Section } from './InvoiceHistory.styles';

function InvoiceHistory() {
  const loading = false; // TODO: implement loading
  const avatarImage = ''; // TODO: implement avatar image
  const invoices = getInvoicesMock(10); // TODO: implement invoices

  const statusOptions = [
    { label: 'Paga', value: 'PAID' },
    { label: 'Aberta', value: 'PENDING' },
    { label: 'Vencida', value: 'OVERDUE' },
  ];

  const inputFilters = useMemo(
    () => [
      {
        id: 'closingDate',
        label: 'Data de fechamento',
        input: <SelectDate maxDate={new Date()} />,
      },
      {
        id: 'dueDate',
        label: 'Data de vencimento',
        input: <SelectDate maxDate={new Date()} />,
      },
      {
        id: 'status',
        label: 'Status',
        input: <MultiSelect options={statusOptions} />,
      },
    ],
    [],
  );

  return (
    <>
      <ProfileWithActions loading={loading} person={{} as Person} avatarImage={avatarImage}>
        <SideBarActions />
      </ProfileWithActions>

      <Content>
        <ResponsiveMainActions
          person={{} as Person}
          loading={loading}
          avatarImage={avatarImage}
          responsiveActions={<></>} // TODO: implement responsive actions
        />

        <Section>
          <Typography type="paragraphLarge" weight={600}>
            Histórico de faturas
          </Typography>

          <Flex justify="space-between" align="center" direction="row-reverse">
            <Filters filters={inputFilters} />
          </Flex>

          <InvoicesTable tableData={invoices} />
        </Section>
      </Content>
    </>
  );
}

export default screenRegister({
  screenName: 'InvoiceHistory',
  path: '/backoffice/card/:contractId/invoice-history',
  headerTitle: 'Histórico de faturas',
  customLayout: true,
})(InvoiceHistory);
