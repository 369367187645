import React, { useEffect } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import { useTheme } from 'styled-components';

import { listTags } from '~/store/tags/actions';
import { TagResourceType } from '~/typings/enums/TagTypes';
import { Flex, Tag } from '~/ui/components';

interface ProfileTagsProps {
  id: string | number;
  type: TagResourceType;
}

export function Tags({ id, type }: ProfileTagsProps) {
  const { colors } = useTheme();

  const listTagsControl = useAwaitControl(listTags);

  const tags = listTagsControl.result(`${type}_${id}`);

  useEffect(() => {
    if (id) {
      listTagsControl.start({ externalId: id, type }, { actionId: `${type}_${id}` });
    }
  }, [id]);

  if (!tags?.length) {
    return <></>;
  }

  return (
    <Flex gap={8}>
      {tags.map((tag) => (
        <Tag small rounded textColor={colors.brand.primaryAlt} bgColor={colors.brand.secondary}>
          {tag?.tagType?.name}
        </Tag>
      ))}
    </Flex>
  );
}
