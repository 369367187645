import React, { useMemo } from 'react';

import { Tabs } from 'antd';

import useQueryParams from '~/hooks/useQueryParams';
import { ContractInfo } from '~/typings/entities/ContractInfo';

import { Returns } from './Returns/Returns';
import Comments from './Comments';
import Historical from './Historical';
import More from './More';
import Pendencies from './Pendencies';
import Resume from './Resume';
import RefinancedContracts from './RefinancedContracts';
import { Files } from './Files/Files';

const REFIN = 'REFIN';
const PORTABILITY = 'PORTABILITY';
const FGTS_NEW = 'FGTS_NEW';

type PanelsProps = {
  contractId: string;
  contractInfo: ContractInfo;
  isConferenceByPersonEnabled?: boolean;
};

export default function Panels({
  contractId,
  contractInfo,
  isConferenceByPersonEnabled,
}: PanelsProps) {
  const query = useQueryParams();

  const { loanType, financialInstitution } = contractInfo.object;

  const shouldShowReturns = useMemo(
    () =>
      (financialInstitution && ['326', '329'].includes(financialInstitution.bankNumber)) ||
      loanType === FGTS_NEW ||
      contractInfo.isPAB,
    [loanType, financialInstitution],
  );

  return (
    <Tabs defaultActiveKey={query.get('panel') || '1'}>
      <Tabs.TabPane tab="Resumo" key="1">
        <Resume isConferenceByPersonEnabled={isConferenceByPersonEnabled} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Outras Informações" key="2">
        <More />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Arquivos" key="3" style={{ padding: 0 }}>
        <Files id={contractId} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Comentários" key="4">
        <Comments />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Histórico" key="5">
        <Historical />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Pendências" key="6">
        <Pendencies personId={701734} contractId={contractId} />
      </Tabs.TabPane>
      {[PORTABILITY, REFIN].includes(loanType) && (
        <Tabs.TabPane
          tab={loanType === PORTABILITY ? 'Contrato de Portabilidade' : 'Contratos Refinanciados'}
          key="7"
        >
          <RefinancedContracts />
        </Tabs.TabPane>
      )}

      {shouldShowReturns && (
        <Tabs.TabPane tab="Retornos" key="8">
          {/* {financialInstitution?.bankNumber === '326' ? ( */}
          {/*  <ParatiReturns contractId={contractId} /> */}
          {/* ) : ( */}
          {/*  <FGTSReturns type={contractInfo.isPAB || contractInfo.isINSSQiTech ? 'PAB' : 'FGTS'} /> */}
          {/* )} */}
          <Returns contractId={contractId} contractInfo={contractInfo} />
        </Tabs.TabPane>
      )}
    </Tabs>
  );
}
