import React, { useState } from 'react';
import {
  ActionsWrapper,
  ItemText,
  MenuItem,
  SubActionItem,
  SubActions,
} from '~/components/SideActions/SideActions.styled';
import { Icon } from 'antd';

export type Action = {
  title?: string;
  onClick?: () => void;
  subActions?: Action[];
};

type ActionItemProps = {
  action: Action;
};

export function ActionItem({ action }: ActionItemProps) {
  const [subMenuVisibility, setSubMenuVisibility] = useState(false);

  const toggleSubmenu = () => {
    setSubMenuVisibility(!subMenuVisibility);
  };

  const handleVisibleChange = (value) => {
    setSubMenuVisibility(value);
  };

  const handleMenuClick = (a: Action) => {
    if (a?.subActions) {
      toggleSubmenu();
    }

    a.onClick?.();
  };

  const renderOptions = () => (
    <ActionsWrapper>
      {action.subActions.map((el) => (
        <SubActionItem key={el.title} onClick={() => handleMenuClick(el)}>
          {el.title}
        </SubActionItem>
      ))}
    </ActionsWrapper>
  );

  return (
    <MenuItem onClick={() => handleMenuClick(action)} key={action.title}>
      <ItemText>{action.title}</ItemText>

      {!!action.subActions && (
        <SubActions
          visible={subMenuVisibility}
          onVisibleChange={handleVisibleChange}
          overlay={renderOptions()}
        >
          <Icon type="more" />
        </SubActions>
      )}
    </MenuItem>
  );
}
