import React, { useMemo } from 'react';
import { OldActions as MenuAction } from '~/components';
import { downloadAuditTrail } from '~/store/contracts/actions';
import { useAwaitControl } from 'react-redux-await-control';
import useUserRoles from '~/hooks/useUserRoles';
import { useHandleAsyncDocumentDownload } from '~/hooks/useHandleAsyncDocumentDownload';

export type ValidateKYCStepProps = {
  contractId: string;
  details: any;
};

export function DownloadAuditTrail({ contractId, details }: ValidateKYCStepProps) {
  const { hasRole, roles, isAdmin } = useUserRoles();
  const downloadAuditTrailControl = useAwaitControl(downloadAuditTrail);
  const loading = downloadAuditTrailControl.isRunning();
  const allowedStatus = ['SIGNED', 'CCBSIGNED', 'PAID'];

  const shouldShowButton = useMemo(
    () => allowedStatus.includes(details?.loanStatus) && (hasRole('LEGAL_TEAM') || isAdmin),
    [details?.loanStatus, roles],
  );

  const handleOnPress = () => {
    downloadAuditTrailControl.start({ contractId });
  };

  useHandleAsyncDocumentDownload(downloadAuditTrail, `trilha-de-auditoria-${contractId}`);

  if (!shouldShowButton) {
    return null;
  }

  return (
    <MenuAction
      disabled={loading}
      onClick={handleOnPress}
      key="audit-trail"
      title="Baixar Trilha de Auditoria"
    />
  );
}
