import React, { useCallback, useState } from 'react';

import { Link } from 'react-router-dom';
import { useDrawer } from '~/hooks/useDrawer';
import { CaretDown, File } from '~/ui/assets/icons';
import { Popover } from '~/ui/components';
import { ContractStatus, WarrantyType, WarrantyTypeNames } from '~/typings/enums/Clix';
import { ContractFieldValue, ContractsList } from './ClixContractsPopover.styled';

export type ClixContractType = {
  status: string;
  personId: number;
  warrantyType: WarrantyType;
};

type ClixContractsPopoverProps = {
  contracts: ClixContractType[];
};

export function ClixContractsPopover({ contracts }: ClixContractsPopoverProps) {
  const { closeAllDrawers } = useDrawer();
  const [isVisible, setIsVisible] = useState(false);

  const handleLinkCLick = useCallback(() => {
    closeAllDrawers();
    setIsVisible(false);
  }, []);

  return (
    <Popover
      trigger="click"
      width={265}
      onVisibleChange={setIsVisible}
      visible={isVisible}
      noHeader
      content={
        <ContractsList>
          {contracts?.map(({ status, warrantyType, personId }) => (
            <Link
              key={`${status}_${warrantyType}_${personId}`}
              onClick={handleLinkCLick}
              to={`/backoffice/clix/${personId}?status=${status}&warranty=${warrantyType}`}
            >
              <File /> {WarrantyTypeNames[warrantyType]} ({ContractStatus[status] || status})
            </Link>
          ))}
        </ContractsList>
      }
    >
      <ContractFieldValue data-testid="clix-contracts-popover">
        <span>Contratos disponíveis</span>
        <CaretDown className="action-icon" width={14} height={14} />
      </ContractFieldValue>
    </Popover>
  );
}
