import React, { useCallback, useEffect, useMemo } from 'react';

import { useModal } from '~/hooks/useModal';
import { ConfirmModal } from '~/components';

import { getDetails, approveContract } from '~/store/contracts/actions';
import useAsyncActionStatus from '~/hooks/useAsyncActionStatus';
import { getLoggedUser } from '~/store/user/actions';
import { ActionItem } from '../Sidebar.styled';

function ApproveContractAction() {
  const { openModal, closeModal, setLoading } = useModal();

  const approveContractControl = useAsyncActionStatus(approveContract);
  const loggedUserControl = useAsyncActionStatus(getLoggedUser);
  const detailsControl = useAsyncActionStatus(getDetails);

  const isCancelled = detailsControl?.result?.logicalStatus === 'Cancelado';

  const actionDisabled = useMemo(
    () =>
      approveContractControl.loading ||
      detailsControl.loading ||
      loggedUserControl.loading ||
      isCancelled,
    [
      approveContractControl.loading,
      detailsControl.loading,
      loggedUserControl.loading,
      isCancelled,
    ],
  );

  const handleConfirm = useCallback((comment) => {
    const { contract } = detailsControl.result;
    const { id } = loggedUserControl.result;

    approveContractControl.start({
      externalId: contract?.externalId,
      product: contract?.product,
      externalUserId: id,
      comment,
      document: contract.document,
    });
  }, []);

  const handleCancel = useCallback(() => {
    closeModal('approve-contract-modal');
  }, []);

  useEffect(() => {
    setLoading('approve-contract-modal', approveContractControl.loading);
  }, [approveContractControl.loading]);

  useEffect(() => {
    closeModal('approve-contract-modal');
  }, [approveContractControl.success]);

  useEffect(() => {
    closeModal('approve-contract-modal');
    approveContractControl.clear();
  }, [approveContractControl.success]);

  const handleOpenModal = useCallback(() => {
    approveContractControl.clear();

    openModal(<ConfirmModal onCancel={handleCancel} onConfirm={handleConfirm} />, {
      title: 'Aprovar contrato',
      id: 'approve-contract-modal',
      width: 450,
      closable: true,
    });
  }, []);

  if (
    detailsControl.result?.contract?.productSecurity ||
    detailsControl.result?.contract?.conferenceStatus === 'APPROVED'
  ) {
    return null;
  }

  return (
    <ActionItem disabled={actionDisabled} onClick={handleOpenModal}>
      Aprovar contrato
    </ActionItem>
  );
}

export default ApproveContractAction;
