import styled from 'styled-components';

import { Button, Typography } from '~/ui/components';
import { CaretRight } from '~/ui/assets/icons';

export const BreadcrumbWrapper = styled.nav`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.background.tertiary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider.primary};
  height: 56px;
  border-radius: 16px 16px 0 0;
  padding: 0 24px;
`;

type BreadcrumbItemProps = { $isLast: boolean };

export const BreadcrumbItem = styled(Typography).attrs({
  element: 'span',
  type: 'bodySmall',
})<BreadcrumbItemProps>`
  color: ${({ theme, $isLast }) =>
    $isLast ? theme.colors.brand.primary : theme.colors.element.primary};
  cursor: ${({ $isLast }) => ($isLast ? 'default' : 'pointer')};
  font-weight: ${({ $isLast }) => ($isLast ? 700 : 400)};
`;

export const Separator = styled(CaretRight).attrs(({ theme }) => ({
  height: 10,
  color: theme.colors.element.secondary,
}))``;

export const BackButton = styled(Button).attrs({
  icon: 'ArrowLeftAlt',
  variant: 'text',
  color: 'black',
})`
  min-width: 38px;
  max-width: 38px;

  svg {
    width: 20px;
    height: 20px;
  }
`;
