import React from 'react';

import useAsyncActionStatus from '~/hooks/useAsyncActionStatus';
import { getDetails } from '~/store/contracts/actions';
import { Contract } from '~/typings/entities/Clix';
import { Flex, Shimmer } from '~/ui/components';
import ConfirmKYCAction from './ConfirmKYCAction/ConfirmKYCAction';
import CancelContractButton from './CancelContractButton/CancelContractButton';
import CreatePendencyAction from './CreatePendencyAction/CreatePendencyAction';
import ApproveContractAction from './ApproveContractAction/ApproveContractAction';
import { Content, ActionsTitle, SidebarContainer } from './Sidebar.styled';
import Timer from './Timer/Timer';
import AddTags from './AddTags/AddTags';

type SidebarProps = {
  contract: Contract;
};

export function Sidebar({ contract }: SidebarProps) {
  const detailsControl = useAsyncActionStatus(getDetails);

  const conferenceStatus = detailsControl.result?.conferenceStatus;
  const assignedAt = conferenceStatus?.assignedAt;

  const calculateEndTime = (value) => {
    const date = new Date(value);
    date.setTime(date.getTime() + 10 * 60 * 1000);
    const formattedDate = date.toISOString();

    return formattedDate;
  };

  const removeFromConferenceQueue = () => {
    detailsControl.start({
      externalId: contract?.contract?.externalId,
      product: contract?.contract?.product,
      document,
    });
  };

  if (detailsControl.loading) {
    return (
      <SidebarContainer>
        {/* <Content> */}
        {/*  <Flex gap={8}> */}
        {/*    <Shimmer height={20} width={80} /> */}
        {/*    <Shimmer height={20} width={90} /> */}
        {/*  </Flex> */}
        {/* </Content> */}

        <Content>
          <ActionsTitle>Ações</ActionsTitle>
          <Shimmer height={20} />
          <Shimmer height={20} />
          <Shimmer height={20} />
        </Content>
      </SidebarContainer>
    );
  }

  return (
    <SidebarContainer>
      {conferenceStatus && conferenceStatus?.assignedAt && (
        <Timer endTime={calculateEndTime(assignedAt)} onFinish={removeFromConferenceQueue} />
      )}

      {/* <AddTags /> */}

      <Content>
        <ActionsTitle>Ações</ActionsTitle>
        <CreatePendencyAction />
        <ApproveContractAction />
        <ConfirmKYCAction />
      </Content>

      <CancelContractButton
        product={contract?.contract?.product as string}
        externalId={contract?.contract?.externalId as string}
      />
    </SidebarContainer>
  );
}
