import { Cancel, HourGlass, Replay, Swap, Verified, Warning } from '~/ui/assets/icons';

export enum TransactionType {
  PURCHASE = 'Compra',
  TRANSFER = 'Transferência',
  WITHDRAW = 'Retirada',
  INSS_DISCOUNT = 'Desconto INSS',
}

export enum WarrantyTypeNames {
  CLEAN = 'Clean',
  INSS = 'Benefício',
  EMPLOYEE = 'Clix para funcionarios',
  CREDIT = 'Cartão de crédito',
}

export enum WarrantyType {
  CLEAN = 'CLEAN',
  INSS = 'INSS',
  EMPLOYEE = 'EMPLOYEE',
  CREDIT = 'CREDIT',
}

export enum InvoiceStatusNames {
  CLOSED = 'Fatura fechada',
  CURRENT = 'Fatura aberta',
  OPEN = 'Próximas faturas',
}

export enum InvoiceStatus {
  CLOSED = 'CLOSED',
  CURRENT = 'CURRENT',
  OPEN = 'OPEN',
}

export enum InvoiceStatusPalette {
  CLOSED = 'positive',
  OPEN = 'neutral',
  CURRENT = 'alert',
}

export enum TransactionTypeIcon {
  PURCHASE = 'Paste',
  TRANSFER = 'Transfer',
  WITHDRAW = 'MoneyWithDraw',
  INSS_DISCOUNT = 'MoneyWithDraw',
}

export enum TransactionStatusString {
  PROCESSED = 'Enviado',
  PROCESSING = 'Em processamento',
  PENDING = 'Em andamento',
  REFUSED = 'Pagamento negado',
  CANCELED = 'Cancelado',
  REVERSED = 'Estorno',
  INITIAL = 'Em andamento',
  ERROR = 'Erro na transação',
  PRE_AUTHORIZED = 'Pré-autorizado',
}

export enum TransactionStatusHelpText {
  PROCESSED = 'Transação foi efetuada com sucesso.',
  PROCESSING = 'A transação já foi enviada para a Zoop e estamos esperando o retorno para concluir a transação.',
  INITIAL = 'A transação já foi enviada para a Zoop e estamos esperando o retorno para concluir a transação.',
  PENDING = 'O cliente preencheu as informações e está aguardando envio para Zoop',
  REFUSED = 'Pagamento negado',
  CANCELED = 'A transação foi cancelada.',
  REVERSED = 'A transação foi estornada e acontecerá em no máximo {DIAS} dias úteis.',
  ERROR = 'Houve algum erro na transação, não sabemos informar o motivo.',
}

export enum TransactionStatusPalette {
  PROCESSED = 'positive',
  PROCESSING = 'alert',
  PENDING = 'alert',
  REFUSED = 'negative',
  CANCELED = 'negative',
  ERROR = 'negative',
  INITIAL = 'alert',
  REVERSED = 'info',
}

export enum TransactionStatusColors {
  PROCESSED = 'positive',
  PROCESSING = 'alert',
  PENDING = 'alert',
  REVERSED = 'alert',
  REFUSED = 'negative',
  CANCELED = 'negative',
  INITIAL = 'alert',
  ERROR = 'negative',
}

export enum PaymentStatus {
  NOT_PAYMENT = 'Pagamento pendente',
  FULL_PAYMENT = 'Fatura paga',
  PARTIAL_PAYMENT = 'Pagamento parcial',
}

export enum PaymentStatusPalette {
  NOT_PAYMENT = 'negative',
  FULL_PAYMENT = 'positive',
  PARTIAL_PAYMENT = 'alert',
}

export enum ContractStatus {
  CONTRACTED = 'Aprovado',
  CANCELED = 'Reprovado',
  IN_PROGRESS = 'Em andamento',
  ANALYSIS = 'Em análise',
  BLOCKED = 'Bloqueado',
  SUSPEND = 'Suspenso',
  PRE_REGISTRATION = 'Em pré-cadastro',
  LIMIT_ANALYSIS = 'Em análise de limite',
  LEGAL_SUSPEND = 'Suspenso por ordem judicial',
  TEMPORARILY_SUSPENDED = 'Temporariamente suspenso',
}

export const TransactionStatusIcons = {
  PROCESSED: Verified,
  PROCESSING: HourGlass,
  PENDING: Replay,
  INITIAL: Replay,
  CANCELED: Cancel,
  REVERSED: Swap,
  ERROR: Warning,
};
