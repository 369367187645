import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import pendencyTitle from '~/typings/enums/PendencyType';
import { solveProofOfAddress } from '~/store/pendencies/actions';
import { getPersonDetail } from '~/store/people/actions';
import { getContractDetails } from '~/store/contracts/actions';
import { FileItem, FileUpload } from '~/screens/Person/components';
import { Select, Tag } from '~/ui/components';
import { useModal } from '~/hooks/useModal';

import {
  Container,
  CustomButton,
  DocumentsList,
  InfoRow,
  InfoTitle,
  InfoValue,
} from '~/screens/Person/components/Modals/Pendency/Pendency.styled';

type ProfOfAddressProps = {
  pendency?: any;
};

const options = [
  { value: 'ENERGYBILL', label: 'Fatura de energia' },
  { value: 'WATERBILL', label: 'Fatura de água' },
  { value: 'TELEPHONEBILL', label: 'Fatura de telefone' },
  { value: 'CREDITCARDBILL', label: 'Fatura de cartão' },
  { value: 'OTHER', label: 'Outros' },
];

export function ProfOfAddress({ pendency }: ProfOfAddressProps) {
  const [file, setFile] = useState<FileItem>();
  const [documentType, setDocumentType] = useState<string>();
  const { setLoading, closeModal } = useModal();

  const solveProofOfAddressControl = useAwaitControl(solveProofOfAddress);
  const getPersonDetailsControl = useAwaitControl(getPersonDetail);
  const getContractDetailsControl = useAwaitControl(getContractDetails);

  const person = getPersonDetailsControl.result();
  const contract = getContractDetailsControl.result();

  const solveProofOfAddressLoading = solveProofOfAddressControl.isRunning();
  const solveProofOfAddressSuccess = solveProofOfAddressControl.isSuccessful();

  const isValid = useMemo(() => !!file && !!documentType, [file, documentType]);

  const getDocumentTypeName = useCallback((value: string) => {
    const documentType = options.find((option) => option.value === value);
    return documentType?.label;
  }, []);

  const handleOnSubmit = useCallback(() => {
    const idKey = contract?.id ? 'contractId' : 'personId';
    const idValue = contract?.id || person?.id;

    solveProofOfAddressControl.start({
      file: file.file,
      id: pendency.id,
      documentType,
      [idKey]: idValue,
    });
  }, [file, pendency.id, contract?.id, person?.id]);

  const handleTypeChange = useCallback((value: string[]) => {
    setDocumentType(value?.[0]);
    setFile(null);
  }, []);

  const handleFileRemoved = useCallback(() => {
    setFile(null);
  }, []);

  const handleFileChange = useCallback((file: FileItem) => {
    setFile(file);
  }, []);

  useEffect(() => {
    setLoading('pendency-details', solveProofOfAddressLoading);
  }, [solveProofOfAddressLoading]);

  useEffect(() => {
    if (solveProofOfAddressSuccess) {
      closeModal('pendency-details');
    }
  }, [solveProofOfAddressSuccess]);

  return (
    <Container>
      <Tag small rounded color="alert">
        Pendência aberta
      </Tag>

      <InfoRow>
        <InfoValue>Reenvie o comprovante de residência para prosseguir.</InfoValue>
      </InfoRow>

      <InfoRow>
        <InfoTitle>Tipo da pendência</InfoTitle>
        <InfoValue>{pendencyTitle(pendency?.pendingType)}</InfoValue>
      </InfoRow>

      <InfoRow>
        <InfoTitle>Escolha qual documento enviar</InfoTitle>
        <Select options={options} onChange={handleTypeChange} />
      </InfoRow>

      {documentType && (
        <InfoRow>
          <DocumentsList>
            <FileUpload
              id={documentType}
              name={getDocumentTypeName(documentType)}
              onChange={handleFileChange}
              onRemove={handleFileRemoved}
            />
          </DocumentsList>
        </InfoRow>
      )}

      <CustomButton
        rounded
        fullWidth
        loading={solveProofOfAddressLoading}
        onClick={handleOnSubmit}
        disabled={!isValid}
      >
        Confirmar resolução
      </CustomButton>
    </Container>
  );
}
