import React from 'react';

import { LoadingSpinner } from '~/ui/assets/icons';
import { LoadingContent } from './TabLoading.styles';

function TabLoading() {
  return (
    <LoadingContent>
      <LoadingSpinner width={20} height={20} />
    </LoadingContent>
  );
}

export default TabLoading;
