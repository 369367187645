import * as React from 'react';
import {
  TagContainer,
  Tag as StyledTag,
  TagItemProps,
  AddTagInput,
} from '~/ui/components/Tags/Tags.styled';
import { CloseCircle } from '~/ui/assets/icons';
import { Popover } from 'antd';
import { TagOptions } from '~/ui/components/Tags/TagOptions';
import { PropsWithChildren, useEffect, useState } from 'react';
import { ColorsTypes } from '~/ui/theme/colors';

export interface TagItem extends Omit<TagItemProps, 'small' | 'rounded'> {
  id: string;
  tagId?: string;
  name: string;
}

export type TagsProps = {
  options?: TagItem[];
  tags: TagItem[];
  closable?: boolean;
  onClose?: (tag: TagItem) => void;
  onAdd?: (tag: TagItem) => void;
  onCreate?: (newTag: string) => void;
  small?: boolean;
  rounded?: boolean;
  editable?: boolean;
};

export function Tags({
  options,
  tags,
  small = false,
  closable,
  onClose,
  onAdd,
  rounded,
  editable,
  onCreate,
  ...props
}: TagsProps) {
  const [currentTags, setCurrentTags] = useState<TagItem[]>();
  const [popoverVisible, setPopoverVisible] = useState(false);
  const canAddTagButton = editable && options?.length;

  const handleVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible);
  };

  const handleClose = (tag: TagItem) => () => {
    onClose?.(tag);
  };

  const handleAdd = (newTag: TagItem) => {
    setPopoverVisible(false);
    setTimeout(() => {
      onAdd?.(newTag);
    }, 100);
  };

  const handleCreate = (tagName: string) => {
    setPopoverVisible(false);
    onCreate?.(tagName);
  };

  useEffect(() => {
    setCurrentTags(tags);
  }, [tags]);

  return (
    <TagContainer className="tags" {...props}>
      {!!currentTags?.length &&
        currentTags.map((tag) => {
          const { colorPalette, textColor, bgColor } = tag;
          const tagProps = {
            colorPalette,
            textColor,
            bgColor,
            small,
            closable,
            rounded,
          };

          return (
            <StyledTag key={tag.id} {...tagProps}>
              {tag.name}

              {closable && <CloseCircle onClick={handleClose(tag)} />}
            </StyledTag>
          );
        })}

      {!!canAddTagButton && (
        <Popover
          trigger="click"
          placement="bottomLeft"
          overlayClassName="mt-popover-no-arrow mt-popover-no-padding"
          visible={popoverVisible}
          onVisibleChange={handleVisibleChange}
          content={
            <TagOptions
              currentTags={currentTags}
              options={options}
              onAdd={handleAdd}
              onCreate={handleCreate}
            />
          }
        >
          <AddTagInput className="add-tag" small={small} onClick={() => setPopoverVisible(true)}>
            Adicionar tag +
          </AddTagInput>
        </Popover>
      )}
    </TagContainer>
  );
}

export type TagProps = Omit<TagItemProps, 'colorPalette'> & { color?: ColorsTypes };

export function Tag({ children, color, ...props }: PropsWithChildren<TagProps>) {
  return (
    <StyledTag rounded small {...props} colorPalette={color}>
      {children}
    </StyledTag>
  );
}
