import toDate from './toDate';
import toDigit from './toDigit';
import toDateAndHour from './toDateAndHour';
import toMoney from './toMoney';
import toMoneyFloor from './toMoneyFloor';
import toMoneyForm from './toMoneyForm';
import toNumber from './toNumber';
import toPercentage from './toPercentage';
import toPersonDocument from './toPersonDocument';
import toPhone from './toPhone';
import toAge from './toAge';
import toCPFFormat from './toCPFFormat';
import fromNow from './fromNow';
import slugify from './slugify';

export default {
  toAge,
  toDate,
  toDigit,
  toDateAndHour,
  toMoney,
  toMoneyFloor,
  toMoneyForm,
  toNumber,
  toPercentage,
  toPersonDocument,
  toPhone,
  toCPFFormat,
  fromNow,
  slugify,
};
