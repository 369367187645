import React, { memo } from 'react';

import { Flex, Tooltip } from '~/ui/components';
import { FormalizationDataType } from '~/typings/entities/Clix';
import { Info, Label, Title, Wrapper } from './Modals.styles';

interface ContractPopoverProps {
  formalization: FormalizationDataType;
}

function DocumentValidation({ formalization }: ContractPopoverProps) {
  return (
    <Wrapper>
      <Flex direction="column" gap={32} mb={31}>
        <Flex direction="column" width="100%" gap={16}>
          <Title>REKOGNITION</Title>
          <Flex width="100%" justify="space-between">
            <Flex direction="column" width="fit-content" gap={16}>
              <Label>ROSTO VALIDADO NO DOC</Label>
              <Flex align="center" gap={16}>
                <Info>{formalization?.documentCheck?.documentValid ? 'Sim' : 'Não'}</Info>
                <Tooltip title="Se houve validação da similaridade da selfie com a foto do documento." />
              </Flex>
              <Label>ROSTO ENCONTRADO</Label>
              <Flex align="center" gap={16}>
                <Info>{formalization?.documentCheck?.faceValid ? 'Sim' : 'Não'}</Info>
                <Tooltip title="Se o rosto existe na base de dados do DATAVALID." />
              </Flex>
            </Flex>
            <Flex direction="column" width="fit-content" gap={16}>
              <Label>SIMILARIDADE ROSTO DOC</Label>
              <Flex align="center" gap={16}>
                <Info>{formalization?.documentCheck?.faceSimilarity ?? '-'}</Info>
                <Tooltip title="Percentual de similaridade da selfie com a foto do documento." />
              </Flex>
              <Label>SIMILARIDADE DO DOC</Label>
              <Flex align="center" gap={16}>
                <Info>{formalization?.documentCheck?.documentSimilarity ?? '-'}</Info>
                <Tooltip title="Quão o documento é parecido de fato com um documento." />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Wrapper>
  );
}

export default memo(DocumentValidation);
