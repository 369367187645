import React, { useEffect } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import screenRegister from '~/hoc/screenRegister';
import {
  cancelContract,
  confirmKYC,
  getDetails,
  operationStep,
  approveContract,
} from '~/store/contracts/actions';
import { Alert, Content, Flex, Typography } from '~/ui/components';
import { getLoggedUser } from '~/store/user/actions';
import { Header } from './components/Header/Header';
import { Sidebar } from './components/Sidebar/Sidebar';
import Tabs from './components/Tabs/Tabs';

type ContractPageProps = {
  match?: any;
};

function ContractPage({ match }: ContractPageProps) {
  const { id } = match.params;

  const contractDetailsControl = useAwaitControl(getDetails);
  const operationStepsControl = useAwaitControl(operationStep);
  const kycConfirmationControl = useAwaitControl(confirmKYC);
  const sendTransactionControl = useAwaitControl(approveContract);
  const cancellationControl = useAwaitControl(cancelContract);
  const loggedUserControl = useAwaitControl(getLoggedUser);

  const operationStepUpdated = operationStepsControl.isSuccessful();
  const kycConfirmed = kycConfirmationControl.isSuccessful();
  const transactionSent = sendTransactionControl.isSuccessful();
  const cancelled = cancellationControl.isSuccessful();
  const isContractRunning = contractDetailsControl.isRunning();
  const contractDetails = contractDetailsControl.result();
  const user = loggedUserControl.result();

  const isAgentResponsable =
    !isContractRunning &&
    contractDetails?.conferenceStatus?.inConference &&
    Number(contractDetails?.conferenceStatus?.agentId) === user?.id;

  const otherAgentIsResponsable =
    !isContractRunning &&
    contractDetails?.conferenceStatus?.inConference &&
    Number(contractDetails?.conferenceStatus?.agentId) !== user?.id;

  useEffect(() => {
    contractDetailsControl.start({ id });
  }, [operationStepUpdated, kycConfirmed, transactionSent, cancelled]);

  return (
    <Content>
      <Flex gap={40} width="100%">
        <Flex width="75%" direction="column" gap={24}>
          {isAgentResponsable && (
            <Alert
              fullWidth
              status="info"
              renderValue={
                <Flex direction="column" gap={4}>
                  <Typography type="paragraphSmall">Em conferência</Typography>
                  <Typography type="caption">
                    Esse contrato pertence a sua fila de conferência!
                  </Typography>
                </Flex>
              }
            />
          )}
          {otherAgentIsResponsable && (
            <Alert
              fullWidth
              status="info"
              renderValue={
                <Flex direction="column" gap={4}>
                  <Typography type="paragraphSmall">Em conferência</Typography>
                  <Typography type="caption">
                    Esse contrato pertence a fila de conferência do(a){' '}
                    {contractDetails?.conferenceStatus?.agentName}!
                  </Typography>
                </Flex>
              }
            />
          )}

          <Header />

          <Tabs />
        </Flex>

        <Sidebar contract={contractDetails} />
      </Flex>
    </Content>
  );
}

export default screenRegister({
  screenName: 'Contract',
  path: '/backoffice/contract/:id',
  headerTitle: 'Contrato',
  customLayout: true,
})(ContractPage);
