import { faker } from '@faker-js/faker';
import { times } from 'lodash';
import { Invoice } from '~/typings/entities/Card';

export const getInvoicesMock = (size: number, replace?: Invoice): Invoice[] =>
  times(size, () => ({
    id: faker.string.uuid(),
    closingDate: faker.date.recent({ days: 1 }),
    dueDate: faker.date.recent({ days: 1 }),
    status: faker.helpers.arrayElement(['PENDING', 'PAID', 'OVERDUE']),
    inssValue: faker.finance.amount(),
    value: faker.finance.amount(),
    totalValue: faker.finance.amount(),
    ...replace,
  }));
