import styled, { css } from 'styled-components';
import { themeColor, themeToggleColor } from '~/common/utils/theme';
import { Typography } from '~/ui/components';

export const Title = styled(Typography).attrs(({ theme }) => ({
  type: 'bodyLarge',
  element: 'span',
  weight: 600,
  color: themeToggleColor(theme, 'element.primary'),
}))``;

export const Label = styled(Typography).attrs(({ theme }) => ({
  type: 'bodyLarge',
  element: 'span',
  weight: 600,
  color: themeToggleColor(theme, 'element.secondary'),
}))``;

interface InfoProps {
  score?: number;
  unico?: boolean;
}

export const Info = styled<InfoProps>(Typography).attrs(() => ({
  type: 'paragraphSmall',
  element: 'span',
  weight: 600,
}))`
  color: ${themeColor('element.primary')} !important;

  ${({ unico }) =>
    !unico &&
    css`
      ${({ score }) =>
        score &&
        score >= 93 &&
        css`
          color: ${themeColor('positive.primaryAlt')} !important;
        `};

      ${({ score }) =>
        score &&
        score < 93 &&
        css`
          color: ${themeColor('alert.primaryAlt')} !important;
        `};

      ${({ score }) =>
        score &&
        score < 86 &&
        css`
          color: ${themeColor('negative.primaryAlt')} !important;
        `};
    `};

  ${({ unico }) =>
    unico &&
    css`
      ${({ score }) =>
        score &&
        score >= 50 &&
        css`
          color: ${themeColor('positive.primaryAlt')} !important;
        `};

      ${({ score }) =>
        score &&
        score < 50 &&
        css`
          color: ${themeColor('alert.primaryAlt')} !important;
        `};

      ${({ score }) =>
        score &&
        score < 1 &&
        css`
          color: ${themeColor('negative.primaryAlt')} !important;
        `};
    `};
`;

type Props = {
  padding?: string;
  alignCenter?: boolean;
};

export const Wrapper = styled.div<Props>`
  padding: ${({ padding }) => padding ?? '24px'};

  ${({ alignCenter }) =>
    alignCenter &&
    css`
      display: flex;
      justify-content: center;
    `}
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider.primary};
`;
