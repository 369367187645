import styled, { css } from 'styled-components';
import { Input } from 'antd';
import { themeColor } from '~/common/utils/theme';
import { Typography } from '~/ui/components';

type InputProps = {
  isDisabled?: boolean;
  withValue?: boolean;
};

export const InputContainer = styled.div.attrs({
  className: 'mt-input',
})`
  min-height: 56px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider.primary} !important;
  background-color: ${themeColor('background.secondary', { dark: 'background.primary' })};
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const inputCss = css<InputProps>`
  &.ant-input,
  &.ant-input-affix-wrapper input {
    width: 100%;
    font-size: ${({ theme }) => theme.typography.types.paragraphSmall.size};
    line-height: ${({ theme }) => theme.typography.types.paragraphSmall.lineHeight};
    font-family: ${({ theme }) => theme.typography.fonts.libreFranklin};
    border-color: transparent;
    background-color: transparent;
    transform: translate(${({ prefix }) => (prefix ? '30px' : '0')}, 16px);

    ${({ isDisabled }) =>
      isDisabled
        ? css`
            color: ${({ theme }) => theme.colors.element.disabled};
          `
        : css`
            color: ${({ theme }) => theme.colors.element.primary};
          `}

    &::placeholder {
      color: ${({ theme }) => theme.colors.element.placeholder};
      opacity: 0.6;
    }

    &.ant-input-lg {
      height: 56px;
      padding: 0 8px;
    }

    &:focus,
    &:active,
    &:hover {
      outline: none;
      box-shadow: none;
      border-color: transparent;
    }

    &:hover {
      ${({ isDisabled }) =>
        isDisabled
          ? css`
              background-color: ${themeColor('element.disabledBackground')};
            `
          : css`
              background-color: ${themeColor('background.secondary', {
                dark: 'background.primary',
              })} !important;
            `}
    }

    &.ant-input-lg:focus {
      height: 24px;
      transform: translate(${({ prefix }) => (prefix ? '30px' : '0')}, 28px);
    }

    ${({ withValue }) =>
      withValue &&
      css`
        &.ant-input-lg {
          height: 24px;
          transform: translate(${({ prefix }) => (prefix ? '30px' : '0')}, 28px);
        }
      `}

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: ${({ theme }) => theme.colors.element.primary};
      -webkit-box-shadow: 0 0 0 1000px
        ${themeColor('background.secondary', { dark: 'background.primary' })} inset;
    }
  }

  &.ant-input-affix-wrapper {
    &:hover .ant-input:not(.ant-input-disabled) {
      outline: none;
      box-shadow: none;
      border-color: transparent;
    }
  }

  .ant-input-suffix {
    top: 28px;
  }

  &.allow-clear {
    .ant-input-suffix {
      display: none;

      svg {
        background-color: ${themeColor('background.secondary', { dark: 'background.primary' })};
        color: ${({ theme }) => theme.colors.element.placeholder};
      }
    }

    &:hover .ant-input-suffix {
      display: block;
    }
  }
`;

type InputLabelProps = {
  withValue?: boolean;
  withIcon?: boolean;
};

export const filledInputLabel = css<InputLabelProps>`
  font-size: ${({ theme }) => theme.typography.types.bodyXSmall.size} !important;
  font-weight: ${({ theme }) => theme.typography.types.bodyXSmall.weight};
  line-height: ${({ theme }) => theme.typography.types.bodyXSmall.lineHeight};
  color: ${({ theme }) => theme.colors.element.secondary};
  transform: translate(${({ withIcon }) => (withIcon ? '38px' : '8px')}, 8px);
`;

export const InputLabel = styled(Typography).attrs({
  element: 'label',
  type: 'bodyMedium',
  color: 'element.placeholder',
})<InputLabelProps>`
  position: absolute;
  transform: translate(${({ withIcon }) => (withIcon ? '38px' : '8px')}, 16px);
  z-index: 8;
  transition-property: transform, font-size, color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  cursor: text;

  &:has(+ .ant-input-affix-wrapper .ant-input:focus),
  &:has(+ .ant-input:focus),
  &:-webkit-autofill {
    ${filledInputLabel}
  }

  ${({ withValue }) => withValue && filledInputLabel}
`;

export const StyledInput = styled(Input)<InputProps>`
  ${inputCss}
`;

export const StyledPasswordInput = styled(Input.Password)<InputProps>`
  ${inputCss}
`;

export const StyledTextAreaInput = styled(Input.TextArea)<InputProps>`
  ${inputCss}
`;
