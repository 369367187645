import primary from './primary';
import secondary from './secondary';
import tertiary from './tertiary';
import error from './error';
import success from './success';
import warning from './warning';
import gray from './gray';
import white from './white';

export type PaletteKeys =
  | 'main'
  | '50'
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900';
export type ColorPalette = { [key in PaletteKeys]: string };

const colors = {
  primary,
  secondary,
  tertiary,
  error,
  success,
  warning,
  gray,
  white,
};

export type ColorsTypes =
  | keyof typeof colors
  | 'brand'
  | 'info'
  | 'alert'
  | 'positive'
  | 'negative'
  | 'neutral';

export default colors;
