import React, { useCallback } from 'react';
import { useTheme } from 'styled-components';
import moment from 'moment';

import { Tag, Tooltip } from '~/ui/components';
import { UiTable } from './GeneratedCardsHistory.styles';

type CardHistory = {
  date: string;
  number: string;
  expiration: string;
  status: string;
};

type GeneratedCardsHistoryProps = {
  tableData: CardHistory[];
};

function GeneratedCardsHistory({ tableData }: GeneratedCardsHistoryProps) {
  const { colors } = useTheme();

  const renderStatus = useCallback(
    (info) => (
      <Tooltip content="Texto" small>
        <Tag small rounded>
          Cancelado
        </Tag>
      </Tooltip>
    ),
    [],
  );

  const columns = [
    {
      header: () => 'DATA DE GERAÇÃO',
      accessorKey: 'date',
      cell: (info) => info.getValue(),
    },
    {
      header: () => 'NÚMERO',
      accessorKey: 'number',
      cell: (info) => info.getValue(),
    },
    {
      header: () => 'VALIDADE',
      accessorKey: 'expiration',
      cell: (info) => {
        const { date } = info;
        return moment(date).format('MM/YY');
      },
    },
    {
      header: () => 'STATUS',
      accessorKey: 'status',
      cell: (info) => renderStatus(info),
    },
  ];

  return (
    <UiTable
      noItemsMessage="Nenhuma transação encontrada"
      loading={false}
      columns={columns}
      data={tableData}
      noPadding
      testId="cards-history"
    />
  );
}

export default GeneratedCardsHistory;
