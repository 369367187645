import React from 'react';

import useTimer from '~/hooks/useTimer';
import { Content, TimerValue } from './Timer.styles';

type TimerProps = {
  endTime: string;
  onFinish: () => any;
};

export default function Timer({ endTime, onFinish }: TimerProps) {
  const value = useTimer(endTime, true, 1_000, onFinish);

  return <Content>{value && <TimerValue>{value}</TimerValue>}</Content>;
}
