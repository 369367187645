import React, { useCallback, useMemo, useState } from 'react';

import pendencyTitle from '~/typings/enums/PendencyType';
import { Tag } from '~/ui/components';
import { FileItem, FileUpload } from '~/screens/Person/components';

import {
  Container,
  CustomButton,
  DocumentsList,
  InfoRow,
  InfoTitle,
  InfoValue,
} from '../Pendency.styled';

type PictureDocumentProps = {
  id: string;
  name: string;
  onSubmit: (data: any) => void;
  pendency?: any;
  loading?: boolean;
  accept?: string;
};

export function SingleDocument({
  pendency,
  id,
  name,
  onSubmit,
  loading,
  accept,
}: PictureDocumentProps) {
  const [file, setFile] = useState<FileItem>();

  const handleFileRemoved = useCallback(() => {
    setFile(null);
  }, []);

  const handleFileChange = useCallback((file: FileItem) => {
    setFile(file);
  }, []);

  const isValid = useMemo(() => !!file, [file]);

  const handleSubmit = useCallback(() => {
    onSubmit(file);
  }, [file]);

  return (
    <Container disabled={loading}>
      <Tag small rounded color="alert">
        Pendência aberta
      </Tag>

      <InfoRow>
        <InfoValue>Reenvie o {name.toLowerCase()} para prosseguir.</InfoValue>
      </InfoRow>

      <InfoRow>
        <InfoTitle>Tipo da pendência</InfoTitle>
        <InfoValue>{pendencyTitle(pendency?.pendingType)}</InfoValue>
      </InfoRow>

      <InfoRow>
        <DocumentsList>
          <FileUpload
            id={id}
            name={name}
            accept={accept}
            onChange={handleFileChange}
            onRemove={handleFileRemoved}
          />
        </DocumentsList>
      </InfoRow>

      <CustomButton rounded fullWidth loading={loading} disabled={!isValid} onClick={handleSubmit}>
        Confirmar resolução
      </CustomButton>
    </Container>
  );
}
