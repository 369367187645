import React, { ReactNode, useMemo } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { ArrowUp, ArrowDown, CaretRight, CaretDown, CornerDownRight } from '~/ui/assets/icons';
import { Popover, Shimmer } from '~/ui/components';
import useScreenSize from '~/hooks/useScreenSize';
import { ChevronRight } from 'react-feather';
import { useTheme } from 'styled-components';
import { CellContext } from '@tanstack/table-core';
import {
  Tbody,
  Thead,
  TableContainer,
  NotFoundMessage,
  Cell,
  AlignType,
  TableWrapper,
  ToggleButton,
  ResponsiveWrapper,
  RespCol,
  RespHeader,
  RespValue,
} from './Table.styled';

type CustomColumnDef<T> = ColumnDef<any[] & T> & {
  cellAlign?: AlignType;
  headerAlign?: AlignType;
  ellipsis?: boolean;
};
export type TableColumns<T> = CustomColumnDef<T>[];
export type CellProps<T> = CellContext<any[] & T, unknown>;

interface TableProps {
  data?: any[];
  loading?: boolean;
  columns?: CustomColumnDef<any>[];
  rowHeight?: number;
  noItemsMessage?: string | ReactNode;
  sortable?: boolean;
  initialSorting?: SortingState;
  withoutBorders?: boolean;
  isTHeadFixed?: boolean;
  noPadding?: boolean;
  maxHeight?: number;
  shimmerLines?: number;
  responsiveCols?: string[];
  responsiveWidth?: number;
  onRowClick?: (row: any) => void;
  testId?: string;
}

export function Table({
  data,
  loading,
  columns,
  rowHeight,
  noItemsMessage,
  sortable,
  initialSorting,
  withoutBorders = false,
  isTHeadFixed = false,
  maxHeight,
  shimmerLines = 3,
  responsiveCols = columns?.slice(0, 4).map((el: any) => el.accessorKey || el.id),
  responsiveWidth = 1280,
  testId = 'ui',
  onRowClick,
  ...props
}: TableProps) {
  const [sorting, setSorting] = React.useState<SortingState>(initialSorting || []);
  const hasSubRows = useMemo(() => data?.some((row) => row.children?.length), [data]);
  const responsiveView = responsiveWidth ? useScreenSize(`max-width: ${responsiveWidth}px`) : null;
  const { colors } = useTheme();

  const getResponsiveColumns = (responsiveCols, columns) => {
    const visibleCols = columns.filter(
      (col: any) => responsiveCols.includes(col.id) || responsiveCols.includes(col.accessorKey),
    );

    const respCols = columns.filter(
      (el) => !visibleCols.map((el) => el.accessorKey || el.id).includes(el.accessorKey || el.id),
    );

    return {
      respCols,
      visibleCols,
    };
  };

  const renderResponsiveRowCell = (context: any) => {
    const { respCols } = getResponsiveColumns(responsiveCols, columns);

    const renderCell = (col, ctx) => {
      if (col?.accessorFn) {
        if (typeof col?.accessorFn === 'function') {
          return col?.accessorFn?.(ctx.row.original);
        }

        return col?.accessorFn;
      }

      if (typeof col?.cell === 'function') {
        return col?.cell?.({
          ...ctx,
          getValue: () => ctx.row.original[col.accessorKey || col.id],
        });
      }

      return col?.cell;
    };

    return (
      <Popover
        noHeader
        placement="bottomRight"
        trigger="click"
        width="auto"
        content={
          <ResponsiveWrapper>
            {respCols.map((c) => {
              const col = c as any;
              return (
                <RespCol key={col.accessorKey || col.id}>
                  <RespHeader>
                    {typeof col?.header === 'function' ? col?.header?.(context) : col?.header}
                  </RespHeader>
                  <RespValue>{renderCell(col, context)}</RespValue>
                </RespCol>
              );
            })}
          </ResponsiveWrapper>
        }
      >
        <ChevronRight color={colors.brand.primary} />
      </Popover>
    );
  };

  const columnsView = useMemo(() => {
    const { visibleCols } = getResponsiveColumns(responsiveCols, columns);

    if (
      responsiveView &&
      responsiveView <= responsiveWidth &&
      columns.length > responsiveCols.length
    ) {
      return [
        ...visibleCols,
        {
          accessorKey: 'responsive-resume',
          header: () => '',
          cellAlign: 'right',
          cell: renderResponsiveRowCell,
        },
      ];
    }

    return columns;
  }, [responsiveView, columns, responsiveCols]);

  const renderSubRowCell = ({ row }: any) => {
    if (row.subRows?.length) {
      return (
        <ToggleButton
          {...{
            onClick: row.getToggleExpandedHandler(),
          }}
        >
          {row.getIsExpanded() ? <CaretDown /> : <CaretRight />}
        </ToggleButton>
      );
    }

    if (row.depth > 0) {
      return <CornerDownRight className="depth-icon" />;
    }

    return <></>;
  };

  const getSubRowsColumn = (cols): TableColumns<any> => {
    if (!hasSubRows) return cols;
    return [
      {
        accessorKey: 'sub-rows',
        header: () => '',
        cellAlign: 'right',
        cell: renderSubRowCell,
      },
      ...cols,
    ];
  };

  const table = useReactTable({
    data,
    columns: getSubRowsColumn(columnsView),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    getSubRows: (row) => row.children,
    getExpandedRowModel: getExpandedRowModel(),
    state: { sorting },
    enableSorting: sortable,
    initialState: { sorting },
  });

  if (!loading && !data?.length) {
    return <NotFoundMessage>{noItemsMessage || 'Nenhum item encontrado'}</NotFoundMessage>;
  }

  return (
    <TableWrapper $maxHeight={maxHeight} {...props}>
      <TableContainer loading={loading ? 1 : 0}>
        <Thead $isTHeadFixed={isTHeadFixed}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={`th-${header.id} ${
                    header.column.getCanSort() ? 'cursor-pointer' : ''
                  }`}
                  style={{ textAlign: (header.column.columnDef as any).headerAlign }}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: (header.column.columnDef as any).headerAlign,
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}

                    {{
                      asc: <ArrowUp className="arrow" />,
                      desc: <ArrowDown className="arrow" />,
                    }[header.column.getIsSorted() as string] ?? null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </Thead>

        {loading && (
          <Tbody rowHeight={rowHeight} $withoutBorders={withoutBorders}>
            {table.getHeaderGroups().map((headerGroup) =>
              Array.from({ length: shimmerLines }).map((_, i) => (
                <tr key={`shimmer_${headerGroup.id}_${i}`}>
                  {headerGroup.headers.map((header) => (
                    <td key={`shimmer_td_${header.id}`}>
                      <Shimmer width={1000} height={25} />
                    </td>
                  ))}
                </tr>
              )),
            )}
          </Tbody>
        )}

        {!loading && data?.length && (
          <Tbody rowHeight={rowHeight} $withoutBorders={withoutBorders} clickable={!!onRowClick}>
            {table.getRowModel().rows.map((row, index) => (
              <tr
                key={row.id}
                className={[row.depth > 0 && `sub-row`].filter((el) => el).join(' ')}
                onClick={() => onRowClick?.(row.original)}
                data-testid={`${testId}-table-row-${index}`}
              >
                {row.getVisibleCells().map((cell, i) => (
                  <td
                    key={cell.id}
                    className={[`td-${cell.column.id}`, i === 0 && 'first']
                      .filter((el) => el)
                      .join(' ')}
                    data-testid={`${testId}-table-row-${index}-cell-${i}`}
                  >
                    <Cell
                      minSize={cell.column.columnDef.minSize}
                      maxSize={cell.column.columnDef.maxSize}
                      align={(cell.column.columnDef as any).cellAlign}
                      ellipsis={(cell.column.columnDef as any).ellipsis}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Cell>
                  </td>
                ))}
              </tr>
            ))}
          </Tbody>
        )}
      </TableContainer>
    </TableWrapper>
  );
}
