export type Pendency = {
  contractId: number;
  id: number;
  isNewPending: boolean;
  pendingType: string;
  reason: string;
  reasonType: string;
  pendingDate: string;
  status: string;
};

export const PendencyStatusColors = {
  Aberta: 'alert',
  Resolvida: 'positive',
  Excluída: 'neutral',
};

export type NewPendency = {
  id: number;
  externalId: string;
  product: string;
  typeKey: string;
  type: string;
  reasonKey: string;
  reason: string;
  logicalStatus: string;
  status: string;
  createdBy: string;
  pendingDate: string;
  solvedBy: string;
  resolutionDate: string;
};
