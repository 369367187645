export enum CardNames {
  BENEFIT_CARD = 'Cartão Benefício',
  CONSIGNED_CARD = 'Cartão Consignado',
}

export enum CardStatusPalette {
  Ativo = 'positive',
  Bloqueado = 'negative',
}

export enum CardContractStatusPalette {
  ACTIVE = 'positive',
  CANCELLED = 'negative',
  SIGNED = 'info',
  REQUESTED = 'neutral',
}

export enum CardContractStatus {
  ACTIVE = 'Ativo',
  CANCELLED = 'Cancelado',
  SIGNED = 'Assinado',
  REQUESTED = 'Requerido',
}
