import React, { useMemo } from 'react';

import { Contract } from '~/typings/entities/Clix';
import { AGREEMENT } from '~/common/constants';

import { ParatiReturns } from './ParatiReturns/ParatiReturns';
import { QiReturns } from './QIReturns/QiReturns';
import { MTReturns } from './MTReturns/MTReturns';

export type ReturnsProps = {
  contract: Contract;
};

export function Returns({ contract }: ReturnsProps) {
  const contractId = contract?.contract?.id;

  const isPAB = useMemo(() => contract?.personData?.agreementId === AGREEMENT.PAB, [contract]);

  const isINSSQiTech = useMemo(() => {
    if (contract?.personData?.agreementId) {
      return (
        contract?.personData?.agreementId === AGREEMENT.INSS &&
        contract?.personData?.bankNumber === '329'
      );
    }

    return false;
  }, [contract]);

  const isMtProposal = useMemo(() => isPAB || isINSSQiTech, [isPAB, isINSSQiTech]);

  const isParatiProposal = useMemo(() => contract?.personData?.bankNumber === '326', [contract]);

  if (!contract?.contract) {
    return null;
  }

  if (isParatiProposal) {
    return <ParatiReturns contractId={contractId} loan={contract?.contract?.product} />;
  }

  if (isMtProposal) {
    return <MTReturns contractId={contractId} />;
  }

  if (!isMtProposal) {
    return <QiReturns contractId={contractId} />;
  }
}
